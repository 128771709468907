import publicReq, { privateReq, imageUpload } from "./axiosConfig";
import { API_KEY } from "./axiosConfig";
export const addFurnitureService = (data) =>
  privateReq.post("/furniture", data, {
    // apiKey: API_KEY,
    headers: {
      "Content-type": "multipart/form-data",
    },
  });

export const getAllFurnitureService = (data) =>
  privateReq.get("/get-furnitures/" + data);

export const onGetFurnitureById = (_id) => privateReq.get("/furniture/" + _id);
export const addFurnitureRatingService = (data) =>
  privateReq.post("/furniture/add-review", data);
export const viewFurnitureContactService = (data) =>
  privateReq.post("/furniture/view-contact", data);
export const createFurnitureBookingService = (data) =>
  privateReq.post("/furniture/create-booking", data);
export const getFurnitureBookingsService = (data) =>
  privateReq.get("/furniture/get-bookings" + data);
export const cancelFurnitureBookingService = (data) =>
  privateReq.patch("/furniture/cancel-booking", data);

export const putFurnitureOrderVerified = (data) =>
  privateReq.put("/furniture/order-verified", data);
export const approveFurnitureService = (data) =>
  privateReq.post("/approve-furniture", data);
export const addToCartService = (data) =>
  privateReq.post("/furniture/add-cart", data);
export const addToWishlistService = (data) =>
  privateReq.post("/furniture/add-wishlist", data);
export const getMyCartService = () => privateReq.get("/furniture/cart");
export const getMyWishlistService = () => privateReq.get("/furniture/wishlist");
export const getMyShortlistService = () => privateReq.get("/property-list/shortlist");

export const addCartService = (data) =>
  privateReq.post("/furniture/add-many-cart", data);
export const addWishlistService = (data) =>
  privateReq.post("/furniture/add-many-wishlist", data);

export const removeFromCartService = (query) =>
  privateReq.delete("/furniture/cart/remove" + query);
export const removeFromWishlistService = (query) =>
  privateReq.delete("/furniture/wishlist/remove" + query);
