import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
import { useMediaQuery } from "react-responsive";
const AboutUs = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        className="container"
        style={{
          padding: isMobile ? "50px" : "125px",
          marginTop: isMobile ? "50px" : "0",
        }}
      >
        <h2 className="h3-about-tems">About Us</h2>
        <div>
          <p className="para-about-terms">
            Welcome to Gharwalah.com, where your dream home is just a click
            away. www.gharwalah.com is primarily the trademark-applied company
            of Gharwala Home Solutions LLP. Gharwala is India's best one-stop
            solution platform for searching properties, Furniture & Home
            Appliances, and Domestic Services Founded by Sachin Kumar & Ashish
            Das, visionary entrepreneurs with over four years of experience in
            real estate, Gharwalah
            {/* <sup style={{ fontSize: "15px" }}>TM</sup> */}
            .com is a unique amalgamation of diverse real estate and home
            service solutions.
          </p>
          <br />
          <h4 className="h4-about-tems">Our Vision</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            At Gharwalah.com, we envision a world where finding the perfect home
            is not a daunting task but an exciting journey. We understand that
            your living space is a reflection of your aspirations, comfort, and
            lifestyle. That's why we've crafted a platform that offers an array
            of services to cater to your every need.
          </p>
          <br />
          <h4 className="h4-about-tems"> Our Comprehensive Services:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            Home Rental Solutions: Find our ideal rental home with ease. Our
            extensive listings cover a spectrum of properties, ensuring you
            discover the perfect match for your lifestyle and preferences.
            Buying and Selling of Properties: Whether you're looking to invest,
            sell, or buy property, our platform simplifies the real estate
            journey. We connect buyers and sellers, making transactions seamless
            and secure.
          </p>
          <br />
          <h4 className="h4-about-tems">
            {" "}
            Furniture and Home Appliances Rental Solutions:{" "}
          </h4>
          <br />
          <p className="para-about-terms">
            {" "}
            Say goodbye to the hassle of purchasing furniture and home
            appliances. Our rental services provide you with top-quality items,
            offering cost-efficiency, flexibility, and peace of mind.
          </p>
          <br />
          <h4 className="h4-about-tems"> Maid Services:</h4>
          <br />
          <p className="para-about-terms">
            A clean and well-maintained home is a happy home. Our reliable maid
            services ensure your space remains spotless, allowing you to enjoy a
            hygienic living environment.
          </p>
          <br />
          <h4 className="h4-about-tems">
            {" "}
            Technicians for Home Appliance Repairs:
          </h4>
          <p className="para-about-terms">
            {" "}
            When your appliances need fixing, our skilled technicians are just a
            call away. We offer prompt and efficient repair services, ensuring
            your home runs smoothly.
          </p>
          <br />
          <h4 className="h4-about-tems"> Why Gharwalah.com?</h4>
          <br />
          <p className="para-about-terms">
            We're not just a real estate platform; we're your trusted partner in
            shaping your living space. Gharwalah.com is built on the principles
            of transparency, reliability, and customer-centricity. We prioritize
            your comfort, safety, and convenience in every service we offer.
          </p>
          <br />
          <h4 className="h4-about-tems"> Your Journey Begins Here:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            Your search for the perfect home, reliable property transactions,
            top-quality furniture and appliances, expert maid services, and
            efficient appliance repairs begins at Gharwalah.com. We're committed
            to simplifying and enriching your life's most significant decisions.
            Join us on this exciting journey. Explore, discover, and make space
            truly your own with Gharwala
            {/* <sup style={{ fontSize: "15px" }}>TM</sup> */}
            .com.
          </p>
          <br />
          <h4 className="h4-about-tems"> Blog</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            In today's fast-paced world, convenience and flexibility have become
            essential elements of our everyday lives. Gharwalah.com, a
            game-changer in the real estate and home services industry, is on a
            mission to provide a diverse range of online services that make life
            easier, starting from the vibrant streets of Kolkata and expanding
            to homes all across India.
          </p>
          <br />
          <h4 className="h4-about-tems"> Home Rental Solutions:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            Searching for the perfect place to call home in Kolkata or any city
            in India? Gharwalah.com simplifies the process with a vast inventory
            of rental properties. Whether you're looking for a cozy apartment, a
            spacious villa, or anything in between, we've got you covered. Say
            goodbye to endless property visits and hello to your dream home with
            just a few clicks.
          </p>
          <br />
          <h4 className="h4-about-tems"> Buying and Selling of Properties:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            For those ready to make a more permanent commitment to their living
            space, Gharwalah.com connects buyers and sellers seamlessly. Our
            platform offers a secure and transparent space for property
            transactions, ensuring that you can confidently navigate the real
            estate market, whether you're in Kolkata or any other part of the
            country.
          </p>
          <br />
          <h4 className="h4-about-tems">
            {" "}
            Furniture and Home Appliances Rental Solutions:
          </h4>
          <br />
          <p className="para-about-terms">
            {" "}
            The days of hefty upfront investments in furniture and appliances
            are over. Gharwalah.com introduces a cost-effective and flexible
            rental service that caters to your evolving needs. You can select
            top-quality items, avoid maintenance hassles, and switch or upgrade
            as required. Life in Kolkata or anywhere in India just got more
            comfortable.
          </p>
          <br />
          <h4 className="h4-about-tems"> Maid Services:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            A clean and organized home is the foundation of a peaceful life.
            Gharwalah.com's dependable maid services bring cleanliness and
            orderliness to your space. Our skilled and vetted maids ensure your
            living environment remains spotless, allowing you to focus on what
            truly matters to you.
          </p>
          <br />
          <h4 className="h4-about-tems">
            {" "}
            Technicians for Home Appliance Repairs:
          </h4>
          <br />
          <p className="para-about-terms">
            {" "}
            Appliance breakdowns can disrupt your daily routine. With Gharwala
            {/* <sup style={{ fontSize: "15px" }}>TM</sup> */}.com, expert
            technicians are a call away. We offer quick and efficient repair
            services for all your home appliances, making sure your household
            operates smoothly.
          </p>
          <br />
          <h4 className="h4-about-tems"> Why Gharwalah.com?</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            What sets Gharwalah.com apart is our unwavering commitment to
            customer satisfaction. We prioritize transparency, reliability, and
            flexibility in every service we provide. Our journey began in
            Kolkata, and we've since expanded our reach to serve customers
            across India, making lives easier, one home at a time.
          </p>
          <br />
          <h4 className="h4-about-tems"> Join Us on This Exciting Journey:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            Gharwalah.com is more than a real estate platform; we're your
            trusted partner in transforming your living space into the epitome
            of comfort, convenience, and style. Explore the possibilities,
            discover the ease of renting, and experience the peace of mind that
            comes with a well-maintained home From Kolkata then with the vision
            of taking our services Pan India, our mission is clear: to simplify
            your life, one service at a time. Are you ready to embark on this
            journey with us? Visit gharwalah.com today and experience a new era
            of living.
          </p>
        </div>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </>
  );
};

export default AboutUs;
