import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { redirect, useNavigate } from "react-router-dom";
import Copyright from "../copyRight";
import AboutTop from "../../assets/images/about-top.svg";
import { authAdminLoginService } from "../../config/user";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../redux/action/auth";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function AdminLoginComp({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await authAdminLoginService({
        email: email,
        password: password,
      });
      if (res.data.token) {
        if (res.data.status) {
          localStorage.setItem("token", res.data.token);
          dispatch(
            setCurrentUser(res.data.token, () => {
              console.log(res.data, "data");
              toast.success("Login Succesfully");
              let redirect = new URLSearchParams(window.location.search).get(
                "redirect"
              );
              if (redirect) {
                navigate(redirect, { replace: true });
              } else {
                navigate("/", { replace: true });
              }
              setLoader(false);
              // setIsLogin(true)
            })
          );
        }
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          style: {
            zIndex: 99994,
          },
        });
        setLoader(false);
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${AboutTop})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 20,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Gharwala Admin
            </Typography>
            <a
              style={{
                display: "inline-block",
                fontSize: 30,
                color: "#000",
              }}
              href="#"
              onClick={(e) => {
                navigate(-1);
              }}
            >
              <CloseRoundedIcon fontSize="25px" />
              {/* <i class="fa fa-times" aria-hidden="true"></i> */}
            </a>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <button
                type="submit"
                disabled={loader}
                className="btn-auth-signup mb-2 mt-2"
              >
                {loader ? "Please wait..." : "Sign In"}
              </button>
              <Grid container>
                <Grid item xs>
                  {/* <Link href="#" variant="body2">
                    Forgot password?
                  </Link> */}
                </Grid>
                <Grid item>
                  {/* <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link> */}
                </Grid>
              </Grid>
              This portal is only for the admin.
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
