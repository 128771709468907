import React from "react";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
const ReturnPolicy = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        className="container"
        style={{ paddingTop: "125px", marginBottom: "2rem" }}
      >
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Return and Refund Policy
        </h2>{" "}
        <br />
        <p className="para-about-terms">
          {" "}
          At Gharwalah.com, we value your satisfaction with our furniture and
          home appliances rental services. This Return and Refund Policy
          outlines the procedures and guidelines for returning rented items and
          seeking refunds. <br></br> <br></br>{" "}
          <strong>
            {" "}
            <u>1. Returns</u>:
          </strong>{" "}
          Product Condition: Before returning rented items, it's essential to
          ensure that they are in the same condition as when they were
          delivered. Normal wear and tear are acceptable, but any significant
          damage should be reported. <br></br> Return Requests: If you wish to
          return any rented items, please contact our customer support team to
          initiate the return process. We'll guide you through the necessary
          steps. <br></br> Timing: Rental items can be returned at any time,
          subject to the agreed-upon rental period. There's no need to wait
          until the end of the rental period to initiate a return. <br></br>
          <br></br>
          <strong>
            <u>2. Refunds</u>:
          </strong>{" "}
          Refund Eligibility: Refunds are applicable in specific circumstances,
          such as early return or if you encounter issues with the rented items
          that we cannot resolve to your satisfaction. <br></br> Refund Process:
          If you are eligible for a refund, please contact our customer support
          team to start the refund process. Refunds are processed within 7 to 10
          working days. Refund Method: Refunds are typically issued via NEFT to
          the registered customer's account. To expedite the process, please
          provide accurate bank account details. <br></br> Deductions: In some
          cases, a refund may be subject to deductions for any damage to the
          rented items beyond normal wear and tear or other applicable fees. The
          specific deduction amount will be communicated to you during the
          refund process. <br></br>
          <br></br>{" "}
          <strong>
            <u> 3. Cancellations</u>:
          </strong>{" "}
          Cancellations during Rental: If you wish to cancel a rental order
          during the rental period, please contact our customer support team.
          Cancellation requests may be subject to charges or penalties as
          outlined in the cancellation policy. <br></br>
          Cancellations Before Rental: If you cancel a rental order before the
          delivery date, you may be eligible for a full refund. Please refer to
          our cancellation policy for more details. <br></br>
          <br></br>{" "}
          <strong>
            <u>4. Early Returns Early Return</u>:
          </strong>{" "}
          If you decide to return rented items before the end of the agreed-upon
          rental period, please contact our customer support team. Early returns
          may qualify for a partial refund, subject to the early return policy.
          <br></br>
          <br></br>{" "}
          <strong>
            <u>5. Damage Deductions</u>:
          </strong>{" "}
          In cases of damage to the rented items that exceed normal wear and
          tear, deductions may apply. The specific deduction amount will be
          communicated during the refund process.
        </p>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </div>
  );
};

export default ReturnPolicy;
