import React from "react";
import search from "../../assets/images/search-image.png";
import testimonial from "../../assets/images/testimonial-image.png";
import notification from "../../assets/images/notification-bell.png";
import WriteBlog from "../../assets/images/write-blog.png";
import services from "../../assets/images/services.png";
import owner from "../../assets/images/owner.png";
import ReferEarn from "../../assets/images/Refer-earn.png";
import zipNew from "../../assets/images/zip-new.png";
import BrokerNew from "../../assets/images/broker-new.png";
import TabAccess from "../../assets/images/tab-access.png";
import contact from "../../assets/images/contact-data.png";
import TestimonialData from "../../assets/images/testimonal-data.png";
import phoneDiary from "../../assets/images/phoneDiary.png";
import carryingCost from "../../assets/images/carryingCost.png";
import role from "../../assets/images/role.png";


import "./admin.css";
import { Link } from "react-router-dom";

export default function AdminControl() {
  return (
    <>
      <div
        style={{
          marginTop: "100px",
          padding: "20px",
        }}
        //  style={{ marginTop: "100px", padding: "20px",}}
      >
        <div className="admin-head">Admin Control</div>

        <div className="each-box">
          <div className="image-box">
            <Link to="/admin-add-user">
              <img className="zip img-admin" src={phoneDiary} alt="zip" />
            </Link>
            <div className="img-admin-name">Enquires</div>
          </div>

          <div className="image-box">
            <Link to="/role-management">
              <img className="zip img-admin" src={role} alt="zip" />
            </Link>
            <div className="img-admin-name">Role Managementss</div>
          </div>
          <div className="image-box">
            <Link to="/pincodes">
              <img className="img-admin" src={zipNew} alt="zip" />
            </Link>
            <div className="img-admin-name">Pin Code</div>
          </div>

          <div className="image-box">
            <Link to="/owners">
              <img className="img-admin" src={owner} alt="owner" />
            </Link>
            <div className="img-admin-name">Owner</div>
          </div>
          <div className="image-box">
            <Link to="/all-users">
              <img className="img-admin" src={owner} alt="owner" />
            </Link>
            <div className="img-admin-name">All users</div>
          </div>

          <div className="image-box">
            <Link to="/notification">
              <img className="img-admin bell" src={notification} alt="bell" />
            </Link>
            <div className="img-admin-name">Payment Reminder</div>
          </div>

          <div className="image-box">
            <Link to="/admin-category">
              <img className="img-admin search" src={search} alt="search" />
            </Link>
            <div className="img-admin-name">Furniture Category</div>
          </div>

          <div className="image-box">
            <Link to="/brokers">
              <img className="broker img-admin" src={BrokerNew} alt="broker" />
            </Link>
            <div className="img-admin-name">Broker</div>
          </div>

          <div className="image-box">
            <Link to="/tabs-access">
              <img className="tab img-admin" src={TabAccess} alt="tab" />
            </Link>
            <div className="img-admin-name">Tab Access</div>
          </div>

          <div className="image-box">
            <Link to="/refer-scheme">
              <img className="tab img-admin" src={ReferEarn} alt="tab" />
            </Link>
            <div className="img-admin-name">Refer</div>
          </div>

          <div className="image-box">
            <Link to="/blogs/addblog">
              <img className="tab img-admin" src={WriteBlog} alt="tab" />
            </Link>
            <div className="img-admin-name">Blog</div>
          </div>

          <div className="image-box">
            <Link to="/add-testimonial">
              <img className="tab img-admin" src={testimonial} alt="tab" />
            </Link>
            <div className="img-admin-name">Testimonial</div>
          </div>
          <div className="image-box">
            <Link to="/carrying-cost">
              <img className="tab img-admin" src={carryingCost} alt="tab" />
            </Link>
            <div className="img-admin-name">Carrying cost</div>
          </div>

          <div className="image-box">
            <Link to="/edit-services">
              <img className="zip img-admin" src={services} alt="zip" />
            </Link>
            <div className="img-admin-name">Services</div>
          </div>

          <div className="image-box">
            <Link to="/contact">
              <img className="zip img-admin" src={contact} alt="zip" />
            </Link>
            <div className="img-admin-name">Contact</div>
          </div>

          <div className="image-box">
            <Link to="/get-contact">
              <img className="zip img-admin" src={contact} alt="zip" />
            </Link>
            <div className="img-admin-name">Show Contact Data</div>
          </div>

          <div className="image-box">
            <Link to="/get-testimonial">
              <img className="zip img-admin" src={TestimonialData} alt="zip" />
            </Link>
            <div className="img-admin-name">Show Testimonial Data</div>
          </div>

          <div className="image-box">
            <Link to="/pincode-testimonial">
              <img className="zip img-admin" src={TestimonialData} alt="zip" />
            </Link>
            <div className="img-admin-name">Pincode Testimonial</div>
          </div>

          <div className="image-box">
            <Link to="/recent-properties">
              <img className="zip img-admin" src={TestimonialData} alt="zip" />
            </Link>
            <div className="img-admin-name">Recent Properties</div>
          </div>
        </div>
      </div>
    </>
  );
}
