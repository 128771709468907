import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
} from "@mui/material";
import Header from "../../components/Header";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanoramaFishEyeSharpIcon from "@mui/icons-material/PanoramaFishEyeSharp";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import ButtonContained from "../../components/Buttons/ButtonContained";
import { addFurnitureService } from "../../config/furniture";
import { toast } from "react-hot-toast";
import { useTheme } from "@mui/material/styles";
import AddFurnitureStep1 from "./components/Step1";
import AddFurnitureStep2 from "./components/Step2";
import AddFurnitureStep3 from "./components/Step3";
import AddFurnitureStep4 from "./components/Step4";

const AddFurniture = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [boxHeight, setboxHeight] = useState(0);
  const [loader, setLoader] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [sellingOptions, setSellingOptions] = useState("rent");
  const navigate = useNavigate();
  const [data, setData] = useState({
    photos: [],
    furnitureName: "",
    categoryType: "",
    furnitureType: "",
    furnitureLength: "",
    furnitureHeight: "",
    furnitureWidth: "",
    color: "",
    material: "",
    features: "",
    keyValue: [],
    furnitureDetails: [],
    furnitureImages: [],
    sellingOptions: sellingOptions,
    expectedMonthlyRent: "",
    expectedSecurityDeposit: "",
    firstTimePayment: "",
    subcategoryType: "",
    desc: "",
    threeMonthPayment: {
      threeMonthPay: "",
      monthlyRentalSavings: "",
      earlyClosureCharge: "",
      freeAllocation: "",
      freeUpgrade: "",
    },
    sixMonthPayment: {
      sixMonthPay: "",
      monthlyRentalSavings: "",
      earlyClosureCharge: "",
      freeAllocation: "",
      freeUpgrade: "",
    },
    nineMonthPayment: {
      nineMonthPay: "",
      monthlyRentalSavings: "",
      earlyClosureCharge: "",
      freeAllocation: "",
      freeUpgrade: "",
    },
    twelveMonthPayment: {
      twelveMonthPay: "",
      monthlyRentalSavings: "",
      earlyClosureCharge: "",
      freeAllocation: "",
      freeUpgrade: "",
    },
    locality: [],
  });
  const theme = useTheme();
  const boxRef = useRef();

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
    setboxHeight(boxRef.current.offsetHeight);
  }, [headerHeight, boxHeight]);

  const steps = [
    {
      component: AddFurnitureStep1,
      label: "Basic Details",
      subLabel: "Step 1",
    },
    {
      component: AddFurnitureStep2,
      label: "Furniture Details",
      subLabel: "Step 2",
    },
    {
      component: AddFurnitureStep3,
      label: "Photos",
      subLabel: "Step 3",
    },
    {
      component: AddFurnitureStep4,
      label: "Pricing & Others",
      subLabel: "Step 4",
    },
  ];
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCheckBoxChange = (name, val) => {
    let dataArr = [...data[name]];
    let indexOfItem = dataArr.indexOf(val);
    if (indexOfItem == -1) {
      dataArr.push(val);
    } else {
      dataArr.splice(indexOfItem, 1);
    }
    setData({ ...data, [name]: dataArr });
  };

  // const handleTextChange = (name, val) => {
  //   setData({ ...data, [name]: val });
  //   console.log(data);
  // };

  const handleTextChange = (...args) => {
    if (args.length === 2) {
      const [name, val] = args;
      setData({ ...data, [name]: val });
    } else if (args.length === 3) {
      const [parentName, name, val] = args;
      setData({
        ...data,
        [parentName]: {
          ...data[parentName],
          [name]: val,
        },
      });
    }
    console.log(data);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoader(true);
      const obj = {
        ...data,
        sellingOptions: sellingOptions,
        furnitureImages: data.photos,
        threeMonthPayment: JSON.stringify(data.threeMonthPayment),
        sixMonthPayment: JSON.stringify(data.sixMonthPayment),
        nineMonthPayment: JSON.stringify(data.nineMonthPayment),
        twelveMonthPayment: JSON.stringify(data.twelveMonthPayment),

        locality: JSON.stringify(data.locality),
        keyValue: JSON.stringify(data.keyValue),
      };
      delete obj.photos;
      const fd = new FormData();
      for (let key in obj) {
        if (Array.isArray(obj[key]) && obj[key].length > 0) {
          obj[key].forEach((i, k) => {
            fd.append(key, obj[key][k]);
          });
        } else {
          fd.append(key, obj[key]);
        }
      }
      const res = await addFurnitureService(fd);

      if (res.data.success) {
        toast.success("Furniture Added Successfully");
        navigate("/");
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <div className="container-fluid px-0">
        <div className="properties-container">
          <div className="row mx-0 w-100">
            <div className="row mx-0 w-100 px-0">
              <div className="col-lg-3 col-md-3 col-xs-12 bg-white border-box h-100 px-0">
                <Box
                  className="d-flex align-items-center py-3 border-bottom p-4 w-100"
                  sx={[styles.color9A291B]}
                >
                  <Link
                    onClick={() => navigate(-1)}
                    className="d-flex align-items-center text-decoration-none"
                  >
                    <KeyboardBackspaceIcon sx={[styles.color9A291B]} />
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#950000",
                        marginTop: "10px",
                      }}
                    >
                      Listing
                    </p>

                    <Typography
                      sx={[
                        styles.color9A291B,
                        styles.fs24,
                        styles.fbBold,
                        styles.fontFamilyDS,
                      ]}
                      className="px-1"
                    ></Typography>
                  </Link>
                </Box>
              </div>
              <div
                className="col-lg-9 col-md-9 col-xs-12 border-box border-bottom p-3"
                style={{ borderLeft: "none" }}
                ref={boxRef}
              ></div>
            </div>
            <div className="row mx-0 w-100 ">
              <Box className="w-100 row">
                <div
                  className="col-lg-3 col-md-3 col-xs-12 border-box border-top-0 py-3"
                  style={{ borderLeft: "none" }}
                >
                  <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    connector={false}
                    className="pt-4"
                    sellingOptions={sellingOptions}
                  >
                    {steps.map((Item, index) => {
                      return (
                        <Step key={index}>
                          <Box>
                            <StepLabel
                              icon={
                                index > activeStep ? (
                                  <PanoramaFishEyeSharpIcon />
                                ) : (
                                  <CheckCircleIcon sx={[styles.color9A291B]} />
                                )
                              }
                              tabIndex={0}
                              className="p-0"
                            >
                              <Typography
                                sx={[
                                  styles.fs20,
                                  styles.fontFamilyDS,
                                  styles.fontWeight500,
                                  styles.color240501,
                                ]}
                              >
                                {Item.label}
                              </Typography>
                              <Typography
                                className="text-color979797"
                                sx={[styles.fs14, styles.fontFamilyDS]}
                              >
                                {Item.subLabel}
                              </Typography>
                            </StepLabel>
                            {steps.length !== index + 1 && <StepConnector />}
                          </Box>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>
                <div
                  className="col-lg-9 col-md-9 col-xs-12 px-4 hidden-scrollbar"
                  id="step-container"
                  style={{
                    height: `calc(100vh - ${headerHeight}px - ${boxHeight}px)`,
                    overflowY: "scroll",
                  }}
                >
                  <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    connector={false}
                    sellingOptions={sellingOptions}
                  >
                    {steps.map((Item, index) => {
                      return (
                        <Step key={index} className="d-flex">
                          <StepContent
                            className="border-0 w-100"
                            sx={[
                              {
                                [theme.breakpoints.between("xs", "md")]: [
                                  {
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    marginLeft: "12px",
                                  },
                                ],
                              },
                            ]}
                          >
                            <Item.component
                              setActiveStep={setActiveStep}
                              activeStep={activeStep}
                              setData={setData}
                              handleTextChange={handleTextChange}
                              data={data}
                              totalSteps={steps.length}
                              currentStep={activeStep}
                              sellingOptions={sellingOptions}
                              setSellingOptions={setSellingOptions}
                            />
                            <Box className="mt-4 px-4 d-flex w-60 pb-5">
                              <ButtonOutlined
                                sx={[
                                  {
                                    [theme.breakpoints.between("xs", "md")]: [
                                      { minWidth: "100px", maxWidth: "100px" },
                                    ],
                                  },
                                ]}
                                onClick={() => {
                                  if (activeStep !== 0) handleBack();
                                  document
                                    .getElementById("step-container")
                                    .scrollTo(0, 0);
                                }}
                              >
                                {activeStep === 0 ? "Cancel" : "Previous"}
                              </ButtonOutlined>
                              <ButtonContained
                                sx={[
                                  {
                                    [theme.breakpoints.between("xs", "md")]: [
                                      { minWidth: "100px", maxWidth: "100px" },
                                    ],
                                  },
                                ]}
                                onClick={(e) => {
                                  if (steps.length === activeStep + 1) {
                                    console.log(data);
                                    onSubmit(e);
                                  } else {
                                    handleNext();
                                    document
                                      .getElementById("step-container")
                                      .scrollTo(0, 0);
                                  }
                                }}
                              >
                                {steps.length === activeStep + 1
                                  ? "Finish"
                                  : "Next"}
                              </ButtonContained>
                            </Box>
                          </StepContent>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFurniture;
