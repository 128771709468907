import React, { useRef, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  Typography,
  Slider,
  MenuItem,
  TextField,
} from "@mui/material";
import { SliderThumb } from "@mui/material/Slider";
import BedSvg from "../../assets/images/bed.svg";
import BathSvg from "../../assets/images/bed.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Header from "../../components/Header";
import classes from "../../styles/styles.js";
import ButtonContained from "../../components/Buttons/ButtonContained";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import publicReq, { API_KEY, assetUrl } from "../../config/axiosConfig";
import { getProperties } from "../../redux/action/property";
import ReactGoogleAutocomplete, {
  usePlacesWidget,
} from "react-google-autocomplete";
import { useMediaQuery } from "react-responsive";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import toast from "react-hot-toast";
import { privateReq } from "../../config/axiosConfig";

function valuetext(value) {
  return `${value}°C`;
}

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "rgba(154, 41, 27, 1)",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    // border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));
function SliderThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
    </SliderThumb>
  );
}
export const getLocLatLng = (district) => {
  return geocodeByAddress(district?.label)
    .then((results) => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log("Successfully got latitude and longitude", { lat, lng });
      return { lat, lng };
    })
    .catch((err) => {
      console.log(err);
    });
};
const ManageProperties = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [budget, setBudget] = useState([1, 1000000000]);
  const [usp, setUsp] = useState([]);
  const [wantTo, setWantTo] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [accessoriesFilter, setAccessoryFilter] = useState([]);
  const [locality, setLocality] = useState({});
  const { properties } = useSelector((state) => state.property);
  const {
    accessories,
    user,
    location: userLocation,
  } = useSelector((state) => state.auth);
  const [sortby, setSortBy] = useState("created");
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showFilter, setShowFilter] = useState(false);
  const [go, setGo] = useState(0);
  const [showServices, setShowServices] = useState();

  function checkTabS2() {
    publicReq
      .post("/get-access-for-basic-features", { propertyName: "PROPERTY" })
      .then((result) => {
        setShowServices(result.data.message.enabled);
        console.log(result.data.message.enabled);
        if (result.data.message.enabled === false) {
          navigate("/");
        }
      })
      .catch((error) => {
        toast.error("something went wrong");
        console.log(error);
      });

    // const results = await Promise.all(promises);

    // setBroker(results[0].data.message.enabled);
    // setOwner(results[1].data.message.enabled);
    // setService(results[2].data.message.enabled);
    // setProperty(results[3].data.message.enabled);
  }

  useEffect(() => {
    checkTabS2();
  }, []);

  const { ref } = usePlacesWidget({
    apiKey: API_KEY,
    options: {
      componentRestrictions: { country: "in" },
      types: ["(regions)"],
    },
    onPlaceSelected: (place) => {
      setLocality({
        lng: place.geometry.location.lng(),
        lat: place.geometry.location.lat(),
      });
    },
  });

  function deleteProperty(id) {
    privateReq
      .delete(`/delete-property/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Property deleted");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getPropertiesInitial();
      });
  }

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerHeight]);

  function numDifferentiation(val) {
    if (val >= 10000000) val = val / 10000000 + " Cr";
    else if (val >= 100000) val = val / 100000 + " Lac";
    else if (val >= 1000) val = val / 1000 + ",000";
    return val;
  }

  const onCheck = (state, val, setState) => {
    const arr = [...state];
    if (arr.includes(val)) {
      let idx = arr.findIndex((i) => i === val);
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    setState(arr);
  };

  const onResetFilter = () => {
    setPropertyType([]);
    setWantTo([]);
    setPropertyType([]);
    setBudget([1, 1000000000]);
    setAccessoryFilter([]);
    setUsp([]);
    // dispatch(getProperties(``));
    setShowFilter(false);
  };

  async function setListingToinactive() {
    toast.success("Changed service to inactive");
  }

  useEffect(() => {
    if (location.search) {
      let params = new URLSearchParams(location.search);
      if (params.get("wantto")) {
        setWantTo([params.get("wantto")]);
      }
      if (params.get("propertyType")) {
        setPropertyType([params.get("propertyType")]);
      }
      if (params.get("max")) {
        setBudget([1, parseInt(params.get("max"))]);
      }
      if (params.get("latitude") && params.get("longitude")) {
        setLocality({
          lng: params.get("longitude"),
          lat: params.get("latitude"),
        });
      }

      setGo((prev) => prev + 1);

      // let timeout = setTimeout(() => {
      //   onApplyFilter();
      //   clearTimeout(timeout);
      // }, 500);
    } else if (userLocation.lat !== undefined && userLocation.lng !== undefined)
      getPropertiesInitial();
    else getPropertiesInitial();
  }, []);

  useEffect(() => {
    if (go >= 1) onApplyFilter();
  }, [go]);

  // useEffect(() => {
  //   if (location.search) {
  //     let params = new URLSearchParams(location.search);
  //     if (params.get('wantto')) {
  //       setWantTo([params.get('wantto')]);
  //     }
  //     if (params.get('propertyType')) {
  //       setPropertyType([params.get('propertyType')]);
  //     }
  //     if (params.get('max')) {
  //       setBudget([1, parseInt(params.get('max'))]);
  //     }
  //     if (params.get('latitude') && params.get('longitude')) {
  //       setLocality({
  //         lng: params.get('longitude'),
  //         lat: params.get('latitude'),
  //       });
  //     }
  //     let timeout = setTimeout(() => {
  //       onApplyFilter();
  //       clearTimeout(timeout);
  //     }, 500);
  //   }
  // }, [location.search]);

  const onApplyFilter = async () => {
    // event.preventDefault();
    try {
      const data = {
        priceRange: budget,
        usp,
        propertyType,
        wantTo,
        accessories: accessoriesFilter,
      };
      let loc =
        locality.lng && locality.lat ? locality : await getLocLatLng(locality);
      if (loc?.lng && loc?.lat) {
        data.longitude = loc?.lng;
        data.latitude = loc?.lat;
      }

      let query = `?`;
      // Object.keys(data).map((key, idx) => {
      //   if (key === 'priceRange') {
      //     query += `priceRange=1&priceRange=${data[key][1]}`;
      //   } else if (Array.isArray(data[key])) {
      //     data[key].forEach((item, index) => {
      //       query += `${idx > 0 || index > 0 ? '&' : ''}${key}=${item}`;
      //     });
      //   } else {
      //     query += `${idx > 0 ? '&' : ''}${key}=${data[key]}`;
      //   }
      // });

      Object.keys(data).map((key) => {
        if (key === "priceRange") {
          query += `priceRange=1&priceRange=${data[key][1]}`;
        } else {
          query += `${key}=${data[key]}`;
        }
      });

      if (sortby) {
        query += `&sortby=${sortby}`;
      }

      console.log(query);

      dispatch(getProperties(query + "&selfData=true"));
      setShowFilter(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getPropertiesInitial = () => {
    // event.preventDefault();
    try {
      const data = {
        priceRange: budget,
        usp,
        propertyType,
        wantTo,
        accessories: accessoriesFilter,
      };

      let query = `?`;
      Object.keys(data).map((key, idx) => {
        if (Array.isArray(data[key])) {
          data[key].forEach((item, index) => {
            query += `${idx > 0 || index > 0 ? "&" : ""}${key}=${item}`;
          });
        } else {
          query += `${idx > 0 ? "&" : ""}${key}=${data[key]}`;
        }
      });
      if (sortby) {
        query += `&sortby=${sortby}`;
      }
      dispatch(getProperties(query + "&selfData=true"));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <div className="container-fluid px-0">
        <div className="properties-container bgF4F4F4">
          <div className="row mx-0 w-100">
            {isMobile && (
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
                sx={[
                  classes.colorADB0B6,
                  classes.fs20,
                  classes.fontFamilyDS,
                  classes.fbBold,
                  classes.textDecorationUL,
                  {
                    cursor: "pointer",
                    zIndex: 9,
                  },
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                      ...classes.fbBold,
                    },
                  },
                ]}
              >
                Show Filters
              </Typography>
            )}
            {(isMobile && showFilter) || !isMobile ? (
              <div
                className="col-lg-3 col-md-3 col-xs-12 bg-white p-4 container-scrollbar"
                style={{
                  height: isMobile ? "auto" : `calc(100vh - ${headerHeight}px)`,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <Box
                  className="mt-3"
                  sx={[
                    classes.p10,
                    classes.dflex,
                    classes.jcSBetween,
                    classes.alignItemCenter,
                  ]}
                >
                  <Typography
                    component="div"
                    variant="h5"
                    sx={[
                      classes.fontFamilySGB,
                      classes.fbBold,
                      classes.fs24,
                      classes.dflex,
                      classes.color240501,
                      {
                        [theme.breakpoints.between("xs", "md")]: {
                          ...classes.fs17,
                        },
                      },
                    ]}
                  >
                    Filters
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    onClick={onResetFilter}
                    sx={[
                      classes.colorADB0B6,
                      classes.fs20,
                      classes.fontFamilyDS,
                      classes.fbBold,
                      classes.textDecorationUL,
                      {
                        cursor: "pointer",
                      },
                      {
                        [theme.breakpoints.between("xs", "md")]: {
                          ...classes.fs14,
                          ...classes.fbBold,
                        },
                      },
                    ]}
                  >
                    Reset Filters
                  </Typography>
                </Box>
                <GooglePlacesAutocomplete
                  apiKey={API_KEY}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["in"],
                    },
                  }}
                  selectProps={{
                    district: locality,
                    onChange: setLocality,
                    placeholder: "Enter City, Locality",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  sx={[
                    classes.colorADB0B6,
                    classes.fs20,
                    classes.fontFamilyDS,
                    classes.fbBold,
                    classes.p10,
                    {
                      [theme.breakpoints.between("xs", "md")]: {
                        ...classes.fs17,
                      },
                    },
                  ]}
                >
                  Want To
                </Typography>
                <Box component="span" sx={{ display: "block" }}>
                  {/* <FormControl component="fieldset">
                  <FormGroup
                    aria-label="position"
                    xs={[classes.dBlock, classes.fdc]}
                  > */}
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      Rent
                    </Typography>
                    <Checkbox
                      value={"rent"}
                      onChange={(e) =>
                        onCheck(wantTo, e.target.value, setWantTo)
                      }
                      checked={wantTo.includes("rent")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      Buy
                    </Typography>
                    <Checkbox
                      value="sell"
                      onChange={(e) =>
                        onCheck(wantTo, e.target.value, setWantTo)
                      }
                      checked={wantTo.includes("sell")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  {/* <Box
                    className='d-flex justify-content-between w-100 align-items-center'
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between('xs', 'md')]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      PG
                    </Typography>
                    <Checkbox
                      value='pg'
                      onChange={(e) => onCheck(wantTo, e.target.value, setWantTo)}
                      checked={wantTo.includes('pg')}
                      sx={{
                        '&.Mui-checked': classes.color9A291B,
                      }}
                    />
                  </Box> */}
                </Box>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  sx={[
                    classes.colorADB0B6,
                    classes.fs20,
                    classes.fontFamilyDS,
                    classes.fbBold,
                    classes.p10,
                    {
                      [theme.breakpoints.between("xs", "md")]: {
                        ...classes.fs17,
                      },
                    },
                  ]}
                >
                  Property Type
                </Typography>
                <Box component="span" sx={{ display: "block" }}>
                  {/* <FormControl component="fieldset">
                  <FormGroup
                    aria-label="position"
                    xs={[classes.dBlock, classes.fdc]}
                  > */}
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      Flat
                    </Typography>
                    <Checkbox
                      value="flat"
                      onChange={(e) =>
                        onCheck(propertyType, e.target.value, setPropertyType)
                      }
                      checked={propertyType.includes("flat")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      House
                    </Typography>
                    <Checkbox
                      value="house"
                      onChange={(e) =>
                        onCheck(propertyType, e.target.value, setPropertyType)
                      }
                      checked={propertyType.includes("house")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      Villa
                    </Typography>
                    <Checkbox
                      value="villa"
                      onChange={(e) =>
                        onCheck(propertyType, e.target.value, setPropertyType)
                      }
                      checked={propertyType.includes("villa")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      Commercial Space
                    </Typography>
                    <Checkbox
                      value="commercial space"
                      onChange={(e) =>
                        onCheck(propertyType, e.target.value, setPropertyType)
                      }
                      checked={propertyType.includes("commercial space")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      Land
                    </Typography>
                    <Checkbox
                      value="land"
                      onChange={(e) =>
                        onCheck(propertyType, e.target.value, setPropertyType)
                      }
                      checked={propertyType.includes("land")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>

                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      Pg
                    </Typography>
                    <Checkbox
                      value="pg"
                      onChange={(e) =>
                        onCheck(propertyType, e.target.value, setPropertyType)
                      }
                      checked={propertyType.includes("pg")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>

                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      Flat mate / Room mate
                    </Typography>
                    <Checkbox
                      value="flatmate"
                      onChange={(e) =>
                        onCheck(propertyType, e.target.value, setPropertyType)
                      }
                      checked={propertyType.includes("flatmate")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  sx={[
                    classes.colorADB0B6,
                    classes.fs20,
                    classes.fontFamilyDS,
                    classes.fbBold,
                    classes.p10,
                    {
                      [theme.breakpoints.between("xs", "md")]: {
                        ...classes.fs17,
                      },
                    },
                  ]}
                >
                  Budget
                </Typography>
                <Box sx={[classes.px10, { width: "100%" }]}>
                  {/* <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={value}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  color={"secondary"}
                /> */}
                  <AirbnbSlider
                    slots={{ thumb: SliderThumbComponent }}
                    onChange={(e, value, activeThum) => {
                      console.log(value);
                      setBudget(value.map((i) => Math.floor(i)));
                    }}
                    value={budget}
                    min={1}
                    max={1000000000}
                  />
                  <div className="d-flex gap-2">
                    <TextField
                      value={budget[0]}
                      variant="outlined"
                      label="From"
                      onChange={(e) => {
                        setBudget([e.target.value, budget[1]]);
                      }}
                    />
                    <TextField
                      variant="outlined"
                      label="To"
                      value={budget[1]}
                      onChange={(e) => {
                        setBudget([budget[0], e.target.value]);
                      }}
                    />
                  </div>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  sx={[
                    classes.color240501,
                    classes.fs16,
                    classes.fontFamilyDS,
                    classes.fontWeight500,
                    classes.p10,
                    classes.lh24,
                  ]}
                >
                  {numDifferentiation(budget[0])} -{" "}
                  {numDifferentiation(budget[1])} Rupees
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  sx={[
                    classes.colorADB0B6,
                    classes.fs20,
                    classes.fontFamilyDS,
                    classes.fbBold,
                    classes.p10,
                    {
                      [theme.breakpoints.between("xs", "md")]: {
                        ...classes.fs17,
                      },
                    },
                  ]}
                >
                  USP
                </Typography>
                <Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      Couple Friendly
                    </Typography>
                    <Checkbox
                      onChange={(e) => onCheck(usp, e.target.value, setUsp)}
                      checked={usp.includes("couple friendly")}
                      value="couple friendly"
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      Pet Friendly
                    </Typography>
                    <Checkbox
                      value="pet friendly"
                      onChange={(e) => onCheck(usp, e.target.value, setUsp)}
                      checked={usp.includes("pet friendly")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      Restriction Free
                    </Typography>
                    <Checkbox
                      onChange={(e) => onCheck(usp, e.target.value, setUsp)}
                      checked={usp.includes("restriction free")}
                      value="restriction free"
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  sx={[
                    classes.colorADB0B6,
                    classes.fs20,
                    classes.fontFamilyDS,
                    classes.fbBold,
                    classes.p10,
                    {
                      [theme.breakpoints.between("xs", "md")]: {
                        ...classes.fs17,
                      },
                    },
                  ]}
                >
                  Accessories
                </Typography>
                <Box>
                  {accessories.map((item) => (
                    <Box
                      className="d-flex justify-content-between w-100 align-items-center"
                      sx={classes.px10}
                    >
                      <Typography
                        sx={[
                          classes.fs16,
                          classes.color240501,
                          classes.fontFamilyDS,
                          {
                            [theme.breakpoints.between("xs", "md")]: {
                              ...classes.fs14,
                            },
                          },
                        ]}
                      >
                        {item.accessoryName}
                      </Typography>
                      <Checkbox
                        onChange={(e) =>
                          onCheck(
                            accessoriesFilter,
                            e.target.value,
                            setAccessoryFilter
                          )
                        }
                        checked={accessoriesFilter.includes(item._id)}
                        value={item._id}
                        sx={{
                          "&.Mui-checked": classes.color9A291B,
                        }}
                      />
                    </Box>
                  ))}
                </Box>
                {/* <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={[
                  classes.colorADB0B6,
                  classes.fs20,
                  classes.fontFamilyDS,
                  classes.fbBold,
                  classes.p10,
                ]}
              >
                Age of Property
              </Typography>
              <Box sx={classes.px10}>
                 <AirbnbSlider
                  slots={{ thumb: SliderThumbComponent }}
                  onChange={(e, value, activeThum) => {
                    setAgeOfProperty(value);
                  }}
                  value={ageOfProperty}
                  min={0}
                  max={10}
                />
              </Box>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={[
                  classes.color240501,
                  classes.fs16,
                  classes.fontFamilyDS,
                  classes.fontWeight500,
                  classes.p10,
                  classes.lh24,
                ]}
              >
                {ageOfProperty[0]} - {ageOfProperty[1]} Years
              </Typography> */}
                <Box>
                  <ButtonContained
                    onClick={onApplyFilter}
                    sx={[
                      classes.bg9A291B,
                      classes.width95,
                      classes.fbBold,
                      classes.fontFamilySGB,
                      classes.fs16,
                      classes.colorWhite,
                      classes.borderRadius12,
                      classes.mt50,
                    ]}
                  >
                    Apply Filter
                  </ButtonContained>
                </Box>
              </div>
            ) : null}
            <div
              className="col-lg-9 col-md-9 col-xs-12"
              // style={{
              //   height: `calc(100vh - ${headerHeight}px)`,
              //   overflowY: 'scroll',
              // }}
            >
              <div className="row properties-list mx-0">
                <div className="row properties-header mt-5 px-3">
                  <div className="col-lg-6 col-md-6 col-sm-12 heading-properties">
                    <span>
                      {`${
                        user?.role === "admin"
                          ? "All properties"
                          : ` Properties 
                      ${
                        user?.role === "broker" ||
                        user?.role === "developer" ||
                        user?.role === "owner"
                          ? "Listed By"
                          : "Near"
                      } 
                      You`
                      }`}
                    </span>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 properties-filter-select"
                    // style={{ marginRight: "1.25rem" }}
                  >
                    {/* <FormControl sx={{ display: 'flex' }}> */}

                    <div className="select-input">
                      <Typography
                        id="label"
                        sx={[classes.textSortBy, classes.fontFamilyDS]}
                      >
                        Sort by:
                      </Typography>
                      <Select
                        labelId="label"
                        id="select"
                        value={sortby}
                        onChange={(e) => setSortBy(e.target.value)}
                        IconComponent={KeyboardArrowDownIcon}
                        style={{ width: "300px" }}
                        sx={[
                          classes.fontFamilyDS,
                          classes.fbBold,
                          {
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          },
                        ]}
                      >
                        {/* <MenuItem value="10">Relenvance</MenuItem> */}
                        <MenuItem value="created">Newest First</MenuItem>
                        <MenuItem value="low-to-high">
                          Price Low To High
                        </MenuItem>
                        <MenuItem value="high-to-low">
                          Price High To Low
                        </MenuItem>
                      </Select>
                    </div>
                    {/* </FormControl> */}
                  </div>
                </div>
                <div className="d-flex flex-wrap">
                  {properties &&
                    properties?.map((card, index) => (
                      <div
                        className={`col-lg-4 col-md-4 col-sm-12 ${
                          isMobile && " w-100"
                        }`}
                      >
                        <div className="card-container">
                          <div key={index} className="card">
                            <div
                              onClick={
                                () =>
                                  window.open(
                                    `/properties/${
                                      card?.route_title || card._id
                                    }`,
                                    "_blank"
                                  )
                                // navigate(
                                //   `/properties/${card?.route_title || card._id}`
                                // )
                              }
                              className="card-image-properties"
                              style={{
                                backgroundImage: `url(${card.image})`,
                                backgroundSize: "100%",
                                backgroundRepeat: "no-repeat",
                                backgroundClip: "content-box",
                                backgroundPosition: "center",
                              }}
                            ></div>
                            <div className="text-info">
                              <div className="slider-card-header">
                                <h2 className="slider-title">{card.title}</h2>
                                <span className="slider-title price-text">
                                  ₹
                                  {Intl.NumberFormat("en-IN").format(
                                    card?.expectedMonthlyRent
                                  )}
                                  {card?.wantto !== "sell" && "/month"}
                                </span>
                              </div>
                              <p className="property-desc">
                                {card.description}
                              </p>
                              <span
                                style={{
                                  color: "gray",
                                  fontSize: "small",
                                }}
                              >
                                Listed on: {card.created.slice(0, 10)}
                              </span>
                              <div
                                style={{
                                  color: "#00B908",
                                  fontSize: "small",
                                  fontWeight: "bold",
                                }}
                              >
                                {card.createdBy !== "" && (
                                  <div>
                                    Post by:{" "}
                                    {card.createdBy || "Gharwala Exclusive"}
                                  </div>
                                )}
                              </div>
                              <div className="propety-icons">
                                <div className="property-icon">
                                  <img src={BedSvg} />
                                  <span className="text-color979797 property-icon-text">
                                    {card.bedrooms}
                                  </span>
                                </div>
                                {card.bathrooms && (
                                  <div className="property-icon">
                                    <img src={BathSvg} />
                                    <span className="property-icon-text text-color979797">
                                      {card.bathrooms}
                                    </span>
                                  </div>
                                )}
                                {user && user?.role !== "guest" && (
                                  <div className="property-icon">
                                    <span
                                      style={{
                                        color: card.status ? "green" : "red",
                                      }}
                                      className="property-icon-text text-color979797 px-2"
                                    >
                                      {card.status ? "Active" : "Deactivated"}
                                    </span>
                                  </div>
                                )}
                                {/* <div className="property-icon">
                                <img src={FloorSvg} />
                                <span className="text-color979797 property-icon-text">
                                  1
                                </span>
                              </div> */}
                                {/* <div className="property-icon">
                                <img src={AreaSvg} />
                                <span className="text-color979797 property-icon-text">
                                  2000 Ft
                                </span>
                              </div> */}
                              </div>
                            </div>
                            <div className="property-slider-buttons px-2">
                              {/* <button className="btn-connect">Connect </button> */}
                              {/* <Link to={`/property-detail/${card._id}`} style={{ textDecoration: "none", color: "#000" }}> */}
                              <ButtonContained
                                // onClick={()=>{
                                //   {`/property-detail/${card._id}`}
                                // }}
                                onClick={
                                  () =>
                                    window.open(
                                      `/properties/${
                                        card?.route_title || card._id
                                      }`,
                                      "_blank"
                                    )
                                  // navigate(
                                  //   `/properties/${
                                  //     card?.route_title || card._id
                                  //   }`
                                  // )
                                }
                                sx={[
                                  classes.borderRadius12,
                                  classes.fs12,
                                  classes.margin4,
                                  classes.p10,
                                  {
                                    [theme.breakpoints.between("xs", "md")]: {
                                      ...classes.fs9,
                                      ...classes.margin4,
                                      ...classes.borderRadius7,
                                      ...classes.p7,
                                    },
                                  },
                                ]}
                                className="m-0 mx-1 my-2"
                              >
                                Connect
                              </ButtonContained>
                              {/* </Link> */}
                              {/* <button className="btn-connect btn-details col-6">
                            View Detail{" "}
                          </button> */}
                              {/* <Link to= style={{ textDecoration: "none", color: "#000" }}> */}
                              <ButtonOutlined
                                sx={[
                                  classes.borderRadius12,
                                  classes.fs12,
                                  classes.margin4,
                                  classes.p10,
                                  {
                                    [theme.breakpoints.between("xs", "md")]: {
                                      ...classes.fs9,
                                      ...classes.margin4,
                                      ...classes.borderRadius7,
                                      ...classes.p6,
                                    },
                                  },
                                ]}
                                onClick={
                                  () =>
                                    window.open(
                                      `/properties/${
                                        card?.route_title || card._id
                                      }`,
                                      "_blank"
                                    )
                                  // navigate(
                                  //   `/properties/${
                                  //     card?.route_title || card._id
                                  //   }`
                                  // )
                                }
                              >
                                View Detail
                              </ButtonOutlined>
                              {user && user.role === "admin" && (
                                <ButtonOutlined
                                  sx={[
                                    classes.borderRadius12,
                                    classes.fs12,
                                    classes.margin4,
                                    classes.p10,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...classes.fs9,
                                        ...classes.margin4,
                                        ...classes.borderRadius7,
                                        ...classes.p6,
                                      },
                                    },
                                  ]}
                                  onClick={() => deleteProperty(card._id)}
                                >
                                  Delete
                                </ButtonOutlined>
                              )}
                              {/* </Link> */}
                            </div>
                            <div>
                              {user &&
                                (user?.role === null ||
                                  user?.role !== "guest") && (
                                  <button
                                    className="inactive-listing"
                                    onClick={() => setListingToinactive()}
                                  >
                                    Inactive this Listing
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <StickyFooter /> */}
    </>
  );
};

export default ManageProperties;
