import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link, UserRoundPlus, Award, Copy } from "lucide-react";
import Footer from "../../components/Footer";
import { EmailForm } from "../../components/EmailForm";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../config/user";
import toast from "react-hot-toast";

function ReferAndEarn({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) {
  const { user } = useSelector((state) => state.auth);
  const [referCode, setReferCode] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  async function getProfile() {
    const res = await getUserProfile();
    setReferCode(res?.data?.user.referral_code);
  }

  useEffect(() => {
    getProfile();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(referCode)
      .then(() => {
        toast.success("Refer Code Copied");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        className="refer-and-earn"
        style={{
          paddingInline: isMobile ? "4px" : "200px",
          paddingBlock: isMobile ? "50px" : "100px",
          backgroundColor: "#ffebe6",
        }}
      >
        <div
          className="refer-and-earn-head-text"
          style={{
            color: "#9a291b",
            fontSize: isMobile ? "20px" : "40px",
            fontWeight: "bolder",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          Refer And Earn
        </div>
        <div
          className="refer-now-head"
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "20px",
            marginTop: "30px",
            paddingInline: isMobile ? "20px" : "60px",
            paddingBlock: isMobile ? "20px" : "30px",
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          <div
            className="refer-now-first-container"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <div
              style={{
                borderBottom: "2px solid #ececec",
                paddingBlock: "20px",
              }}
            >
              <div
                style={{
                  fontWeight: "bolder",
                  fontSize: "20px",
                }}
              >
                Spread the world and earn reward
              </div>
              <div>Get 40 credits for each person you refer to Gharwala</div>
            </div>
            <div
              style={{
                paddingBlock: "10px",
              }}
            >
              <div
                style={{
                  fontWeight: "bolder",
                }}
              >
                Share your unique referral link
              </div>
              <div
                className="refer-now-link-copy"
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <input
                  style={{
                    width: isMobile ? "100%" : "300px",
                    borderRadius: "8px",
                    border: "2px solid #ececec",
                    paddingLeft: "10px",
                  }}
                  value={referCode}
                  placeholder="https:refer&earn"
                />
                <button
                  onClick={copyToClipboard}
                  style={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <Copy /> Copy
                </button>
              </div>
              {user ? <EmailForm /> : null}
            </div>
          </div>

          <div
            className="refer-now-sec-container"
            style={{
              border: "2px solid #ececec",
              borderRadius: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                padding: "20px",
                borderBottom: "2px solid #ececec",
              }}
            >
              <div
                style={{
                  color: "#9a291b",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  backgroundColor: "#ffece6",
                }}
              >
                <Link />
              </div>
              <div>
                <div style={{ fontWeight: "bolder" }}>
                  Share your referral link
                </div>
                <div>
                  Invite your friends to join the Gharwala using your <br />
                  unique referral link.
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                padding: "20px",
                borderBottom: "2px solid #ececec",
              }}
            >
              <div
                style={{
                  color: "#9a291b",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  backgroundColor: "#ffece6",
                }}
              >
                <UserRoundPlus />
              </div>
              <div>
                <div style={{ fontWeight: "bolder" }}>Your friend joins</div>
                <div>
                  When your friends join Gharwala through your shared <br />
                  link, they become a part of our community.
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                padding: "20px",
              }}
            >
              <div
                style={{
                  color: "#9a291b",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  backgroundColor: "#ffece6",
                }}
              >
                <Award />
              </div>
              <div>
                <div style={{ fontWeight: "bolder" }}>You both earn reward</div>
                <div>
                  As a token of appreciation both you and your friend will{" "}
                  <br />
                  receive 40 credits each.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ReferAndEarn;
