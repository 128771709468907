import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Modal,
  Switch,
} from "@mui/material";
import { privateReq } from "../../config/axiosConfig";
import toast from "react-hot-toast";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop.js";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleIcon from "@mui/icons-material/People";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddIcon from "@mui/icons-material/Add";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  maxHeight: "90vh",
  overflow: "auto",
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};

const AdminAddUsers = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    role: "guest",
    localities: [],
    budget: {
      min: "",
      max: "",
    },
    type: "new",
    property_type: "",
    created: "",
  });
  let allPropertyType = [
    "flat",
    "house",
    "villa",
    "land",
    "pg",
    "commercial",
    "Flat mate / Room mate",
  ];
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [enquiries, setEnquiries] = useState([]);
  const [error, setError] = useState("");
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    fetchEnquiries();
    setHeaderHeight(headerRef.current.offsetHeight);
  }, []);

  const fetchEnquiries = async () => {
    try {
      const response = await privateReq.get("/get-enquiries-admin");
      setEnquiries(response.data.data);
    } catch (err) {
      console.error("Error fetching enquiries:", err);
      toast.error("Failed to fetch enquiries");
    }
  };

  const handleLocationSelect = async (location) => {
    try {
      const address = location.label;
      const results = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(results[0]);

      if (!selectedLocations.some((loc) => loc.label === location.label)) {
        setSelectedLocations([...selectedLocations, location]);
        setFormData((prev) => ({
          ...prev,
          localities: [...prev.localities, location.label],
        }));
      }
    } catch (error) {
      console.error("Error selecting location:", error);
      toast.error("Error selecting location");
    }
  };

  const handleRemoveLocation = (locationToRemove) => {
    setSelectedLocations(
      selectedLocations.filter((loc) => loc.label !== locationToRemove)
    );
    setFormData((prev) => ({
      ...prev,
      localities: prev.localities.filter((loc) => loc !== locationToRemove),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("budget.")) {
      const budgetField = name.split(".")[1];
      setFormData((prev) => ({
        ...prev,
        budget: {
          ...prev.budget,
          [budgetField]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      role: "guest",
      created: "",
      localities: [],
      budget: {
        min: "",
        max: "",
      },
      type: "new",
      property_type: "",
    });
    setSelectedLocations([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await privateReq.post("/add-enquiries-user", formData);
      if (response.data) {
        toast.success("Enquiry added successfully");
        resetForm();
        fetchEnquiries();
        setOpenModal(false);
      }
    } catch (err) {
      console.error("Error adding enquiry:", err);
      toast.error(err.response?.data?.message || "Failed to add enquiry");
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <>
      <ScrollToTop />
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <Box
        sx={{
          p: 4,
          maxWidth: "100%",
          mt: `${headerHeight}px`,
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)",
          minHeight: "100vh",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "16px",
            background: "white",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            mb: 4,
          }}
        >
          <Box
            sx={{
              p: 1,
              borderBottom: "1px solid #eee",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "3px 10px",
              }}
            >
              <PeopleIcon sx={{ fontSize: 16, color: "#1976d2", mr: 1 }} />
              <Typography variant="p" fontWeight="600" color="#2c3e50">
                Enquiries List
              </Typography>
            </Box>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenModal(true)}
              sx={{
                borderRadius: "12px",
                textTransform: "none",
                background: "linear-gradient(45deg, #1976d2 30%, #2196f3 90%)",
                boxShadow: "0 3px 15px rgba(33,150,243,0.3)",
                "&:hover": {
                  background:
                    "linear-gradient(45deg, #1565c0 30%, #1976d2 90%)",
                },
              }}
            >
              Add New Enquiry
            </Button>
          </Box>
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                      width: "140px",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Contact
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Localities
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Budget
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Property Type
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Created By
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Enquire Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enquiries.map((enquiry) => (
                  <TableRow
                    key={enquiry._id}
                    hover
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f8fafc",
                      },
                    }}
                  >
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography sx={{ fontWeight: 500 }}>
                        {enquiry.name}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography>{enquiry.phone}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.email}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 0.5,
                          flexWrap: "wrap",
                          maxWidth: "300px",
                        }}
                      >
                        {enquiry.localities?.map((locality, index) => (
                          <Chip
                            key={index}
                            label={locality}
                            size="small"
                            sx={{
                              backgroundColor: "#e3f2fd",
                              fontSize: "12px",
                            }}
                          />
                        ))}
                      </Box>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>
                          ₹{enquiry.budget?.min} - {enquiry.budget?.max}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Chip
                        label={enquiry.type}
                        size="small"
                        sx={{
                          backgroundColor:
                            enquiry.type === "new" ? "#e8f5e9" : "#fff3e0",
                          color: enquiry.type === "new" ? "#2e7d32" : "#ed6c02",
                          textTransform: "capitalize",
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry?.property_type}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        Gharwala (Admin)
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {formatDate(enquiry.created)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            resetForm();
          }}
          aria-labelledby="add-enquiry-modal"
          aria-describedby="modal-to-add-new-enquiry"
        >
          <Paper sx={modalStyle}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 0 }}>
              <PersonAddIcon sx={{ fontSize: 16, color: "#1976d2", mr: 1 }} />
              <Typography variant="p" fontWeight="600" color="#2c3e50">
                Add New Enquiry
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <FormControl
                component="fieldset"
                margin="normal"
                sx={{
                  width: "100%",
                  "& .MuiFormGroup-root": {
                    flexDirection: "row",
                    justifyContent: "space-around",
                    border: "1px solid #e0e0e0",
                    borderRadius: "12px",
                    padding: "10px",
                    backgroundColor: "#f8fafc",
                  },
                }}
              >
                <div
                  style={{ justifyContent: "space-between" }}
                  className="flex1"
                >
                  <div style={{ alignItems: "center" }} className="flex1">
                    Is this old Enquiry?
                    <Switch
                      name="type"
                      value={formData.type}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          type: e.target.checked ? "old" : "new",
                          created: "",
                        }))
                      }
                    />
                  </div>
                  {formData?.type === "old" && (
                    <div>
                      <input
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            created: e.target.value,
                          }))
                        }
                        type="date"
                        value={formData.created}
                      />
                    </div>
                  )}
                </div>
              </FormControl>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                margin="normal"
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                margin="normal"
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <FormControl fullWidth margin="normal" required>
                <select
                  value={formData.property_type}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      property_type: e.target.value,
                    }));
                  }}
                  style={{
                    width: "100%",
                    padding: "12px",
                    border: "1px solid #ccc",
                    borderRadius: "12px",
                    fontSize: "16px",
                  }}
                >
                  <option value="">Select Property Type</option>
                  {allPropertyType.map((role) => (
                    <option key={role} value={role}>
                      {role.charAt(0).toUpperCase() + role.slice(1)}
                    </option>
                  ))}
                </select>
              </FormControl>
              <Box sx={{ mt: 2, mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#2c3e50",
                    fontWeight: 500,
                    mb: 1,
                  }}
                >
                  Localities
                </Typography>
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  selectProps={{
                    value: null,
                    onChange: handleLocationSelect,
                    placeholder: "Search locations...",
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        borderRadius: "12px",
                        border: "1px solid #e0e0e0",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #1976d2",
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        borderRadius: "12px",
                        marginTop: "8px",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                        zIndex: 999,
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? "#f5f7fa" : "white",
                        color: "#2c3e50",
                        "&:hover": {
                          backgroundColor: "#f5f7fa",
                        },
                      }),
                    },
                  }}
                />
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 0 }}>
                  {selectedLocations.map((location, index) => (
                    <Chip
                      key={index}
                      label={location.label}
                      onDelete={() => handleRemoveLocation(location.label)}
                      sx={{
                        backgroundColor: "#e3f2fd",
                        "& .MuiChip-deleteIcon": {
                          color: "#1976d2",
                        },
                      }}
                    />
                  ))}
                </Box>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Min Budget"
                    name="budget.min"
                    type="number"
                    value={formData.budget.min}
                    onChange={handleInputChange}
                    margin="normal"
                    sx={{
                      padding: "0px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Max Budget"
                    name="budget.max"
                    type="number"
                    value={formData.budget.max}
                    onChange={handleInputChange}
                    margin="normal"
                    sx={{
                      padding: "0px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 1,
                  height: "48px",
                  borderRadius: "12px",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "600",
                  background:
                    "linear-gradient(45deg, #1976d2 30%, #2196f3 90%)",
                  boxShadow: "0 3px 15px rgba(33,150,243,0.3)",
                  "&:hover": {
                    background:
                      "linear-gradient(45deg, #1565c0 30%, #1976d2 90%)",
                  },
                }}
              >
                Add Enquiry
              </Button>
            </form>
          </Paper>
        </Modal>
      </Box>
    </>
  );
};

export default AdminAddUsers;
