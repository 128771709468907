import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import ButtonContained from "../../components/Buttons/ButtonContained";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import publicReq, { privateReq } from "../../config/axiosConfig";
import { FileX, Settings } from "lucide-react";
import { BellDot } from "lucide-react";
import { User } from "lucide-react";
import { Iterable } from "immutable";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Testimonials = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [tests, setTests] = useState();
  // const [addTest, setAddTest] = useState({
  //   name: '',
  //   title: '',
  //   description: '',
  // });

  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  const deleteTest = (id) => {
    privateReq
      .delete(`/delete-testimonial/${id}`)
      .then((res) => {
        console.log("deleted");
      })
      .catch((err) => {
        console.log(err);
      });

    fetchData();
  };

  const fetchData = () => {
    publicReq
      .get("/get-all-testimonials")
      .then((res) => {
        setTests(res.data.testimonials);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const onChangeAddTest = (e) => {
  //   const { name, value } = e.target;

  //   setAddTest((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const onChangeEditTest = () => {};
  // const deleteTest = () => {};

  return showPage ? (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        style={{
          marginTop: "100px",
          padding: "0 50px",
        }}
      >
        <div
          style={{
            fontSize: "30px",
            fontFamily: "Montserrat",
          }}
        >
          Testimonials
        </div>
        {/* <div>
          <div>
            <div style={{ marginLeft: '7px' }}>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  marginBottom: '10px',
                }}
              >
                <OutlinedFormInput
                  style={{
                    width: '300px',
                  }}
                  type='text'
                  name='name'
                  value={addTest.name}
                  onChange={onChangeAddTest}
                  placeholder='Name'
                />
                <OutlinedFormInput
                  style={{
                    width: '300px',
                  }}
                  type='text'
                  name='title'
                  value={addTest.title}
                  onChange={onChangeAddTest}
                  placeholder='Title'
                />
                <OutlinedFormInput
                  style={{ width: '300px' }}
                  type='text'
                  name='description'
                  Add
                  onChange={onChangeAddTest}
                  placeholder='Description'
                />
              </div>
            </div>

            <ButtonContained
              style={{
                width: '150px',
              }}
              onClick={onChangeAddTest}
            >
              Add
            </ButtonContained>
          </div>
        </div> */}
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid gray",
            }}
          >
            <div
              style={{
                fontSize: "30px",
                fontFamily: "Montserrat",
              }}
            >
              Service list:
            </div>

            {/* <div
              style={{
                display: "flex",
                gap: "20px",
                color: "#9a291b",
                alignItems: "center",
              }}
            >
              <div style={{ color: "#3498db", cursor: "pointer" }}>
                <Settings />
              </div>
              <div style={{ color: "#3498db", cursor: "pointer" }}>
                <BellDot />
              </div>
              <div style={{ color: "#3498db", cursor: "pointer" }}>
                <User />
              </div>
            </div> */}
          </div>

          {/* <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <input
                style={{ paddingBlock: "2px", paddingInline: "20px" }}
                placeholder="Search"
              />
              <div
                style={{
                  paddingBlock: "2px",
                  paddingInline: "20px",
                  border: "1px solid black",
                }}
              >
                Filter
              </div>
            </div>
          </div> */}

          <div
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "20px",
              maxWidth: "100%",
            }}
          >
            <div className="container text-center">
              <div className="row align-items-start">
                <div className="col" style={{ fontWeight: "bold" }}>
                  Name
                </div>
                <div className="col" style={{ fontWeight: "bold" }}>
                  Title
                </div>
                <div className="col" style={{ fontWeight: "bold" }}>
                  Description
                </div>
                {isMobile ? null : (
                  <div className="col" style={{ fontWeight: "bold" }}>
                    Actions
                  </div>
                )}
                {/* <div className="col" style={{ fontWeight: "bold" }}>Actions</div> */}
              </div>
            </div>
          </div>
          {tests?.map((t) => (
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                borderBottom: "1px solid gray",
                maxWidth: "100%",
              }}
            >
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <div className="container text-center">
                    <div className="row align-items-start">
                      <div
                        className="col"
                        style={isMobile ? {} : { marginLeft: "7vw" }}
                      >
                        {t.name}
                      </div>
                      <div className="col">{t.title}</div>
                      <div className="col">
                        {isMobile
                          ? t.description.slice(0, 20) + "..."
                          : t.description}
                      </div>
                      {/* <ButtonContained
                                  style={{
                                    width: '100px',
                                  }}
                                  onClick={() => fillAddService(service._id)}
                                >
                                  Edit
                                </ButtonContained> */}
                      <div className="col" style={{ width: "50vw" }}>
                        <ButtonContained
                          style={{
                            marginLeft: "5vw",
                            width: "100px",
                          }}
                          onClick={() => deleteTest(t._id)}
                        >
                          Delete
                        </ButtonContained>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default Testimonials;
