import { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
  addCartService,
  getMyCartService,
  removeFromCartService,
} from "../../config/furniture";
import ButtonContained from "../../components/Buttons/ButtonContained";
import { buyPhonePe } from "../../config/user";
import toast from "react-hot-toast";
import { FaTrash } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { privateReq } from "../../config/axiosConfig";
import { useSelector } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import SignUp from "../../components/Signup";
import ModalSignUp from "../../components/ModalSignup";
import Login from "../../components/Login";
import ModalLogin from "../../components/ModalLogin";
import FurnitureAddress from "./FurnitureAddress";
import publicReq from "../../config/axiosConfig";
import axios from "axios";

const Checkout = ({ user, setOpenPopUp }) => {
  const userData = useSelector((state) => state.auth);
  const [items, setItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [loader, setLoader] = useState(false);
  const [cost, setCost] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [usePoints, setUsePoints] = useState(false);
  const [updatedReferralPoints, setUpdatedReferralPoints] = useState(
    user?.referral_points
  );
  const [deliveryAddress, setDeliveryAddress] = useState({
    address: "",
    house_no: "",
    street: "",
    city: "",
    adhar: "",
    state: "",
    pinCode: "",
  });
  const [carryingCost, setCarryingCost] = useState([]);
  const [deliveryAddressModal, setDeliveryAddressModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [closeLoginModal, setCloseLoginModal] = useState(false);

  const [monthlyRent, setMonthlyRent] = useState(0);
  const [securityDeposit, setSecurityDeposit] = useState(0);
  const [acCount, setAcCount] = useState(0);

  useEffect(() => {
    checkCart();
  }, [userData?.user]);

  useEffect(() => {
    calcTotalAmount(items);
  }, [items]);

  useEffect(() => {
    if (items.length == 0) {
      setCost(0);
    }
    if (items.length > 0 && items.length <= 5) {
      setCost(carryingCost[`product${items.length}`]);
      //  console.log(carryingCost[`product${items.length}`]);
    } else if (items.length > 5) {
      setCost(350);
    }
  }, [items]);

  const checkCart = async () => {
    if (!userData?.user) {
      setItems(
        JSON.parse(localStorage.getItem("cartData"))
          ? JSON.parse(localStorage.getItem("cartData"))
          : []
      );
    } else {
      let cartItem = JSON.parse(localStorage.getItem("cartData"))
        ? JSON.parse(localStorage.getItem("cartData"))
        : [];

      let itemsToAdd = cartItem.map((item) => ({
        furnitureId: item.furniture._id,
        period: item.periodNeeded,
      }));

      console.log("itemsToAdd total ", itemsToAdd);
      if (itemsToAdd.length !== 0) {
        await addCartService(itemsToAdd);
      }
      getItems();
    }
  };

  const getItems = async () => {
    const { data } = await getMyCartService();
    setItems(data.bookings);
    localStorage.removeItem("cartData");
    getAcCount(data.bookings);
  };

  async function getAcCount(items) {
    let count = 0;
    items.forEach((it) => {
      if (it.furniture.categoryType === "AC") {
        count += 1;
      }
    });
    console.log("yyy", count);
    setAcCount(count);
  }

  const handleRemove = async (id, index) => {
    if (!userData?.user) {
      const cartData = JSON.parse(localStorage.getItem("cartData")) || [];
      cartData.splice(index, 1); // Remove the item at the specified index
      localStorage.setItem("cartData", JSON.stringify(cartData));
      setItems(cartData);
    } else {
      try {
        await axios.delete(
          `https://gharwala-5466fddd6458.herokuapp.com/api/furniture/cart/remove/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        getItems();
      } catch (error) {
        console.log(error.response ? error.response.data : error.message);
      }
    }
  };

  const calcTotalAmount = () => {
    const parseAndRound = (value) => parseFloat(parseFloat(value).toFixed(2));

    let newMonthlyRent = 0;

    items.forEach((it) => {
      if (it) {
        if (it.periodNeeded == 3) {
          newMonthlyRent += parseAndRound(
            it.furniture.threeMonthPayment.threeMonthPayment
          );
        } else if (it.periodNeeded == 6) {
          newMonthlyRent += parseAndRound(
            it.furniture.sixMonthPayment.sixMonthPayment
          );
        } else if (it.periodNeeded == 9) {
          newMonthlyRent += parseAndRound(
            it.furniture.nineMonthPayment.nineMonthPayment
          );
        } else if (it.periodNeeded == 12) {
          console.log(
            "monthly rent xxxx",
            it.furniture.twelveMonthPayment.twelveMonthPayment
          );
          newMonthlyRent += parseAndRound(
            it.furniture.twelveMonthPayment.twelveMonthPayment
          );
        }
      }
    });

    setMonthlyRent(newMonthlyRent);

    const newSecurityDeposit = items.reduce(
      (acc, it) => acc + parseAndRound(it.furniture.expectedSecurityDeposit),
      0
    );
    setSecurityDeposit(newSecurityDeposit);

    const newTotalAmount =
      items.reduce(
        (acc, it) => acc + parseAndRound(it.furniture.expectedSecurityDeposit),
        0
      ) + newMonthlyRent;

    setTotalAmount(newTotalAmount);
  };

  const getCost = () => {
    publicReq
      .get("/carryingCost")
      .then((res) => {
        console.log(res);
        const { __v, _id, ...rest } = res.data;
        setCarryingCost(rest);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCost();
  }, []);

  const updateReferral = () => {
    privateReq
      .post("/update-referal-points", {
        points: updatedReferralPoints,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    calcTotalAmount();
  }, [items]);

  function calculateAmount(usePoints, referralPoints) {
    return usePoints
      ? securityDeposit + monthlyRent * 0.18 + acCount * 1500 + monthlyRent <=
        user?.referral_points
        ? 0
        : (
            securityDeposit +
            monthlyRent * 0.18 +
            acCount * 1500 +
            monthlyRent -
            user?.referral_points +
            cost
          ).toFixed(2)
      : (
          securityDeposit +
          monthlyRent * 0.18 +
          acCount * 1500 +
          monthlyRent +
          cost
        ).toFixed(2);

    // if (usePoints) {
    // 	calculatedAmount =
    // 		totalAmount + totalAmount * 0.18 <= referralPoints
    // 			? 0
    // 			: totalAmount + totalAmount * 0.18 - referralPoints;
    // } else {
    // 	calculatedAmount = totalAmount + totalAmount * 0.18;
    // }
    // return calculatedAmount + cost;
  }

  const createSession = async () => {
    if (!userData?.user) {
      setCloseLoginModal(true);
      return;
    }
    updateReferral();
    try {
      setLoader(true);

      const res = await buyPhonePe({
        amount:
          calculateAmount(usePoints, user?.referral_points) === 0
            ? 1
            : calculateAmount(usePoints, user?.referral_points),
        MUID: "MUID" + Date.now(),
        furnitureId: items?.map((i) => {
          return {
            _id: i.furniture._id,
            period: i.periodNeeded,
          };
        }),
        transactionId: "T" + Date.now(),
        address: deliveryAddress,
      });

      if (res.data.status) {
        window.location.href = res.data.url;
        setLoader(false);
      }
    } catch (e) {
      console.log(e);
      toast.error("Sorry, something went wrong!");
      setLoader(false);
    }
  };

  return (
    <div>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        style={{
          width: "90%",
          margin: "100px auto 0 auto",
        }}
      >
        <div
          style={{
            fontFamily: "Montserrat",
            fontSize: "40px",
            color: "#9A291B",
          }}
        >
          Checkout
        </div>
        <div
          style={{
            display: !isMobile && "flex",
            alignItems: "start",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              flex: "1",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
              }}
            >
              Items
            </div>
            {items?.map((it, index) => (
              <div
                style={{
                  boxShadow: "0px 0px 10px 1px lightgray ",
                  padding: "20px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    display: !isMobile && "flex",
                    justifyContent: "start",
                    gap: "10px",
                  }}
                >
                  <img
                    style={{
                      width: isMobile ? "100%" : "300px",
                      borderRadius: "20px",
                      height: "200px",
                    }}
                    src={it.image}
                  />
                  <div
                    style={{
                      marginLeft: !isMobile && "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "28px",
                        fontWeight: "700",
                      }}
                    >
                      {it.furniture.furnitureName}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        display: !isMobile && "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        gap: "30px",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          flex: "1",
                          borderRadius: "20px",
                          marginBottom: "15px",
                          marginBottom: isMobile && "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          {" "}
                          Period:
                          {it.periodNeeded === 9
                            ? " Nine Month"
                            : it.periodNeeded === 6
                            ? " Six Month"
                            : it.periodNeeded === 3
                            ? " Three Month"
                            : " Twelve Month"}{" "}
                        </div>
                        <div>
                          Product Type:{" "}
                          {it.furniture.expectedSecurityDeposit
                            ? "Rent"
                            : "Buy"}
                        </div>
                        {/* {it.furniture.categoryType ==="AC" && (()=>setAcCount(acCount + 1))} */}
                        {it.furniture.categoryType === "AC" && (
                          <p
                            style={{
                              fontSize: "small",
                              color: "#9a291b",
                              marginTop: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            AC installation : ₹ 1,500
                          </p>
                        )}
                      </div>

                      {it.furniture.expectedSecurityDeposit && (
                        <div
                          style={{
                            padding: "10px",
                            flex: "1",
                            borderRadius: "20px",
                          }}
                        >
                          <div
                            style={{
                              marginBottom: "15px",
                            }}
                          >
                            Security Deposit: ₹
                            {it.furniture.expectedSecurityDeposit}
                          </div>
                          <div>
                            Monthly Rent: ₹
                            {it.periodNeeded === 9
                              ? it.furniture.nineMonthPayment.nineMonthPayment
                              : it.periodNeeded === 6
                              ? it.furniture.sixMonthPayment.sixMonthPayment
                              : it.periodNeeded === 3
                              ? it.furniture.threeMonthPayment.threeMonthPayment
                              : it.furniture.twelveMonthPayment
                                  .twelveMonthPayment}
                          </div>
                          <div
                            style={{
                              margin: "5px 0",
                              height: "1px",
                              width: "100%",
                              backgroundColor: "lightgray",
                            }}
                          />
                          <div>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "gray",
                                width: "13rem",
                              }}
                            >
                              Security Deposit is Refundable*
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginRight: "10px",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                >
                  <ButtonContained
                    sx={[
                      {
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: "1rem",
                      },
                    ]}
                    onClick={() => handleRemove(it.furniture._id, index)}
                  >
                    <FaTrash />
                    Remove
                  </ButtonContained>
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              marginTop: "45px",
              maxWidth: "500px",
              boxShadow: "0px 0px 10px 1px lightgray ",
              padding: "10px",
              borderRadius: "7px",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              padding: "20px",
            }}
          >
            <div
              style={{
                fontSize: "20px",
                marginBottom: "20px",
                fontWeight: "bold",
                color: "#9A291B",
              }}
            >
              Checkout Details
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div>Monthly Rent:</div>
              <div>₹ {monthlyRent}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div>Taxes: </div>
              <div>
                <div>SGST 9%</div>
                <div>CGST 9%</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div>Total Taxes: </div>
              <div>
                <div>₹ {(monthlyRent * 0.18).toFixed(2)}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div>Security Deposit:</div>
              <div>₹ {securityDeposit}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div>Carrying cost:</div>
              <div>₹ {cost}</div>
            </div>

            {acCount > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "16px",
                }}
              >
                <div>AC installation Charge:</div>
                <div>₹ {acCount * 1500}</div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",

                gap: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    color: "#9A291B",
                  }}
                >
                  Use referral points ?
                </label>
                <input
                  type="checkbox"
                  disabled={!user}
                  onChange={(e) => {
                    setUsePoints(e.target.checked);
                    setUpdatedReferralPoints(
                      user?.referral_points -
                        (totalAmount + totalAmount * 0.18) <
                        0
                        ? 0
                        : user?.referral_points -
                            (totalAmount + totalAmount * 0.18)
                    );

                    calculateAmount(usePoints, user?.referral_points);
                    // console.log("hi");
                  }}
                />
              </div>

              <p
                style={{
                  fontSize: "13px",
                }}
              >
                Your total referral points : {user?.referral_points}
              </p>
              {usePoints && (
                <p>remaining points will be {updatedReferralPoints}</p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                gap: "20px",
              }}
            >
              <div
                style={{
                  color: "##9A291B",
                }}
              >
                Payable Amount{" "}
              </div>
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#9A291B",
                    fontSize: "20px",
                  }}
                >
                  ₹
                  {usePoints
                    ? securityDeposit +
                        monthlyRent * 0.18 +
                        acCount * 1500 +
                        monthlyRent <=
                      user?.referral_points
                      ? 0
                      : (
                          securityDeposit +
                          monthlyRent * 0.18 +
                          acCount * 1500 +
                          monthlyRent -
                          user?.referral_points +
                          cost
                        ).toFixed(2)
                    : (
                        securityDeposit +
                        monthlyRent * 0.18 +
                        acCount * 1500 +
                        monthlyRent +
                        cost
                      ).toFixed(2)}
                </div>
                {/* <p style={{textAlign:'left'}}> ₹1 will be payable </p> */}
              </div>
            </div>
            <div
              class="text-wrap text-center"
              style={{
                color: "gray",
                fontSize: "small",
                maxWidth: "18rem",
                marginTop: "15px",
              }}
            >
              <p>
                Note : Security deposit or installation charge or carrying cost
                not taxable
              </p>
              {/* <p>Carrying cost is fixed at 350 for more than 5 items</p> */}
            </div>
            {items.length > 0 && (
              <button
                disabled={loader}
                onClick={() =>
                  user
                    ? setDeliveryAddressModal(true)
                    : setCloseLoginModal(true)
                }
                style={{
                  width: "100%",
                  marginTop: "20px",
                  padding: "10px 0",
                  borderRadius: "20px",
                  border: "none",
                  backgroundColor: "#9A291B",
                  color: "white",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                {loader ? "Please wait.." : "Place order"}
              </button>
            )}
          </div>
        </div>
      </div>
      {isMobile ? (
        <BottomSheet
          snapPoints={({ minHeight }) => minHeight}
          open={closeModal}
          onDismiss={() => setCloseModal(false)}
        >
          <SignUp setCloseModal={setCloseModal} />
        </BottomSheet>
      ) : (
        <ModalSignUp
          state={closeModal}
          stateFunc={setCloseModal}
          title="Please fill all the Details"
        >
          <SignUp setCloseModal={setCloseModal} />
        </ModalSignUp>
      )}
      {isMobile ? (
        <BottomSheet
          snapPoints={({ minHeight }) => minHeight}
          open={closeLoginModal}
          onDismiss={() => setCloseLoginModal(false)}
        >
          <Login
            setSignupModal={setCloseModal}
            setCloseModal={setCloseLoginModal}
          />
        </BottomSheet>
      ) : (
        <ModalLogin
          state={closeLoginModal}
          stateFunc={setCloseLoginModal}
          title="Login"
        >
          <Login setCloseModal={setCloseLoginModal} />
        </ModalLogin>
      )}
      <FurnitureAddress
        modalVisible={deliveryAddressModal}
        deliveryAddress={deliveryAddress}
        setDeliveryAddress={setDeliveryAddress}
        setModalVisible={setDeliveryAddressModal}
        onSubmit={() => createSession()}
      />
    </div>
  );
};

export default Checkout;
