import React from "react";
import AdminLoginComp from "../../components/AdminLogin";

const AdminLogin = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  return (
    <AdminLoginComp
      closeLoginModal={closeLoginModal}
      setCloseLoginModal={setCloseLoginModal}
      setOpenPopUp={setOpenPopUp}
    />
  );
};

export default AdminLogin;
