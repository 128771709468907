import React from "react";
import Header from "../../components/Header";
import "./index.css";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
import { useMediaQuery } from "react-responsive";

const FurnitureRenting = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        style={{
          marginBottom: "100px",
        }}
        className="box-text"
      >
        <h1>FURNITURE AND HOME APPLIANCES RENTING</h1>
        <div>
          <h2>
            Welcome to Gharwala's Furniture and Home Appliances Renting Service!
          </h2>
          <p>
            At Gharwala, we understand the importance of furnishing your home
            with quality furniture and essential appliances without breaking the
            bank. Our furniture and home appliances renting service offers you a
            convenient and cost-effective solution to furnish your living space
            according to your needs and preferences.
          </p>
        </div>
        <div>
          <h2>Wide Range of Furniture and Appliances:</h2>
          <p>
            Explore our extensive collection of furniture and home appliances,
            meticulously curated to cater to every taste and requirement. From
            cozy sofas and elegant dining sets to efficient refrigerators and
            stylish TVs, we have everything you need to transform your house
            into a home.
          </p>
        </div>
        <div>
          <h2>Flexible Rental Plans:</h2>
          <p>
            Choose from our flexible rental plans tailored to suit your budget
            and duration of use. Whether you need furniture and appliances for a
            short-term stay or a long-term arrangement, we have rental options
            that fit your lifestyle.
          </p>
        </div>
        <div>
          <h2>Convenient Delivery and Setup:</h2>
          <p>
            Enjoy hassle-free delivery and setup of your rented furniture and
            appliances right to your doorstep. Our team ensures prompt and
            professional installation, allowing you to start using your items
            immediately without any hassle.
          </p>
        </div>
        <div>
          <h2>Quality Assurance:</h2>
          <p>
            Rest assured, all our rental furniture and appliances undergo
            thorough inspection and maintenance to ensure optimal performance
            and cleanliness. We prioritize quality and reliability to provide
            you with a seamless renting experience.
          </p>
        </div>
        <div>
          <h2>Easy Returns and Exchanges:</h2>
          <p>
            Need to make changes to your rental agreement? No problem! We offer
            easy returns and exchanges, allowing you to swap out items or modify
            your rental plan as needed. Our customer service team is always
            available to assist you every step of the way.
          </p>
        </div>
        <div>
          <h2>Affordable Pricing:</h2>
          <p>
            Say goodbye to hefty upfront costs and long-term commitments. Our
            competitive pricing ensures that you get the best value for your
            money, allowing you to enjoy premium furniture and appliances at a
            fraction of the cost of purchasing them outright.
          </p>
        </div>
        <div>
          <h2>Customer Satisfaction Guaranteed:</h2>
          <p>
            Your satisfaction is our top priority. We strive to exceed your
            expectations with our top-notch service and quality products. Count
            on us to make your renting experience with Gharwala a memorable and
            enjoyable one.
          </p>
        </div>
        <div>
          <h2>Get Started Today:</h2>
          <p>
            Ready to furnish your home with ease? Explore our collection of
            furniture and home appliances and start renting with Gharwala today.
            Experience the convenience and affordability of renting with us and
            make your house feel like home in no time!
          </p>
        </div>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </div>
  );
};

export default FurnitureRenting;
