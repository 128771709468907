import React, { useRef, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import {
  Box,
  Checkbox,
  Typography,
  Slider,
  MenuItem,
  TextField,
} from "@mui/material";
import { SliderThumb } from "@mui/material/Slider";
import BedSvg from "../../assets/images/bed.svg";
import BathSvg from "../../assets/images/bed.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Header from "../../components/Header";
import classes from "../../styles/styles.js";
import ButtonContained from "../../components/Buttons/ButtonContained";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import publicReq, { API_KEY, assetUrl } from "../../config/axiosConfig";
import { getProperties } from "../../redux/action/property";
import ReactGoogleAutocomplete, {
  usePlacesWidget,
} from "react-google-autocomplete";
import { useMediaQuery } from "react-responsive";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import toast from "react-hot-toast";
import { privateReq } from "../../config/axiosConfig";
import ScrollToTop from "../../components/ScrollToTop.js";

function valuetext(value) {
  return `${value}°C`;
}

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "rgba(154, 41, 27, 1)",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    // border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));
function SliderThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
    </SliderThumb>
  );
}
export const getLocLatLng = (district) => {
  return geocodeByAddress(district?.label)
    .then((results) => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log("Successfully got latitude and longitude", { lat, lng });
      return { lat, lng };
    })
    .catch((err) => {
      console.log(err);
    });
};
const RecentProperties = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const [statusField, setStatusField] = useState("");
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [budget, setBudget] = useState([1, 1000000000]);
  const [usp, setUsp] = useState([]);
  const [wantTo, setWantTo] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [accessoriesFilter, setAccessoryFilter] = useState([]);
  const [properties, setProperties] = useState([]);
  const {
    accessories,
    user,
    location: userLocation,
  } = useSelector((state) => state.auth);
  const [sortby, setSortBy] = useState("created");
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showFilter, setShowFilter] = useState(false);
  const [go, setGo] = useState(0);
  const [showServices, setShowServices] = useState();

  function checkTabS2() {
    publicReq
      .post("/get-access-for-basic-features", { propertyName: "PROPERTY" })
      .then((result) => {
        setShowServices(result.data.message.enabled);
        console.log(result.data.message.enabled);
        if (result.data.message.enabled === false) {
          navigate("/");
        }
      })
      .catch((error) => {
        toast.error("something went wrong");
        console.log(error);
      });

    // const results = await Promise.all(promises);

    // setBroker(results[0].data.message.enabled);
    // setOwner(results[1].data.message.enabled);
    // setService(results[2].data.message.enabled);
    // setProperty(results[3].data.message.enabled);
  }

  useEffect(() => {
    checkTabS2();
  }, []);

  function deleteProperty(id) {
    privateReq
      .delete(`/delete-property/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Property deleted");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // getPropertiesInitial();
        getRecentProperties();
      });
  }

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerHeight]);

  async function setListingToinactive(card) {
    let status;
    if (card.active) {
      status = false;
    } else status = true;

    // console.log(id,'id for inactive')
    privateReq
      .put(`/properties/${card.id}/deactivate`, { status })
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          if (status) {
            toast.success("Activated listing");

            setStatusField("Deactivate this listing");
          } else toast.success("Deactivated listing");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    toast.success("Changed service to inactive");
  }

  const getPropertiesInitial = () => {
    // event.preventDefault();
    try {
      const data = {
        priceRange: budget,
        usp,
        propertyType,
        wantTo,
        accessories: accessoriesFilter,
      };

      let query = `?`;
      Object.keys(data).map((key, idx) => {
        if (Array.isArray(data[key])) {
          data[key].forEach((item, index) => {
            query += `${idx > 0 || index > 0 ? "&" : ""}${key}=${item}`;
          });
        } else {
          query += `${idx > 0 ? "&" : ""}${key}=${data[key]}`;
        }
      });
      if (sortby) {
        query += `&sortby=${sortby}`;
      }
      dispatch(getProperties(query));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getRecentProperties();
  }, [sortby]);

  const getRecentProperties = () => {
    privateReq
      .get(`/new-property-list`)
      .then((res) => {
        if (res.status === 200) {
          // console.log("res.data.data", res.data.data);
          setProperties(res.data.data.reverse());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <ScrollToTop />
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <div className="container-fluid px-0">
        <div className="properties-container bgF4F4F4">
          <div className="row mx-0 w-100">
            <div className="col-lg-9 col-md-9 col-xs-12">
              <div className="row properties-list mx-0">
                <div className="row properties-header mt-5 px-3">
                  <div className="col-lg-6 col-md-6 col-sm-12 heading-properties"></div>
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 properties-filter-select"
                    // style={{ marginRight: "1.25rem" }}
                  >
                    {/* <FormControl sx={{ display: 'flex' }}> */}

                    <div className="select-input">
                      <Typography
                        id="label"
                        sx={[classes.textSortBy, classes.fontFamilyDS]}
                      >
                        Sort by:
                      </Typography>
                      <Select
                        labelId="label"
                        id="select"
                        value={sortby}
                        onChange={(e) => setSortBy(e.target.value)}
                        IconComponent={KeyboardArrowDownIcon}
                        style={{ width: "300px" }}
                        sx={[
                          classes.fontFamilyDS,
                          classes.fbBold,
                          {
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          },
                        ]}
                      >
                        <MenuItem value="all">all</MenuItem>
                        <MenuItem value="24hours">24hours</MenuItem>
                        <MenuItem value="7days">7days</MenuItem>
                        <MenuItem value="1month">1month</MenuItem>
                      </Select>
                    </div>
                    {/* </FormControl> */}
                  </div>
                </div>
                <div className="d-flex flex-wrap">
                  {properties &&
                    properties?.map((card, index) => (
                      <div
                        className={`col-lg-4 col-md-4 col-sm-12 ${
                          isMobile && " w-100"
                        }`}
                      >
                        <div className="card-container">
                          <div key={index} className="card">
                            <div
                              onClick={() => navigate(`/properties/${card.id}`)}
                              className="card-image-properties"
                              style={{
                                backgroundImage: `url(${card?.propertyImages[0]})`,
                                backgroundSize: "100%",
                                backgroundRepeat: "no-repeat",
                                backgroundClip: "content-box",
                                backgroundPosition: "center",
                              }}
                            ></div>
                            <div className="text-info">
                              <div className="slider-card-header">
                                <h2 className="slider-title">{card.title}</h2>
                                <span className="slider-title price-text">
                                  ₹
                                  {Intl.NumberFormat("en-IN").format(
                                    card?.expectedMonthlyRent
                                  )}
                                  {card?.wantto !== "sell" && "/month"}
                                </span>
                              </div>
                              <p className="property-desc">
                                {card.description}
                              </p>
                              <span
                                style={{
                                  color: "gray",
                                  fontSize: "small",
                                }}
                              >
                                Listed on: {card.created.slice(0, 10)}
                              </span>
                              <div
                                style={{
                                  color: "#00B908",
                                  fontSize: "small",
                                  fontWeight: "bold",
                                }}
                              >
                                {card.createdBy !== "" && (
                                  <div>
                                    Post by:{" "}
                                    {card.createdBy || "Gharwala Exclusive"}
                                  </div>
                                )}
                              </div>
                              <div className="propety-icons">
                                <div className="property-icon">
                                  <img src={BedSvg} />
                                  <span className="text-color979797 property-icon-text">
                                    {card.bedrooms}
                                  </span>
                                </div>
                                {card.bathrooms && (
                                  <div className="property-icon">
                                    <img src={BathSvg} />
                                    <span className="property-icon-text text-color979797">
                                      {card.bathrooms}
                                    </span>
                                  </div>
                                )}
                                {user && user?.role !== "guest" && (
                                  <div className="property-icon">
                                    <span
                                      style={{
                                        color: card.status ? "green" : "red",
                                      }}
                                      className="property-icon-text text-color979797 px-2"
                                    >
                                      {card.status ? "Active" : "Deactivated"}
                                    </span>
                                  </div>
                                )}
                                {/* <div className="property-icon">
                                <img src={FloorSvg} />
                                <span className="text-color979797 property-icon-text">
                                  1
                                </span>
                              </div> */}
                                {/* <div className="property-icon">
                                <img src={AreaSvg} />
                                <span className="text-color979797 property-icon-text">
                                  2000 Ft
                                </span>
                              </div> */}
                              </div>
                            </div>
                            <div className="property-slider-buttons px-2">
                              {/* <button className="btn-connect">Connect </button> */}
                              {/* <Link to={`/property-detail/${card._id}`} style={{ textDecoration: "none", color: "#000" }}> */}
                              <ButtonContained
                                // onClick={()=>{
                                //   {`/property-detail/${card._id}`}
                                // }}
                                onClick={() =>
                                  navigate(`/properties/${card.id}`)
                                }
                                sx={[
                                  classes.borderRadius12,
                                  classes.fs12,
                                  classes.margin4,
                                  classes.p10,
                                  {
                                    [theme.breakpoints.between("xs", "md")]: {
                                      ...classes.fs9,
                                      ...classes.margin4,
                                      ...classes.borderRadius7,
                                      ...classes.p7,
                                    },
                                  },
                                ]}
                                className="m-0 mx-1 my-2"
                              >
                                Connect
                              </ButtonContained>
                              {/* </Link> */}
                              {/* <button className="btn-connect btn-details col-6">
                            View Detail{" "}
                          </button> */}
                              {/* <Link to= style={{ textDecoration: "none", color: "#000" }}> */}
                              <ButtonOutlined
                                sx={[
                                  classes.borderRadius12,
                                  classes.fs12,
                                  classes.margin4,
                                  classes.p10,
                                  {
                                    [theme.breakpoints.between("xs", "md")]: {
                                      ...classes.fs9,
                                      ...classes.margin4,
                                      ...classes.borderRadius7,
                                      ...classes.p6,
                                    },
                                  },
                                ]}
                                onClick={() =>
                                  navigate(`/properties/${card.id}`)
                                }
                              >
                                View Detail
                              </ButtonOutlined>
                              {user && user.role === "admin" && (
                                <ButtonOutlined
                                  sx={[
                                    classes.borderRadius12,
                                    classes.fs12,
                                    classes.margin4,
                                    classes.p10,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...classes.fs9,
                                        ...classes.margin4,
                                        ...classes.borderRadius7,
                                        ...classes.p6,
                                      },
                                    },
                                  ]}
                                  onClick={() => deleteProperty(card.id)}
                                >
                                  Delete
                                </ButtonOutlined>
                              )}
                              {/* </Link> */}
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                margin: "7px",
                                // backgroundColor: '#9A291B',
                                borderRadius: "8.25px",
                                // width: '50px',
                                height: "50px",
                                "&:hover": {
                                  backgroundColor: "rgba(154, 41, 27, 0.8)",
                                },
                              }}
                            >
                              {user &&
                                (user?.role === null ||
                                  user?.role !== "guest" ||
                                  user?.role !== "serviceman") && (
                                  <button
                                    onClick={() => setListingToinactive(card)}
                                  >
                                    {card?.active
                                      ? "  Inactive this Listing"
                                      : "Activate this listing"}
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <StickyFooter /> */}
    </>
  );
};

export default RecentProperties;
