import React from "react";
import Navbar from "../../components/Navbar";
import PropertySection from "../../components/PropertySection";
import ExplorePropertyType from "../../components/PropertyTypes";

import HowItWorks from "../../components/HowItWorks";
import FeaturedProperty from "../../components/FeaturedProperty";
import Cities from "../../components/Cities";

import Testimonials from "../../components/testimonials";
import FeaturedRent from "../../components/FeaturedRent";
import TopBanner from "../../components/TopBanner";
import FAQSection from "../../components/FAQ-new";
import Footer from "../../components/Footer.js";
import Header from "../../components/Header";

const HomeNew = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  return (
    <div>
      <div>
        <Header
          closeLoginModal={closeLoginModal}
          setCloseLoginModal={setCloseLoginModal}
          setOpenPopUp={setOpenPopUp}
        />
      </div>

      <div>
        <PropertySection />
      </div>

      {/* Explore Property Section (with margin to separate from PropertySection) */}
      <div>
        <ExplorePropertyType />
      </div>
      <div>
        <FeaturedRent />
      </div>
      <div style={{ marginTop: "20px" }}>
        <HowItWorks />
      </div>

      <div>
        <Cities />
      </div>
      <div>{/* <FeaturedRent /> */}</div>
      <div>
        <Testimonials />
      </div>
      <div>
        <FAQSection />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default HomeNew;
