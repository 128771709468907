import React, { useState } from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardMedia,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FAQImage from "../../assets/images/faq.png";

const FAQSection = () => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid
      container
      spacing={4}
      sx={{ padding: "40px 20px", backgroundColor: "#23AEFF12" }}
    >
      {/* Image Section */}
      <Grid item xs={12} md={5}>
        <CardMedia
          component="img"
          src={FAQImage}
          alt="Property Image"
          sx={{
            borderRadius: "8px",
            width: "100%",
            maxWidth: "450px",
            height: "400px",
          }}
        />
      </Grid>

      {/* FAQ Section */}
      <Grid item xs={12} md={7}>
        <Typography variant="h4" sx={{ color: "#1e2a78", fontWeight: "bold" }}>
          Frequently Asked Questions
        </Typography>
        <Typography
          sx={{ color: "#7d8597", marginTop: "10px", marginBottom: "20px" }}
        >
         Your Questions, Answered: All You Need to Know About Property Sales and Rentals
        </Typography>
        <div
          style={{
            borderBottom: "2px solid #B90000",
            width: "30px",
            marginBottom: "16px",
            borderRadius: "30px",
          }}
        />

        {/* Accordion List */}
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleAccordionChange("panel1")}
          sx={{ backgroundColor: "#ffffff" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#ffffff", fontWeight: "bold" }}
          >
            <Typography>
              1.What are the key factors to consider when investing in Kolkata
              real estate?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: "#ffffff", padding: "16px" }}
          >
            <Typography>
              Some key factors to consider when investing in Kolkata real estate
              include location, infrastructure development, rental potential,
              resale value, developer reputation, legal compliance, and future
              growth prospects of the area.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleAccordionChange("panel2")}
          sx={{ backgroundColor: "#ffffff" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#ffffff", fontWeight: "bold" }}
          >
            <Typography>
              2.What are the popular residential areas in Kolkata for real
              estate investment?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: "#ffffff", padding: "16px" }}
          >
            <Typography>
              Some popular residential areas in Kolkata for real estate
              investment are Salt Lake City, Rajarhat, New Town, EM Bypass,
              Tollygunge and Behala.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleAccordionChange("panel3")}
          sx={{ backgroundColor: "#ffffff" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#ffffff", fontWeight: "bold" }}
          >
            <Typography>
              3.What are the amenities and facilities that buyers can expect in
              modern real estate developments in Kolkata?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: "#ffffff", padding: "16px" }}
          >
            <Typography>
              Modern real estate developments in Kolkata often offer a range of
              amenities and facilities such as landscaped gardens, swimming
              pools, fitness centers, children's play areas, multipurpose halls,
              security systems, and parking spaces. Some premium projects may
              also include smart home features and eco-friendly initiatives.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleAccordionChange("panel4")}
          sx={{ backgroundColor: "#ffffff" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#ffffff", fontWeight: "bold" }}
          >
            <Typography>
              4.How is the rental market in Kolkata for residential properties?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: "#ffffff", padding: "16px" }}
          >
            <Typography>
              The rental market in Kolkata for residential properties is quite
              active, especially in popular areas like Tollygunge, Salt Lake,
              Rajarhat, and EM Bypass. Rental rates may vary depending on
              factors such as location, property size, amenities, and demand in
              the specific neighborhood. Some landlords may also offer furnished
              or semi-furnished rental options to cater to different tenant
              preferences.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleAccordionChange("panel5")}
          sx={{ backgroundColor: "#ffffff" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#ffffff", fontWeight: "bold" }}
          >
            <Typography>
              How can one finance a real estate purchase in Kolkata?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: "#ffffff", padding: "16px" }}
          >
            <Typography>
              Financing a real estate purchase in Kolkata can be done through
              various avenues such as home loans offered by banks and financial
              institutions. Potential buyers can explore different loan options,
              compare interest rates, evaluate eligibility criteria, and assess
              repayment terms to choose a suitable financing option that aligns
              with their financial capabilities and requirements.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default FAQSection;
