import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./SingleBlog.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Header from "../Header";
import { useMediaQuery } from "react-responsive";
import { Height } from "@mui/icons-material";
const SingleBlogPage = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { blogId } = useParams();

  const fetchBlog = async () => {
    try {
      const response = await axios.get(
        // `http://localhost:5000/api/blogs/${blogId}`
        `https://gharwala-5466fddd6458.herokuapp.com/api/blogs/${blogId}`
      );
      console.log(response.data);
      setBlog(response.data);
    } catch (error) {
      console.error("Error fetching blog:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlog();
  }, [blogId]);

  useEffect(() => {
    if (blog && blog.blogContent) {
      try {
        const contentState = convertFromRaw(JSON.parse(blog.blogContent));
        setEditorState(EditorState.createWithContent(contentState));
      } catch (error) {
        console.error("Error parsing blog content:", error);
      }
    }
  }, [blog]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="single-blog-container">
        <div key={blog.id} className="blog-item">
          <div className="header-container">
            <div className="title-blog">
              <h1 className="title-h1">{blog.title}</h1>
            </div>
            <div className="short-desc">{blog.shortDescription}</div>
          </div>
          <div className="blog-image">
            <img
              src={blog.imageUrl}
              alt="blogimg"
              style={
                isMobile
                  ? { objectFit: "Fill", height: "100%" }
                  : { objectFit: "Fill", width: "100%" }
              }
            />
            <div className="author-info">
              <p>{blog.author}</p>
            </div>
          </div>
          <div className="content-container">
            <div style={isMobile ? { width: "90%", marginLeft: "7%" } : {}}>
              <Editor editorState={editorState} readOnly={true} toolbarHidden />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleBlogPage;
