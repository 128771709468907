import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProperties } from "../../redux/action/property";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import "./index.css";

const FeaturedRent = () => {
  const { properties } = useSelector((state) => state.property);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch properties
  useEffect(() => {
    dispatch(getProperties(""));
  }, []);

  const handleContact = (id) => {
    window.open(`/properties/${id}`, "_blank");

    // navigate(`/properties/${id}`);
  };

  // Filter properties for rent
  const propertiesForRent = properties.filter(
    (property) => property.wantto === "rent"
  );

  function removeLocations(inputString) {
    if (inputString) {
      // Remove "West Bengal", "India", and any postal code at the end of the string
      let modifiedString = inputString
        .replace(/West Bengal,?\s?|India,?\s?|\d{6}/g, "")
        .trim();

      // Remove any extra commas and spaces that might result from the replacements
      modifiedString = modifiedString
        .replace(/,\s*,/g, ", ")
        .replace(/,\s*$/, "")
        .trim();

      return modifiedString;
    }
    return inputString;
  }

  const getPropertiesInitial = () => {
    try {
      const data = {
        priceRange: [1, 10000000],
      };

      let query = `?`;
      Object.keys(data).forEach((key, idx) => {
        if (Array.isArray(data[key])) {
          data[key].forEach((item, index) => {
            query += `${idx > 0 || index > 0 ? "&" : ""}${key}=${item}`;
          });
        } else {
          query += `${idx > 0 ? "&" : ""}${key}=${data[key]}`;
        }
      });
      if (query !== undefined) {
        dispatch(getProperties(query));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPropertiesInitial();
  }, []);

  // Fallback if no properties are available
  if (propertiesForRent.length === 0) {
    return <p>No properties available for rent at this time.</p>;
  }

  return (
    <section className="responsive-padding">
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Typography
          variant="h4"
          style={{ color: "#1e2a78", fontWeight: "bold" }}
        >
          Featured Properties for Rent
        </Typography>
        <Typography style={{ color: "#7d8597", fontSize: "16px" }}>
          Hand-Picked selection of quality places
        </Typography>
        <hr
          style={{
            borderBottom: "2px solid #B90000",
            width: "16px",
            margin: "0 auto",
            marginTop: "8px",
            borderRadius: "30px",
          }}
        />
      </div>

      <Grid container spacing={4}>
        {propertiesForRent.slice(0, 6).map((property) => (
          <Grid item key={property.id} xs={12} md={4} lg={4}>
            {" "}
            {/* Set consistent width */}
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%", // Ensure card takes full height
                borderRadius: "10px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
              }}
              onClick={() =>
                handleContact(property?.route_title || property._id)
              }
            >
              <div style={{ position: "relative" }}>
                <CardMedia
                  component="img"
                  image={property?.image}
                  alt={
                    property?.title +
                    " " +
                    property?.propertyType +
                    " for " +
                    property?.wantto +
                    " " +
                    property?.locality?.coordinates[2]
                  }
                  style={{
                    height: "200px",
                    objectFit: "cover",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                />
                {property.featured && (
                  <span
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                      backgroundColor: "#B90000",
                      color: "#fff",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontSize: "12px",
                    }}
                  >
                    Featured
                  </span>
                )}
              </div>
              <CardContent style={{ flexGrow: 1 }}>
                {" "}
                {/* Make CardContent grow to fill space */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "#1e2a78", fontWeight: "bold" }}
                  >
                    {property.title}
                  </Typography>
                  <Typography
                    style={{
                      color: "#b90000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    ₹ {property.expectedMonthlyRent}
                  </Typography>
                </div>
                <Typography
                  style={{
                    color: "#7d8597",
                    fontSize: "14px",
                    marginBottom: "15px",
                  }}
                >
                  {property.location}
                </Typography>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "4px",
                    display: "flex",
                    justifyContent: "start",
                    color: "#7d8597",
                    fontSize: "12px",
                    gap: "20%",
                    marginBottom: "15px",
                    height: "30px",
                  }}
                >
                  {property.bedrooms && <span>🛏 {property.bedrooms} Beds</span>}
                  {property.bathrooms && (
                    <span>🛁 {property.bathrooms} Baths</span>
                  )}
                  {property.buildupArea && (
                    <span>📏 {property.buildupArea} Sqft</span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <img
                      src={Avatar}
                      alt="Agent"
                      style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                    /> */}
                    <div>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", color: "#1e2a78" }}
                      >
                        Property Listed By
                      </Typography>
                      <Typography variant="body2" style={{ color: "#7d8597" }}>
                        {property.createdBy}
                      </Typography>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#B90000", color: "#fff" }}
                    onClick={() =>
                      handleContact(property?.route_title || property._id)
                    }
                  >
                    Book Now
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

// Internal CSS styles object
const styles = {
  container: {
    padding: "100px",
    backgroundColor: "#23AEFF12",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    color: "#1e2a78",
    fontWeight: "bold",
  },
  subtitle: {
    color: "#7d8597",
    fontSize: "16px",
  },
  separator: {
    borderBottom: "2px solid #B90000",
    width: "16px",
    margin: "0 auto",
    marginTop: "8px",
    borderRadius: "30px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "20px",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    transition: "transform 0.3s ease",
    cursor: "pointer",
    width: "350px",
    height: "auto",
  },
  imageContainer: {
    position: "relative",
  },
  image: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  featuredBadge: {
    position: "absolute",
    top: "10px",
    left: "10px",
    backgroundColor: "#B90000",
    color: "#fff",
    padding: "5px 10px",
    borderRadius: "5px",
    fontSize: "12px",
  },
  cardContent: {
    padding: "15px",
  },
  price: {
    color: "#1e2a78",
    fontSize: "18px",
    fontWeight: "bold",
  },
  rating: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  stars: {
    color: "#FFD700",
    marginRight: "5px",
  },
  excellent: {
    fontSize: "12px",
    color: "#7d8597",
  },
  propertyTitle: {
    color: "#1e2a78",
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  location: {
    color: "#7d8597",
    fontSize: "14px",
    marginBottom: "15px",
  },
  propertyDetails: {
    backgroundColor: "#F6F4FF",
    padding: "4px",
    display: "flex",
    justifyContent: "space-between",
    color: "#7d8597",
    fontSize: "12px",
    marginBottom: "15px",
  },
  agentSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  agentInfo: {
    display: "flex",
    alignItems: "center",
  },
  agentImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  agentName: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1e2a78",
    marginTop: "10px",
  },
  agentLocation: {
    fontSize: "12px",
    color: "#7d8597",
  },
  bookNowButton: {
    backgroundColor: "#B90000",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
};

export default FeaturedRent;
