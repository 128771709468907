import React from "react";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
const Benefits = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="container" style={{ paddingTop: "125px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Benefits of Renting Furniture and Home Appliances Over Buying:
        </h2>{" "}
        <br />
        <p className="para-about-terms">
          {" "}
          Are you tired of the hassle and high costs of buying furniture and
          home appliances? At Gharwalah.com, we offer you a smarter and more
          cost- effective solution: renting. Here are some compelling benefits
          of choosing our furniture and home appliances rental service:
          Cost-Efficiency: Renting is significantly cheaper than buying. You can
          enjoy top-quality furniture and appliances without a hefty upfront
          investment. Save your hard-earned money for other important things.
          Flexibility: Your needs may change over time. Renting allows you to
          easily switch to different products or upgrade as your requirements
          evolve. No need to be stuck with the same old items. Maintenance and
          Cleanliness of Products At Gharwalah.com, we understand the importance
          of maintaining the products you rent in top-notch condition. We offer
          comprehensive maintenance services designed for your convenience, and
          here's how we handle it:
          <br /> - Cleaning and Maintenance: Our team ensures that all rented
          furniture and home appliances are meticulously cleaned and sanitized
          before delivery, ensuring they are in pristine condition when they
          reach your home. <br />- Scheduled Maintenance: Regular maintenance is
          a vital aspect of keeping your rented items in optimal shape. We
          schedule periodic maintenance checks to proactively address any
          potential issues, ensuring your products remain in excellent working
          order. <br />- User Convenience: We value your time, which is why our
          maintenance services can be scheduled at your convenience. Let us know
          when it suits you, and our technicians will be there to perform the
          necessary maintenance tasks.
          <br />
          -Cost Coverage: In the rare event of minor damage to a rented product
          where the fixing cost is up to 300 INR, Gharwalah.com will bear the
          cost of repairs. This provides peace of mind, knowing that small
          issues won't incur additional expenses for you. -Qualified
          Technicians: All maintenance and repair work is conducted by skilled
          technicians from Gharwalah.com. Rest assured that your items are in
          capable hands. This means that you can enjoy the benefits of
          top-quality furniture and appliances without the worry of maintenance
          costs, as long as the repair costs remain within the specified limit
          of 300 INR. Easy Relocation: We offer free relocation services within
          a 3-kilometer radius, making moving hassle-free. Say goodbye to the
          stress of moving your furniture and appliances. No Depreciation
          Worries: When you buy, the value of your assets depreciates over time.
          Renting eliminates this concern. You get to enjoy your furniture and
          appliances without worrying about their decreasing value.
        </p>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </div>
  );
};

export default Benefits;
