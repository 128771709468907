import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import GHModal from "./Modal";
import SignUp from "./Signup";
import { FaBackspace, FaBackward, FaClipboardList } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaCreditCard } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaKey } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaTag } from "react-icons/fa";
import { FaCartArrowDown } from "react-icons/fa";
import { FaCartPlus } from "react-icons/fa";

import Login from "./Login";
import ModalCredit from "../components/ModalCredits/ModalCredit";
import {
  OutlinedInput,
  Avatar,
  Rating,
  Box,
  Typography,
  Divider,
  Badge,
} from "@mui/material";
import Logo from "../assets/images/logo.jpg";
import { Button, InputAdornment, Menu, MenuItem } from "@mui/material";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { AddOutlined, Money, AddLocation } from "@mui/icons-material";
import ButtonContained from "./Buttons/ButtonContained";
import { useSelector } from "react-redux";
import { assetUrl } from "../config/axiosConfig";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Profile from "./Profile";
import Ratings from "./Ratings/index";
import ModalSignUp from "./ModalSignup";
import RatingModal from "./ModalRating";
import PropertyContactViewed from "./PropertiesContactView";
import styles from "../styles/styles";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import WeekendIcon from "@mui/icons-material/Weekend";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import { useMediaQuery } from "react-responsive";
import CartModal from "./Cart";
import toast from "react-hot-toast";
import { createSessionService } from "../config/user";
import ModalLogin from "./ModalLogin";
import { getMyCartService } from "../config/furniture";
import HeaderMobile from "./NewHeaderMobile";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AcMechanic from "../assets/images/Air_Conditioner_Mechanic.png";
import Plumber from "../assets/images/plumber.png";
import Electrian from "../assets/images/Electrian.png";
import MakeupArtist from "../assets/images/makeup_artist.png";
import Saloon from "../assets/images/saloon.png";
import Maid from "../assets/images/maid.png";
import Cleaning from "../assets/images/cleaning.png";
import Beautician from "../assets/images/beautician.png";
import publicReq from "../config/axiosConfig";
import Profession from "./Profession/Profession";
import axios from "axios";
import TopBanner from "./TopBanner";

const Header = ({
  closeLoginModal,
  setCloseLoginModal,
  innerRef,
  setOpenPopUp = () => {},
}) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const [closeModal, setCloseModal] = useState(false);
  // const [closeLoginModal, setCloseLoginModal] = useState(false);
  const [closeProfileModal, setCloseProfileModal] = useState(false);
  const [isPropertyFree, setIsPropertyFree] = useState(false);
  const [firstPropty, setFirstPropty] = useState(false);

  const [closeRatingsModal, setCloseRatingsModal] = useState(false);
  const [closeCartsModal, setCloseCartsModal] = useState(false);
  const [totalItemsInCart, setTotalItemsInCart] = useState(0);
  const [closePropertiesContactViewModal, setClosePropertiesContactViewModal] =
    useState(false);
  const { pathname } = useLocation();

  // console.log(pathname.includes("furniture"));

  const [showProperty, setShowProperty] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [loadingProperty, setLoadingProperty] = useState(true);
  const [loadingServices, setLoadingServices] = useState(true);

  async function checkTabS() {
    try {
      const result = await publicReq.post("/get-access-for-basic-features", {
        propertyName: "PROPERTY",
      });

      console.log("tab", result);
      setShowProperty(result.data.message.enabled);
    } catch (error) {
      toast.error("server overloaded");
    } finally {
      setLoadingProperty(false);
    }
  }
  async function checkTabS2() {
    try {
      const result = await publicReq.post("/get-access-for-basic-features", {
        propertyName: "SERVICES",
      });
      setShowServices(result.data.message.enabled);
    } catch (error) {
      toast.error("server overloaded");
      console.log(error);
    } finally {
      setLoadingServices(false);
    }
  }

  useEffect(() => {
    checkTabS();
    checkTabS2();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);

  const open = Boolean(anchorEl);
  const openProfile = Boolean(anchorElProfile);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const handleClick = (event) => {
    if (
      (user?.role === "broker" || user?.role === "developer") &&
      user?.credits === 0
    ) {
      toast.error("Please buy credits to add more listing");
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const createSession = async (e) => {
    e.preventDefault();
    try {
      const res = await createSessionService();
      if (res.data.status) {
        window.location.href = res.data.url;
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    onGetAllRatings();
  }, []);
  const onGetAllRatings = async () => {
    if (token) {
      try {
        const res = await getMyCartService();
        if (res.data.status) {
          setTotalItemsInCart(res.data.bookings.length);
        }
      } catch (error) {
        console.error("Error fetching cart:", error);
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized: Check your authentication token");
        }
      }
    }
  };

  const handleBuyCredits = () => {
    navigate("/buy-credits");
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <header ref={innerRef}>
          <HeaderMobile />
        </header>
      ) : (
        <header ref={innerRef} className="header_wrapper">
          <TopBanner />
          <div className="container-fluid px-4">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link className="navbar-brand" to="/">
                <div
                  className="brand-logo"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Logo}
                    style={{
                      height: "50px",
                      width: "50px",
                      marginLeft: "15px",
                    }}
                  />

                  <h3
                    className="ms-2"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1px",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ marginBottom: "1px" }}>
                      Ghar
                      <span style={{ color: "#9A291B" }}>Wala</span>
                      <sup style={{ fontSize: "15px" }}>TM</sup>
                    </p>

                    <span
                      style={{
                        fontSize: "8.5px",
                        marginLeft: "2px",
                      }}
                    >
                      (Gharwala Home Solutions LLP)
                    </span>
                  </h3>
                </div>
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded={!isNavCollapsed ? true : false}
                onClick={handleNavCollapse}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`${
                  isNavCollapsed ? "collapse" : ""
                } navbar-collapse ${"justify-content-between"}`}
                id="navbarNav"
              >
                <div></div>

                {/* {
                  user?.role ==='admin' &&
                  <div className="navlinks-header-auth-icons dropdown">
                    <NavLink style={{color: "#9a291b", textDecoration: "none"}} to={'/viewed-properties-all'}>
                      <div style={{fontWeight: "bolder"}} className="header-special-menu">
                          Property Views
                      </div>
                    </NavLink>
                  </div>
                } */}

                <div className="navlinks-header-auth ">
                  {/* {(user == null || user?.role !== "serviceman") && ( */}
                  {loadingProperty ? (
                    <div className="placeholder-glow">
                      <div
                        className="placeholder col-12 header-special-menu"
                        style={{
                          color: "#7B8788",
                          borderRadius: "10px",
                          height: "36px",
                        }}
                      >
                        Properties
                      </div>
                    </div>
                  ) : (
                    showProperty && (
                      <div className="navlinks-header-auth-icons dropdown">
                        <NavLink
                          // to="/properties"
                          style={{
                            color: "#9a291b",
                          }}
                        >
                          <div className="header-special-menu">Properties</div>

                          <div className="dropdown-content">
                            <div className="rent-services">
                              <p className="prop-services-head">
                                Rent Services
                              </p>

                              <Link
                                // style={{
                                //   color: "#9a291b",
                                // }}
                                // reloadDocument

                                to={"/properties?wantto=rent"}
                              >
                                Rent a property
                              </Link>
                              <Link
                                // style={{
                                //   color: "#9a291b",
                                // }}
                                // reloadDocument

                                to={"/properties?wantto=pg"}
                              >
                                PG
                              </Link>

                              {/* <Link
                                style={{
                                  color: "#9a291b",
                                }}
                                
                                to={"/agreement"}
                              >
                                Rent Agreement
                              </Link> */}
                            </div>

                            <div className="home-services">
                              <p className="prop-services-head">
                                Home Services
                              </p>
                              <Link
                                // style={{
                                //   color: "#9a291b",
                                // }}
                                // reloadDocument

                                to={"/properties?wantto=sell"}
                              >
                                Buy a property
                              </Link>
                              <Link
                                style={{
                                  color: "#9a291b",
                                }}
                                // reloadDocument

                                to={"/properties"}
                              >
                                <p>
                                  View All Services
                                  <ArrowForwardIcon />
                                </p>
                              </Link>
                            </div>
                          </div>
                        </NavLink>
                        {!isMobile && (
                          <FiberManualRecordIcon
                            sx={{
                              height: "10px",
                              width: "10px",
                            }}
                          />
                        )}
                      </div>
                    )
                  )}
                  {/* )} */}

                  {/* {(user == null ||
                    user?.role === "guest" ||
                    user?.role === "admin") && ( */}
                  {/* <div className="navlinks-header-auth-icons dropdown">
										<NavLink
											style={{
												color: "#9a291b",
											}}
											to={"/furniture"}
										>
											<div className="header-special-menu">
												Furniture & Home Appliances
											</div>
										</NavLink>
										{!isMobile && (
											<FiberManualRecordIcon
												sx={{
													height: "10px",
													width: "10px",
												}}
											/>
										)}
										<div className="dropdown-content">
											<div className="dropdown-content-subsection">
												<div className="dropdown-content-desc">
													<div>
														<p className="dropdown-content-head">
															Rent Furniture
														</p>
														<p className="dropdown-content-first-p">
															Rent Furniture, AC &
															Other Home
															Appliances{" "}
														</p>
														<p className="dropdown-content-sec-p">
															Choose from over
															2000 products
														</p>
													</div>
												</div>

												<div className="dropdown-content-images">
													<div className="dropdown-content-images-sec-row">
														<p className="rent">
															Rent
														</p>

														<div className="furniture-and-home-second-row-images">
															<a href="/furniture?category=Bed">
																<div className="furniture-sec-row-image">
																	<img
																		className="furniture-sec-row"
																		src={
																			MegaMenuBed
																		}
																		alt="Bed"
																	/>
																</div>
																<p className="chair-text">
																	Bed
																</p>
															</a>

															<a href="/furniture?category=Fridge">
																<div className="furniture-first-row-image">
																	<img
																		className="furniture-first-row"
																		src={
																			MegaMenuFridge
																		}
																		alt="Fridge"
																	/>
																</div>
																<p className="sofa-text">
																	Fridge
																</p>
															</a>

															<a href="/furniture?category=AC">
																<div className="furniture-sec-row-image">
																	<img
																		className="furniture-sec-row"
																		src={
																			MegaMenuAc
																		}
																		alt="AC"
																	/>
																</div>
																<p className="living-room-text">
																	AC
																</p>
															</a>

															<a href="/furniture?category=Sofa">
																<div className="furniture-first-row-image">
																	<img
																		className="furniture-first-row"
																		src={
																			MegaMenuSofa
																		}
																		alt="Sofa"
																	/>
																</div>
																<p className="suitcase-text">
																	Sofa
																</p>
															</a>
														</div>

											
													</div>

													<div className="dropdown-content-images-first-row">
														
														<div className="furniture-and-home-first-row-images">
															<a href="/furniture?category=TV">
																<div className="furniture-first-row-image">
																	<img
																		className="furniture-first-row"
																		src={
																			MegaMenuTv
																		}
																		alt="TV"
																	/>
																</div>
																<p className="sofa-text">
																	TV
																</p>
															</a>

															<a href="/furniture?category=Table">
																<div className="furniture-first-row-image">
																	<img
																		className="furniture-first-row"
																		src={
																			MegaMenuTable
																		}
																		alt="Table"
																	/>
																</div>
																<p className="sofa-text">
																	Table
																</p>
															</a>

															<a href="/furniture?category=Matress">
																<div className="furniture-first-row-image">
																	<img
																		className="furniture-first-row"
																		src={
																			MegaMenuMatress
																		}
																		alt="Mattress"
																	/>
																</div>
																<p className="sofa-text">
																	Mattress
																</p>
															</a>

															<a href="/furniture?category=Washing Machine">
																<div className="furniture-first-row-image">
																	<img
																		className="furniture-first-row"
																		src={
																			MegaMenuWashingMachine
																		}
																		alt="Washing Machine"
																	/>
																</div>
																<p className="suitcase-text">
																	Washing
																	Machine{" "}
																</p>
															</a>

															
															
														</div>
													</div>
												</div>
											</div>

											
										</div>
									</div> */}

                  {(user?.role === null || user?.role === "admin") && (
                    <div className="navlinks-header-auth-icons dropdown">
                      <NavLink
                        to="/admin"
                        style={{
                          color: "#9a291b",
                        }}
                      >
                        <div className="header-special-menu">Admin Access</div>
                      </NavLink>
                      {!isMobile && (
                        <FiberManualRecordIcon
                          sx={{
                            height: "10px",
                            width: "10px",
                          }}
                        />
                      )}
                    </div>
                  )}

                  {loadingServices ? (
                    <div className="placeholder-glow">
                      <div
                        className="placeholder col-12 header-special-menu"
                        style={{
                          color: "#7B8788",
                          borderRadius: "10px",
                          height: "35px",
                        }}
                      >
                        {" "}
                        Services
                      </div>
                    </div>
                  ) : (
                    showServices && (
                      <div className="navlinks-header-auth-icons dropdown">
                        <NavLink
                          style={{
                            color: "#9a291b",
                          }}
                          to="/other-services"
                        >
                          <div className="header-special-menu">Services</div>
                        </NavLink>

                        {!isMobile && (
                          <FiberManualRecordIcon
                            sx={{
                              height: "10px",
                              width: "10px",
                            }}
                          />
                        )}

                        <div className="dropdown-content">
                          <div className="dropdown-content-subsection">
                            {/* <div className="dropdown-content-desc">
                            <div>
                              <p className="dropdown-content-head">
                                Buy Furniture
                              </p>
                              <p className="dropdown-content-first-p">
                                Own Brand New or Refurbished furniture for your
                                homes
                              </p>
                              <p className="dropdown-content-sec-p">
                                Choose from over 2000 products
                              </p>
                            </div>
                          </div> */}

                            <div className="dropdown-content-images">
                              <div className="dropdown-content-images-first-row">
                                <div className="furniture-and-home-first-row-images">
                                  <a href="/other-services?AC Mechanic">
                                    <div className="furniture-first-row-image">
                                      <img
                                        className="furniture-first-row"
                                        src={AcMechanic}
                                        alt="sofa"
                                      />
                                    </div>
                                    <p className="sofa-text">AC Mechanic</p>
                                  </a>

                                  <a href="/other-services?Plumber">
                                    <div className="furniture-first-row-image">
                                      <img
                                        className="furniture-first-row"
                                        src={Plumber}
                                        alt="sofa"
                                      />
                                    </div>
                                    <p className="suitcase-text">Plumber</p>
                                  </a>

                                  <a href="/other-services?Electrian">
                                    <div className="furniture-first-row-image">
                                      <img
                                        className="furniture-first-row"
                                        src={Electrian}
                                        alt="sofa"
                                      />
                                    </div>
                                    <p className="suitcase-text">Electrician</p>
                                  </a>

                                  <a href="/other-services?MakeupArtist">
                                    <div className="furniture-first-row-image">
                                      <img
                                        className="furniture-first-row"
                                        src={MakeupArtist}
                                        alt="sofa"
                                      />
                                    </div>
                                    <p className="suitcase-text">
                                      Makeup Artist
                                    </p>
                                  </a>
                                </div>
                              </div>

                              <div className="dropdown-content-images-sec-row">
                                <div className="furniture-and-home-second-row-images">
                                  <a href="/other-services?saloon">
                                    <div className="furniture-sec-row-image">
                                      <img
                                        className="furniture-sec-row"
                                        src={Saloon}
                                        alt="chair"
                                      />
                                    </div>
                                    <p className="chair-text">Salon</p>
                                  </a>

                                  <a href="/other-services?Maid">
                                    <div className="furniture-sec-row-image">
                                      <img
                                        className="furniture-sec-row"
                                        src={Maid}
                                        alt="room"
                                      />
                                    </div>
                                    <p className="living-room-text">
                                      Maid Service
                                    </p>
                                  </a>

                                  <a href="/other-services?Cleaning Expert">
                                    <div className="furniture-sec-row-image">
                                      <img
                                        className="furniture-sec-row"
                                        src={Cleaning}
                                        alt="room"
                                      />
                                    </div>
                                    <p className="living-room-text">
                                      Cleaning Expert
                                    </p>
                                  </a>

                                  <a href="/other-services?Beautician Expert">
                                    <div className="furniture-sec-row-image">
                                      <img
                                        className="furniture-sec-row"
                                        src={Beautician}
                                        alt="room"
                                      />
                                    </div>
                                    <p className="living-room-text">
                                      Beautician Expert
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  {user?.role === null ||
                    (user?.role === "guest" && (
                      <Button
                        className="mt-2"
                        onClick={() => {
                          setFirstPropty(true);
                          // setIsPropertyFree(true);
                          // setCloseModal(true);
                        }}
                        sx={[
                          styles.searcButtons,
                          {
                            height: "48px",
                            fontSize: "14px",
                            backgroundColor: "#ffc72c",
                            color: "#000",
                            padding: "0px 15px",
                            borderRadius: "12px",
                          },
                          {
                            "&:hover": {
                              backgroundColor: "#ffc72c",
                            },
                            "&:focus": {
                              border: "1px solid #ffc72c",
                            },
                          },
                        ]}
                      >
                        List My Property
                        <sup
                          style={{
                            fontWeight: "800",
                            fontSize: "15px",
                          }}
                        >
                          Free
                        </sup>
                      </Button>
                    ))}
                  {/* )} */}

                  {/* {(user == null ||
                    user?.role === "guest" ||
                    user?.role === "admin" ||
                    user?.role === "serviceman") && (
                    <div className="navlinks-header-auth-icons dropdown">
                      <a
                        target="_blank"
                        style={{
                          color: "#9a291b",
                        }}
                        href="https://drive.google.com/file/d/1FAUe1vCpJOD1Q92273cMncsz-omR1QeG/view?usp=sharing"
                      >
                        User Guide
                      </a>
                      {!isMobile && (
                        <FiberManualRecordIcon
                          sx={{
                            height: "10px",
                            width: "10px",
                          }}
                        />
                      )}
                    </div>
                  )} */}
                  {/* {(user == null || user?.role === 'guest' || user?.role === 'admin' || user?.role === 'serviceman') && (
                    <div className='navlinks-header-auth-icons'>
                      <NavLink
                        style={{
                          color: '#9a291b',
                        }}
                        to='/admin-login'
                      >
                        AdminLogin
                      </NavLink>
                      {!isMobile && (
                        <FiberManualRecordIcon
                          sx={{
                            height: '10px',
                            width: '10px',
                          }}
                        />
                      )}
                    </div>
                  )} */}
                </div>
                <Link to="/happy-clients">
                  <AddLocation
                    sx={{
                      marginRight: "10px",
                      color: "#9A291B",
                    }}
                  />
                </Link>

                {token ? (
                  <div className="d-flex align-items-center">
                    {user?.role === null ||
                      (user?.role !== "guest" && (
                        <ButtonContained
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          sx={[
                            {
                              display: "flex",
                              width: "fit-content",
                              padding: "10px 16px",
                              borderRadius: "12px",
                              margin: "0px 20px",
                              fontSize: "12px",
                            },
                          ]}
                          startIcon={<AddOutlined />}
                        >
                          {!isMobile
                            ? user?.role === "admin"
                              ? " Add New"
                              : "Add Listing"
                            : user?.role === "admin"
                            ? " Add"
                            : "Add"}
                        </ButtonContained>
                      ))}
                    {/* {user?.role == "guest" && ( */}
                    {pathname.includes("furniture") && (
                      <Link
                        to="/checkout"
                        style={{
                          marginRight: "50px",
                        }}
                      >
                        <Badge badgeContent={totalItemsInCart} color="error">
                          <ShoppingCartIcon
                            sx={{
                              // width: "20px",
                              // height: "20px",
                              color: "#9A291B",
                            }}
                          />
                        </Badge>
                      </Link>
                    )}
                    {/* <Link to={'/shortlist'}>
                    <FaClipboardList  style={{ color: "#9A291B",height:'32px',width:'32px',marginRight:'10px' }}/>
                    </Link>

                    <Link to={"/wishlist"}>
                    <FaHeart style={{ color: "#9A291B",height:'32px',width:'32px',marginRight:'10px' }}/>                     
                    </Link> */}

                    {/* )} */}
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      sx={{
                        borderRadius: "8px",
                      }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {user?.role !== "serviceman" && (
                        <Link
                          to={"/add-property"}
                          className="header-fropdown-links"
                        >
                          <MenuItem
                          //  onClick={handleClose}
                          >
                            <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <MapsHomeWorkIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                }}
                              />
                            </Box>
                            <Typography
                              className="mx-2"
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              Add New Property
                            </Typography>
                          </MenuItem>
                        </Link>
                      )}

                      {user?.role === "admin" && (
                        <>
                          <Divider className="mx-3 my-0" />

                          <Link
                            to="/add-furniture"
                            className="header-fropdown-links"
                          >
                            <MenuItem>
                              <Box
                                className="text-center"
                                sx={{
                                  backgroundColor: "#f5f5f5",
                                  borderRadius: "30px",
                                  width: "30px",
                                  height: "30px",
                                }}
                              >
                                <WeekendIcon
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    color: "#ADB0B6",
                                  }}
                                />
                              </Box>
                              <Typography
                                className="mx-2"
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                Add New Furniture
                              </Typography>
                            </MenuItem>
                          </Link>
                          <Divider className="mx-3 my-0" />
                          <Link
                            to="/add-broker"
                            className="header-fropdown-links"
                          >
                            <MenuItem
                            //  onClick={handleClose}
                            >
                              <Box
                                className="text-center"
                                sx={{
                                  backgroundColor: "#f5f5f5",
                                  borderRadius: "30px",
                                  width: "30px",
                                  height: "30px",
                                }}
                              >
                                <PersonIcon
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    color: "#ADB0B6",
                                  }}
                                />
                              </Box>
                              <Typography
                                className="mx-2"
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                Add New Broker
                              </Typography>
                            </MenuItem>
                          </Link>
                        </>
                      )}
                      {user?.role === "admin" && (
                        <>
                          <Divider className="mx-3 my-0" />
                          <Link
                            to="/add-accessories "
                            className="header-fropdown-links"
                          >
                            <MenuItem
                            //  onClick={handleClose}
                            >
                              <Box
                                className="text-center"
                                sx={{
                                  backgroundColor: "#f5f5f5",
                                  borderRadius: "30px",
                                  width: "30px",
                                  height: "30px",
                                }}
                              >
                                <SettingsAccessibilityIcon
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    color: "#ADB0B6",
                                  }}
                                />
                              </Box>
                              <Typography
                                className="mx-2"
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                Add Accessories
                              </Typography>
                            </MenuItem>
                          </Link>
                        </>
                      )}

                      {(user?.role === "admin" ||
                        user?.role === "serviceman") && (
                        <>
                          <Divider className="mx-3 my-0" />

                          <Link
                            to="/add-other-service "
                            className="header-fropdown-links"
                          >
                            <MenuItem
                            //  onClick={handleClose}
                            >
                              <Box
                                className="text-center"
                                sx={{
                                  backgroundColor: "#f5f5f5",
                                  borderRadius: "30px",
                                  width: "30px",
                                  height: "30px",
                                }}
                              >
                                <HomeRepairServiceIcon
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    color: "#ADB0B6",
                                  }}
                                />
                              </Box>
                              <Typography
                                className="mx-2"
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                Add Service
                              </Typography>
                            </MenuItem>
                          </Link>
                        </>
                      )}
                    </Menu>

                    <Button
                      aria-controls={
                        openProfile ? "basic-profile-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openProfile ? "true" : undefined}
                      onClick={handleClickProfile}
                    >
                      <Avatar
                        sx={{
                          height: "48px",
                          width: "48px",
                          // display: "flex",
                          // width: "fit-content",
                          // padding: "12px 16px",
                          borderRadius: "48px",
                          // margin: "0px 20px"
                        }}
                        alt={user?.name}
                        src={assetUrl + user?.avatarImage}
                      />
                    </Button>
                    <Menu
                      onClose={handleCloseProfile}
                      id="basic-profile-menu"
                      anchorEl={anchorElProfile}
                      open={openProfile}
                      sx={{
                        borderRadius: "8px",
                      }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button-profile",
                      }}
                    >
                      {user !== null && (
                        <MenuItem>
                          <Box>
                            <Typography
                              sx={[
                                styles.fs16,
                                styles.fontFamilySGB,
                                styles.color9A291B,
                              ]}
                            >
                              {user?.name}
                            </Typography>
                            <Typography
                              sx={[
                                styles.fs12,
                                styles.fontFamilySG,
                                styles.colorADB0B6,
                              ]}
                              className="text-capitalize"
                            >
                              {user?.role}
                            </Typography>
                            {user?.role === "broker" ||
                              (user?.role === "developer" && (
                                <Typography
                                  sx={[
                                    styles.fs12,
                                    styles.fontFamilySG,
                                    styles.color9A291B,
                                  ]}
                                  className="text-capitalize"
                                >
                                  Credits Left: {user?.credits}
                                </Typography>
                              ))}
                          </Box>
                        </MenuItem>
                      )}
                      <Divider className="mx-3 my-0" />
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/profile");
                          // setCloseProfileModal(true);
                        }}
                        //  onClick={handleClose}
                      >
                        {/* <img src={UserIconProfile} /> */}
                        {/* <Box
                          className="text-center"
                          sx={{
                            backgroundColor: "#f5f5f5",
                            borderRadius: "30px",
                            width: "30px",
                            height: "30px",
                          }}
                        >
                          <PersonIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: "#ADB0B6",
                            }}
                          />  
                        </Box> */}

                        <FaUser
                          style={{
                            color: "#9A291B",
                            height: "24px",
                            width: "24px",
                            marginRight: "10px",
                          }}
                        />
                        <Link to={"#"} className="header-fropdown-links mx-2">
                          <Typography
                            style={{
                              paddingBlock: "4px",
                              paddingInline: "10px",
                            }}
                            sx={[
                              styles.fontFamilyDS,
                              styles.fs16,
                              styles.color240501,
                            ]}
                          >
                            My Profile
                          </Typography>
                        </Link>
                      </MenuItem>
                      {user?.role === "broker" ||
                        (user?.role === "developer" && (
                          <>
                            <Divider className="mx-3 my-0" />
                            <MenuItem
                              // onClick={handleClickBuyCredits}
                              //  onClick={createSession}
                              onClick={handleClose}
                            >
                              {/* <img src={UserIconProfile} /> */}
                              {/* <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <Money
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                }}
                              />
                            </Box> */}

                              <FaCreditCard
                                style={{
                                  color: "#9A291B",
                                  height: "24px",
                                  width: "24px",
                                  marginRight: "10px",
                                }}
                              />
                              <button
                                className=""
                                style={{
                                  border: "none",
                                  background: "none",
                                  color: "black",
                                }}
                                onClick={handleBuyCredits}
                              >
                                <Typography
                                  sx={[
                                    styles.fontFamilyDS,
                                    styles.fs16,
                                    styles.color240501,
                                  ]}
                                >
                                  Buy Credits
                                </Typography>
                              </button>
                            </MenuItem>
                          </>
                        ))}
                      {user?.role === "guest" && (
                        <>
                          <Divider className="mx-3 my-0" />
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              setCloseRatingsModal(true);
                            }}
                          >
                            <Box
                              // className="text-center"
                              style={{
                                display: "flex",
                                gap: "18px",
                              }}
                              // sx={{
                              //   backgroundColor: "#f5f5f5",
                              //   borderRadius: "30px",
                              //   width: "30px",
                              //   height: "30px",
                              // }}
                            >
                              {/* <StarIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                }}
                              /> */}
                            </Box>
                            <FaStar
                              style={{
                                color: "#9A291B",
                                height: "24px",
                                width: "24px",
                                marginRight: "10px",
                              }}
                            />
                            <Link
                              to={"#"}
                              className="header-dropdown-links mx-3"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <Typography
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                Rating & Comments
                              </Typography>
                            </Link>
                          </MenuItem>
                          <Divider className="mx-3 my-0" />
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Box
                              className="text-center"
                              style={{
                                display: "flex",
                                gap: "18px",
                              }}
                              // sx={{
                              //   backgroundColor: "#f5f5f5",
                              //   borderRadius: "30px",
                              //   width: "30px",
                              //   height: "30px",
                              // }}
                            >
                              {/* <LoyaltyIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              /> */}
                              <FaTag
                                style={{
                                  color: "#9A291B",
                                  height: "24px",
                                  width: "24px",
                                  marginRight: "10px",
                                }}
                              />
                              <Typography
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                My Referral Points {user?.referral_points}
                              </Typography>
                            </Box>
                          </MenuItem>

                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              setClosePropertiesContactViewModal(true);
                            }}
                          >
                            <Box
                              className="text-center"
                              style={{
                                display: "flex",
                                gap: "18px",
                              }}
                              // sx={{
                              //   backgroundColor: "#f5f5f5",
                              //   borderRadius: "30px",
                              //   width: "30px",
                              //   height: "30px",
                              // }}
                            >
                              {/* <VisibilityIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              /> */}
                              <FaEye
                                style={{
                                  color: "#9A291B",
                                  height: "24px",
                                  width: "24px",
                                  marginRight: "10px",
                                }}
                              />
                              <Link
                                to="#"
                                style={{
                                  textDecoration: "none",
                                }}
                                className="header-dropdown-links"
                              >
                                <Typography
                                  sx={[
                                    styles.fontFamilyDS,
                                    styles.fs16,
                                    styles.color240501,
                                  ]}
                                >
                                  Properties Contacts Viewed
                                </Typography>
                              </Link>
                            </Box>
                          </MenuItem>
                          <Divider className="mx-3 my-0" />
                          {pathname.includes("furniture") && (
                            <MenuItem
                              onClick={(e) => {
                                e.preventDefault();
                                setCloseCartsModal(true);
                              }}
                            >
                              {/* <Box
                                className="text-center"
                                sx={{
                                  backgroundColor: "#f5f5f5",
                                  borderRadius: "30px",
                                  width: "30px",
                                  height: "30px",
                                }}
                              >
                                <ShoppingCartIcon
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    color: "#ADB0B6",
                                  }}
                                />
                              </Box> */}
                              <FaCartPlus
                                style={{
                                  color: "#9A291B",
                                  height: "24px",
                                  width: "24px",
                                  marginRight: "10px",
                                }}
                              />

                              <Link
                                to="#"
                                style={{
                                  textDecoration: "none",
                                }}
                                className="header-dropdown-links mx-3"
                              >
                                <Typography
                                  sx={[
                                    styles.fontFamilyDS,
                                    styles.fs16,
                                    styles.color240501,
                                  ]}
                                >
                                  My Cart
                                </Typography>
                              </Link>
                            </MenuItem>
                          )}
                        </>
                      )}

                      {
                        <>
                          <Divider className="mx-3 my-0" />
                          {/* {pathname.includes('furniture') && ( */}
                          <MenuItem
                            onClick={(e) => {
                              navigate("/furniture/order");
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                gap: "18px",
                              }}
                            ></Box>
                            <FaCartArrowDown
                              style={{
                                color: "#9A291B",
                                height: "24px",
                                width: "24px",
                                marginRight: "10px",
                              }}
                            />
                            <Link
                              style={{
                                textDecoration: "none",
                                marginLeft: "20px",
                              }}
                              to="#"
                              className="header-dropdown-links mx-[1.2rem] py-2"
                            >
                              {/* <img src={EyeIconProfile} />*/}
                              <Typography
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                Furniture order
                              </Typography>
                            </Link>
                          </MenuItem>
                          {/* )} */}
                        </>
                      }

                      {user?.role === "serviceman" && (
                        <>
                          <Divider className="mx-3 my-0" />
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/manage-services");
                            }}
                          >
                            <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <Money
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                }}
                              />
                            </Box>
                            <button
                              className=""
                              style={{
                                border: "none",
                                background: "none",
                                color: "black",
                              }}
                              onClick={() => setOpenPopUp(true)}
                            >
                              <p>Manage services</p>
                            </button>
                          </MenuItem>
                        </>
                      )}

                      {(user?.role === "broker" ||
                        user?.role == "owner" ||
                        user?.role === "developer") && (
                        <>
                          <Divider className="mx-3 my-0" />
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/manage-properties");
                            }}
                          >
                            {/* <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <HomeMaxOutlined
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",

                                }}
                              />
                            </Box> */}
                            <FaKey
                              style={{
                                color: "#9A291B",
                                height: "24px",
                                width: "24px",
                                marginRight: "10px",
                              }}
                            />
                            <button
                              className=""
                              style={{
                                border: "none",
                                background: "none",
                                color: "black",
                              }}
                              // onClick={() => setOpenPopUp(true)}
                            >
                              <Typography
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                Manage Properties
                              </Typography>
                            </button>
                          </MenuItem>
                        </>
                      )}

                      {(user?.role === "broker" ||
                        user?.role == "owner" ||
                        user?.role === "developer") && (
                        <>
                          <Divider className="mx-3 my-0" />
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/viewed-properties-all");
                            }}
                          >
                            {/* <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <HomeMaxOutlined
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",

                                }}
                              />
                            </Box> */}
                            <FaEye
                              style={{
                                color: "#9A291B",
                                height: "24px",
                                width: "24px",
                                marginRight: "10px",
                              }}
                            />
                            <button
                              className=""
                              style={{
                                border: "none",
                                background: "none",
                                color: "black",
                              }}
                              // onClick={() => setOpenPopUp(true)}
                            >
                              <Typography
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                View Responses
                              </Typography>
                            </button>
                          </MenuItem>
                        </>
                      )}

                      {(user?.role === "broker" ||
                        user?.role == "owner" ||
                        user?.role === "developer") && (
                        <>
                          <Divider className="mx-3 my-0" />
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/wishlist");
                            }}
                          >
                            <FaHeart
                              style={{
                                color: "#9A291B",
                                height: "24px",
                                width: "24px",
                                marginRight: "10px",
                              }}
                            />
                            <button
                              className=""
                              style={{
                                border: "none",
                                background: "none",
                                color: "black",
                              }}
                              // onClick={() => setOpenPopUp(true)}
                            >
                              <Typography
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                Furniture Wishlist
                              </Typography>
                            </button>
                          </MenuItem>
                        </>
                      )}

                      {(user?.role === "broker" ||
                        user?.role == "owner" ||
                        user?.role === "developer") && (
                        <>
                          <Divider className="mx-3 my-0" />
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/shortlist");
                            }}
                          >
                            <FaClipboardList
                              style={{
                                color: "#9A291B",
                                height: "24px",
                                width: "24px",
                                marginRight: "10px",
                              }}
                            />
                            <button
                              className=""
                              style={{
                                border: "none",
                                background: "none",
                                color: "black",
                              }}
                              // onClick={() => setOpenPopUp(true)}
                            >
                              <Typography
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                Property Shortlist
                              </Typography>
                            </button>
                          </MenuItem>
                        </>
                      )}

                      <Divider className="mx-3 my-0" />
                      <MenuItem
                        onClick={() => {
                          localStorage.removeItem("token");
                          window.location.href = "/";
                        }}
                      >
                        {/* <Box
                          className="text-center"
                          sx={{
                            backgroundColor: "#f5f5f5",
                            borderRadius: "30px",
                            width: "30px",
                            height: "30px",
                          }}
                        >
                          <LogoutIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: "#ADB0B6",
                            }}
                          />
                        </Box> */}
                        <FaBackward
                          style={{
                            color: "#9A291B",
                            height: "24px",
                            width: "24px",
                            marginRight: "10px",
                          }}
                        />
                        <Link
                          style={{
                            textDecoration: "none",
                          }}
                          className="header-dropdown-links mx-2"
                        >
                          {/* <img src={LogoutIconProfile} /> */}
                          <Typography
                            style={{
                              paddingBlock: "4px",
                              paddingInline: "10px",
                            }}
                            sx={[
                              styles.fontFamilyDS,
                              styles.fs16,
                              styles.color240501,
                            ]}
                          >
                            Logout
                          </Typography>
                        </Link>
                      </MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <div className="header-buttons-align">
                    <div className="header_button_group">
                      {pathname.includes("furniture") && (
                        <Link
                          to="/checkout"
                          style={{
                            marginRight: "50px",
                          }}
                        >
                          <Badge
                            badgeContent={
                              JSON.parse(localStorage.getItem("cartData"))
                                ? JSON.parse(localStorage.getItem("cartData"))
                                    .length
                                : 0
                            }
                            color="error"
                          >
                            <ShoppingCartIcon
                              sx={{
                                // width: "20px",
                                // height: "20px",
                                color: "#9A291B",
                              }}
                            />
                          </Badge>
                        </Link>
                      )}
                      <Button
                        onClick={() => {
                          setIsPropertyFree(true);
                          setCloseModal(true);
                        }}
                        sx={[
                          styles.searcButtons,
                          {
                            height: "48px",
                            fontSize: "14px",
                            backgroundColor: "#ffc72c",
                            color: "#000",
                            padding: "0px 15px",
                            borderRadius: "12px",
                          },
                          {
                            "&:hover": {
                              backgroundColor: "#ffc72c",
                            },
                            "&:focus": {
                              border: "1px solid #ffc72c",
                            },
                          },
                        ]}
                      >
                        List My Property
                        <sup
                          style={{
                            fontWeight: "800",
                            fontSize: "15px",
                          }}
                        >
                          Free
                        </sup>
                      </Button>
                      <div className="default_white_btn">
                        {/* <Link to="/login" /> */}
                        <a
                          style={{ width: "20px" }}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setCloseLoginModal(true);
                          }}
                        >
                          {" "}
                          <span>Login</span>
                        </a>
                        {/* <a style={{ width: '20px', marginRight: '25px' }} href="/login" onClick={(e) => {
                          e.preventDefault()
  
                          setCloseLoginModal(true);
                        }}>    <span>Login</span>
                        </a> */}
                      </div>
                      <div className="default_maroon_btn">
                        <a
                          style={{
                            width: "20px",
                            marginRight: "25px",
                          }}
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsPropertyFree(false);
                            setCloseModal(true);
                          }}
                        >
                          {" "}
                          <span>Sign up</span>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </header>
      )}

      <ModalSignUp
        state={firstPropty}
        stateFunc={() => setFirstPropty(false)}
        title="Choose profession"
      >
        <Profession
          user={user}
          isPropertyFree={firstPropty}
          setCloseModal={() => setFirstPropty(false)}
        />
      </ModalSignUp>

      <ModalSignUp
        state={closeModal}
        stateFunc={setCloseModal}
        title="Gharwala Registration"
        setCloseLoginModal={setCloseLoginModal}
      >
        <SignUp
          setSignupModal={setCloseModal}
          isPropertyFree={isPropertyFree}
          setCloseModal={setCloseLoginModal}
          setCloseLoginModal={setCloseLoginModal}
        />
      </ModalSignUp>

      {!isMobile && (
        <ModalLogin
          setSignupModal={setCloseModal}
          state={closeLoginModal}
          stateFunc={setCloseLoginModal}
          title="Login"
        >
          <Login
            setSignupModal={setCloseModal}
            setCloseModal={setCloseLoginModal}
          />
        </ModalLogin>
      )}

      <GHModal
        state={closeProfileModal}
        stateFunc={setCloseProfileModal}
        title="My Profile"
      >
        <Profile
          closeProfileModal={closeProfileModal}
          setCloseModal={setCloseProfileModal}
        />
      </GHModal>
      <RatingModal
        state={closeRatingsModal}
        stateFunc={setCloseRatingsModal}
        title="Ratings"
      >
        <Ratings setCloseModal={setCloseRatingsModal} />
      </RatingModal>
      <RatingModal
        state={closePropertiesContactViewModal}
        stateFunc={setClosePropertiesContactViewModal}
        title="Properties Contacts Viewed"
      >
        <PropertyContactViewed
          setCloseModal={setClosePropertiesContactViewModal}
        />
      </RatingModal>
      <RatingModal
        state={closeCartsModal}
        stateFunc={setCloseCartsModal}
        title={`My Cart (${totalItemsInCart})`}
      >
        <CartModal
          setTotalItemsInCart={setTotalItemsInCart}
          setCloseModal={setCloseCartsModal}
        />
      </RatingModal>
    </React.Fragment>
  );
};

export default Header;
