import React from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import HomeIcon from "@mui/icons-material/Home";
import Location from "../../assets/images/work-location.svg";
import PropertyType from "../../assets/images/work-property-types.svg";
import BookProperty from "../../assets/images/book-your-property.svg";

const HowItWorks = () => {
  return (
    <Box sx={{ padding: { xs: "10px", md: "80px" } }}>
      <Typography
        variant="h4"
        align="center"
        fontWeight="bold"
        color="#0E0E46"
        gutterBottom
      >
        How It Works
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Box
          component="hr"
          sx={{
            width: "20px",
            height: "6px",
            backgroundColor: "#B90000",
            border: "none",
            borderRadius: "30px",
          }}
        />

        <Box
          component="hr"
          sx={{
            width: "50px",
            height: "6px",
            backgroundColor: "#B90000",
            border: "none",
            borderRadius: "30px",
          }}
        />
      </Box>

      <Typography variant="subtitle1" align="center" color="#8A909A" paragraph>
        Follow these 3 steps to book your place
      </Typography>

      {/* Grid container with responsive layout */}
      <Grid container spacing={4} justifyContent="center" marginTop="20px">
        {/* Card 1 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "30px 20px",
              textAlign: "center",
              // backgroundColor: "#f9f9f9",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                backgroundColor: "#f03e3e",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <img src={Location} alt="location-icon" />
            </Box>
            <CardContent>
              <Typography
                variant="h6"
                color="#1e2a78"
                fontWeight="bold"
                gutterBottom
              >
                01. Search for Location
              </Typography>
              <Typography variant="body2" color="#7d8597">
              Explore various locations to find the perfect neighborhood that meets your lifestyle, work, and family needs.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Card 2 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "30px 20px",
              textAlign: "center",
              // backgroundColor: "#f9f9f9",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                backgroundColor: "#B90000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <img src={PropertyType} alt="property-icon" />
            </Box>
            <CardContent>
              <Typography
                variant="h6"
                color="#1e2a78"
                fontWeight="bold"
                gutterBottom
              >
                02. Select Property Type
              </Typography>
              <Typography variant="body2" color="#7d8597">
              Browse through a wide range of property types, whether you're looking for a cozy apartment, spacious home, or commercial space.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Card 3 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "30px 20px",
              textAlign: "center",
              // backgroundColor: "#f9f9f9",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                backgroundColor: "#2ecc71",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <img src={BookProperty} alt="location-icon" />
            </Box>
            <CardContent>
              <Typography
                variant="h6"
                color="#1e2a78"
                fontWeight="bold"
                gutterBottom
              >
                03. Book Your Property
              </Typography>
              <Typography variant="body2" color="#7d8597">
              Easily reserve your selected property and take the next step toward making it yours with a seamless booking process.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HowItWorks;
