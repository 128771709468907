import React, { useEffect, useState } from "react";
import { privateReq } from "../../config/axiosConfig";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Chip,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Pagination,
  Stack,
  Paper,
} from "@mui/material";
import Header from "../../components/Header";
import toast from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import StarIcon from "@mui/icons-material/Star";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FilterListIcon from "@mui/icons-material/FilterList";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

const UserList = ({
  user_loggedin,
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [limit] = useState(20);

  useEffect(() => {
    fetchUsers();
  }, [page, searchQuery, role, startDate, endDate]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      // Build query parameters
      const params = new URLSearchParams({
        page,
        limit,
      });
      params.append("search", searchQuery);
      params.append("role", role);
      params.append("startDate", startDate);
      params.append("endDate", endDate);
      const response = await privateReq.get(`/users-list?${params.toString()}`);
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
      setTotalUsers(response.data.totalUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Failed to fetch users");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await privateReq.delete(`/users-admin/${id}`);
      setUsers(users.filter((user) => user.id !== id));
      toast.success(res?.data?.message);
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user");
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "activated":
        return "#4caf50";
      case "deactivated":
        return "#f44336";
      default:
        return "#ff9800";
    }
  };

  const getRoleColor = (role) => {
    switch (role?.toLowerCase()) {
      case "admin":
        return "#e91e63";
      case "broker":
        return "#2e7d32";
      case "owner":
        return "#1976d2";
      case "serviceman":
        return "#ed6c02";
      default:
        return "#9c27b0";
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Debounce search
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (page !== 1) {
        setPage(1); // Reset to first page when search changes
      } else {
        fetchUsers();
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <Box
        sx={{
          p: 4,
          mt: 8,
          minHeight: "100vh",
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)",
        }}
      >
        {/* Header Section */}
        <Paper
          elevation={0}
          sx={{
            p: 3,
            mb: 2,
            borderRadius: 3,
            background: "linear-gradient(135deg, #1976d2 0%, #1565c0 100%)",
            color: "white",
            mt: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PeopleAltIcon sx={{ fontSize: 40, mr: 2 }} />
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              User Management
            </Typography>
          </Box>
          <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
            {totalUsers} Total Users • Page {page} of {totalPages}
          </Typography>
        </Paper>

        {/* Filters Section */}
        <Paper
          elevation={3}
          sx={{
            p: 3,
            mb: 4,
            borderRadius: 3,
            background: "white",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <FilterListIcon sx={{ color: "#1976d2", mr: 1 }} />
            <Typography variant="h6" sx={{ color: "#2c3e50", fontWeight: 600 }}>
              Filters
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                placeholder="Search by name, email, or phone"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#1976d2" }} />
                    </InputAdornment>
                  ),
                  sx: { borderRadius: 2 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  value={role}
                  label="Role"
                  onChange={(e) => {
                    setRole(e.target.value);
                    setPage(1); // Reset to first page when role changes
                  }}
                  sx={{ borderRadius: 2 }}
                >
                  <MenuItem value="">All Roles</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="owner">Owner</MenuItem>
                  <MenuItem value="broker">Broker</MenuItem>
                  <MenuItem value="guest">Guest</MenuItem>
                  <MenuItem value="serviceman">Serviceman</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setPage(1); // Reset to first page when date changes
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setPage(1); // Reset to first page when date changes
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Paper>

        {/* User Cards Grid */}
        <Grid container spacing={3}>
          {loading ? (
            <Box sx={{ p: 3, textAlign: "center", width: "100%" }}>
              <Typography>Loading users...</Typography>
            </Box>
          ) : users.length === 0 ? (
            <Box sx={{ p: 3, textAlign: "center", width: "100%" }}>
              <Typography>No users found</Typography>
            </Box>
          ) : (
            users.map((user) => (
              <Grid item xs={12} sm={6} md={4} key={user.id}>
                <Card
                  elevation={3}
                  sx={{
                    borderRadius: 3,
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0 8px 24px rgba(0,0,0,0.15)",
                    },
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Avatar
                        sx={{
                          width: 56,
                          height: 56,
                          bgcolor: getRoleColor(user.role),
                          mr: 2,
                        }}
                      >
                        {user.name?.charAt(0).toUpperCase()}
                      </Avatar>
                      <Box>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 600, color: "#2c3e50" }}
                        >
                          {user.name}
                        </Typography>
                        <Chip
                          label={user.role}
                          size="small"
                          sx={{
                            bgcolor: `${getRoleColor(user.role)}15`,
                            color: getRoleColor(user.role),
                            fontWeight: 500,
                            mt: 0.5,
                          }}
                        />
                      </Box>
                      {user_loggedin?.role === "admin" && (
                        <IconButton
                          onClick={() => handleDelete(user.id)}
                          sx={{
                            ml: "auto",
                            color: "#f44336",
                            "&:hover": { bgcolor: "#ffebee" },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>

                    <Stack spacing={1.5}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <EmailIcon
                          sx={{ color: "#1976d2", mr: 1, fontSize: 20 }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          {user.email}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PhoneIcon
                          sx={{ color: "#2e7d32", mr: 1, fontSize: 20 }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          {user.phone}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon
                          sx={{ color: "#ed6c02", mr: 1, fontSize: 20 }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          Credits: {user.credits || 0}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CalendarTodayIcon
                          sx={{ color: "#9c27b0", mr: 1, fontSize: 20 }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          Joined: {formatDate(user.created)}
                        </Typography>
                      </Box>

                      <Chip
                        label={user.status}
                        size="small"
                        sx={{
                          width: "fit-content",
                          bgcolor: `${getStatusColor(user.status)}15`,
                          color: getStatusColor(user.status),
                          fontWeight: 500,
                          mt: 1,
                        }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>

        {/* Pagination */}
        {!loading && users.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(e, value) => setPage(value)}
              color="primary"
              size="large"
              sx={{
                "& .MuiPaginationItem-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default UserList;
