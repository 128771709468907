import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Paper, Box } from '@mui/material';
import fbSvg from '../assets/images/fb.svg';
import instaSvg from '../assets/images/insta.svg';
import twitterSvg from '../assets/images/twitter.svg';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.jpg';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
}));

export default function StickyFooter() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '30vh',
        justifyContent: 'center',
        padding: '40px 0',
        backgroundColor: '#3498DB',
      }}
    >
      {/* <CssBaseline /> */}

      <Box
        component='footer'
        sx={{
          // py: 3,
          // px: 2,
          // mt: 'auto',
          backgroundColor: '#3498DB',
        }}
      >
        <div className='container'>
          <div className='row'>
            {/* container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}> */}

            <div
              style={{
                backgroundColor: '#3498DB',
                borderRadius: '4px',
                paddingTop: '12px',
              }}
              className='col-md-3 xs-6 col-sm-6'
            >
              <div
                class='brand-logo'
                style={{
                  width: '90%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: '#3498DB',
                  borderRadius: '3px',
                }}
              >
                <img
                  src={Logo}
                  style={{
                    height: '50px',
                    width: '50px',
                    borderRadius: '100%',
                    // marginLeft: "15px",
                  }}
                />
                <h3
                  className='ms-2'
                  style={{
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1px',
                    justifyContent: 'center',
                  }}
                >
                  <p style={{ marginBottom: '1px', color: '#530900' }}>
                    Ghar
                    <span style={{ color: '#9A291B', color: '#530900' }}>Wala</span>
                    <sup style={{ fontSize: '15px' }}>TM</sup>
                  </p>

                  <span
                    style={{
                      fontSize: '10px',
                      color: '#530900',
                    }}
                  >
                    (Gharwala Home Solutions LLP)
                  </span>
                </h3>
              </div>
              <p style={{ color: 'white', marginTop:"20px" }}>
                <p> 2, New Tollygunge, Purba Putiary, Kolkata, West Bengal, 700093</p>
          
               <p ><a href="mailto:support@gharwala.com"
                style={{color:'white' ,textDecoration:'none'}}
                > support@gharwalah.com</a></p>
          
                <p> <a href="tel:+918240444587"
                style=
                {{color:'white',textDecoration:'none',fontSize:'2rem',fontWeight:'600' }}
                >  82404 44587</a></p>
              </p>
              <div className='footer-social-icons'>
                <a target='_blank'
                href='https://www.facebook.com/profile.php?id=61558672864335'>
                <img src={fbSvg} />
                </a>
                <a
                  target='_blank'
                  href='https://www.instagram.com/gharwala_solutions'
                >
                  <img src={instaSvg} />
                </a>
                <a
                  target='_blank'
                  href='https://www.youtube.com/@gharwala2847'
                >
                  <img src={twitterSvg} />
                </a>
                {/* <img src={emailSvg} /> */}
              </div>
            </div>
            <div className='col-md-3 xs-6'>
              <div className='footer-nav'>
                <ul>
                  <h6 style={{ color: '#fff' }}>Support</h6>
                  <li>
                    <Link
                      to='/privacy'
                      style={{ color: '#fff' }}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/return-policy'
                      style={{ color: '#fff' }}
                    >
                      Return Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/data-security'
                      style={{ color: '#fff' }}
                    >
                      Data Security
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/security-deposit'
                      style={{ color: '#fff' }}
                    >
                      Security Deposit
                    </Link>{' '}
                  </li>
                  <li>
                    <Link
                      to='/blogs'
                      style={{ color: '#fff' }}
                    >
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-3 xs-6'>
              <div className='footer-nav'>
                <ul>
                  <h6 style={{ color: '#fff' }}>Service</h6>
                  <li>
                    <Link
                      to='/buying-property'
                      style={{ color: '#fff' }}
                    >
                      Buying a Property
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/selling-property'
                      style={{ color: '#fff' }}
                    >
                      Selling a Property
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/renting-property'
                      style={{ color: '#fff' }}
                    >
                      Renting a Property
                    </Link>
                  </li>

                  <li>
                    <Link
                      to='/data-security'
                      style={{ color: '#fff' }}
                    >
                      Data Security
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/covid19'
                      style={{ color: '#fff' }}
                    >
                      Covid 19
                    </Link>
                  </li>

                  
                  <li>
                    <Link
                      to='/furniture-renting'
                      style={{ color: '#fff' }}
                    >
                      Furniture and Home Appliances Renting
                    </Link>
                  </li>

                  <li>
                    <Link
                      to='/furniture-buying'
                      style={{ color: '#fff' }}
                    >
                      Furniture and Home Appliances Buying
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-3 xs-6'>
              <div className='footer-nav'>
                <ul>
                  <h6 style={{ color: '#fff' }}>About</h6>
                  <li>
                    <Link
                      to='/about-us'
                      style={{ color: '#fff' }}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/contact-us'
                      style={{ color: '#fff' }}
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/terms'
                      style={{ color: '#fff' }}
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/rental-terms'
                      style={{ color: '#fff' }}
                    >
                      Furniture Rental Terms
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/referral-terms'
                      style={{ color: '#fff' }}
                    >
                      Referral Terms
                    </Link>
                  </li>

                  <li>
                    <Link
                      to='/welcome-to-gharwala'
                      style={{ color: '#fff' }}
                    >
                      GharWala Roadmap
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <a
          href='https://wa.me/+918240444587'
          target='_blank'
        >
          <img
            style={{
              width: '50px',
              height: '50px',
              position: 'fixed',
              right: 16,
              bottom: 10,
            }}
            src='./assests/images/whatsapp.png'
          ></img>
        </a>
      </Box>
    </Box>
  );
}
