import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { registerService } from "../../config/user";
import { validateField } from "../../util/json/helpers";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import EditSvg from "../../assets/images/edit.svg";
import { Avatar, Badge, Box } from "@mui/material";
import publicReq from "../../config/axiosConfig";
import { useMediaQuery } from "react-responsive";
import './index.css'

export default function SignUp({
  isPropertyFree = false,
  setCloseModal,
  setSignupModal,
  setCloseLoginModal,
}) {
  const inputRef = useRef();
  const [data, setData] = useState({
    photo: {},
  });
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [ReferralCode, setReferralCode] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState("");
  const [lang, setlang] = useState("");
  const [role, setRole] = useState("guest");
  const [loader, setLoader] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(publicReq.baseURL1``);
    let validate = true;

    if (fullName.trim() === "") {
      validateField("fullName", "Name field is required");
      validate = false;
    }

    if (phone.trim() === "") {
      validateField("phone", "Phone field is required");
      validate = false;
    }
    if (email.trim() === "") {
      validateField("email", "Email field is required");
      validate = false;
    }
    if (phone.trim().length != 10) {
      validateField("phone", "Phone Number should be 10 numbers only");
      validate = false;
    }
    if (!validate) {
      toast("Please fill required fields");
      return;
    }

    try {
      setLoader(true);
      const res = await registerService({
        fullName,
        phone,
        role,
        password,
        email,
        address,
        user_referral_code: ReferralCode,
      });

      if (res.data.status) {
        setLoader(false);
        setFullName("");
        setPhone("");
        setEmail("");
        setRole("");
        setPassword("");
        setReferralCode("");
        toast.success(res.data.message);
        // localStorage.setItem("token", res.data.token);
        let timeout = setTimeout(() => {
          setSignupModal(false);
          setCloseModal(true);
          // window.location.reload();
          clearTimeout(timeout);
        }, 1000);
      }
    } catch (err) {
      setLoader(false);

      if (err.response.status === 422) {
        toast.error("User Already Exists ");
        return;
      }
      // if (err.response.data.errors[0].msg) {
      //   toast(err.response.data.errors[0].msg);
      // }
      console.log("error is ", err);
    }
  };

  return (
    <div className="container">
      <div class="row g-3 align-items-center">
        <form onSubmit={onSubmit}>
          <Box class="col-lg-12 col-md-12 col-sm-12 form-group mb-3 d-flex align-items-center w-100 justify-content-center text-center">
           
          </Box>
          <div className="div-flex">
          <div className="w-half" >
            <label
              type="text"
              class="col-form-label auth-labels"
              placeholder="Enter your full name"
            >
              Full Name
            </label>

            <input
              type="text"
              class="form-control"
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
              }}
              autocomplete="off"
              name="fullName"
            />
          </div>

          <div class="w-half">
            <label for="inputEmail" class="col-form-label auth-labels">
              Email
            </label>

            <input
              placeholder="xyz@gmail.com"
              type="text"
              id="inputEmail"
              class="form-control"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              autocomplete="off"
              name="email"
            />
          </div>
         
          </div>

          <div className="div-flex">
          <div class="w-half">
            <label for="text" class="col-form-label auth-labels">
              Phone Number
            </label>

            <input
              type="text"
              id="inputPhone"
              class="form-control"
              placeholder="Enter you phone"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              name="phone"
              autocomplete="off"
            />
          </div>
          <div class="w-half">
            <label
              type="text"
              class="col-form-label auth-labels"
              placeholder="Enter a referral code "
            >
              Have a referral code ? (Optional)
            </label>

            <input
              type="text"
              class="form-control"
              value={ReferralCode}
              placeholder="Enter your referral code"
              onChange={(e) => {
                setReferralCode(e.target.value);
              }}
              autocomplete="off"
              name="referral_code"
            />
          </div>
          </div>
          {/* <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3">
            <label className="form-label auth-labels">Register As</label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="form-control w-100"
            >
              <option value="">Select</option>
              {isPropertyFree === false && (
                <option value="guest">Customer</option>
              )}
              <option value="broker">Broker</option>
              <option value="owner">Owner</option>
              <option value="serviceman">Service Provider</option>
            </select>
          </div> */}

          {/* <div class='col-lg-12 col-md-12 col-sm-12 form-group mb-3'>
            <div class='mb-3'>
              <label
                for='exampleFormControlTextarea1'
                class='form-label auth-labels'
              >
                Full Address
              </label>
              <textarea
                class='form-control'
                type='text'
                name='address'
                rows='2'
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                autocomplete='off'
              ></textarea>
            </div>
          </div> */}
          <div className="mt-4">
            <p>
              By continuing, you agree to{" "}
              <strong>
                Gharwala{/* <sup style={{ fontSize: "15px" }}>TM</sup> */}’s
              </strong>{" "}
              Terms of Service; Opens a new tab and acknowledge you've read our
              Privacy Policy.
            </p>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 form-group mb-2">
            <button className="btn-auth-signup ">Finish</button>
          </div>
          <Link
            className="auth-labels"
            to="#"
            onClick={() => {
              setSignupModal(false);
              setCloseModal(true);
            }}
          >
            Already have an account? Login
          </Link>
        </form>
      </div>
    </div>
  );
}
