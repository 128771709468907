import React, { useEffect } from "react";
import StickyFooter from "../../components/Footer";
import Header from "../../components/Header";
import HomeHeroSection from "../../components/HomeHeroSection/HomeHeroSection";
import SearchBar from "../../components/SearchBar/SearcBar";
import HomeAbouSection from "../../components/HomeAboutSection/HomeAboutSection";
import PropertySlider from "../../components/Slider/Slider";
import HomeBottomSection from "../../components/HomeBottomSections/HomeBottomSection";
import FurnitureSlider from "../../components/FurnitureSlider/FurnitureSlider";
// import SearchBarMobile from "../../components/SearchBarMobile/SearchBarMobile";
import HomeHeroSectionMobile from "../../components/HomeHeroSectionMobile/HomeHeroSectionMobile";
import StickyFooterMobile from "../../components/FooterMobile";
import { useMediaQuery } from "react-responsive";
import toast from "react-hot-toast";
import { updateTransactionService } from "../../config/user";
import agreementIcon from "../../assets/images/agreement.png";
import { Outlet, useNavigate } from "react-router-dom";
import shieldIcon from "../../assets/images/icon-shield-lock.svg";
import validateIcon from "../../assets/images/validate-icon.svg";
import RentAgreementImage from "../../assets/images/rent-agreement-image.jpeg";
import DoneIcon from "@mui/icons-material/Done";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./agreement.css";

const Agreement = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const showToastTransactionAlert = async () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("success") === "true") {
      await updateTransaction(params.get("id"), params.get("furniture"));
    } else if (params.get("success") === "false") {
      toast.error("Sorry something went wrong!");
      let timeout = setTimeout(() => {
        window.location.href = "/";
        clearTimeout(timeout);
      }, 1000);
    }
  };
  const updateTransaction = async (id, furniture) => {
    try {
      const res = await updateTransactionService({
        id,
        furniture,
      });
      if (res.data.status) {
        toast.success(
          `Payment done successfully!\n${
            furniture && furniture === "true"
              ? "Order placed"
              : "Credits added to account!"
          }`
        );
        let timeout = setTimeout(() => {
          window.location.href = "/";
          clearTimeout(timeout);
        }, 1000);
      } else {
        toast.error(res.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    let timeout = setTimeout(() => {
      showToastTransactionAlert();
    }, 800);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      {isMobile ? (
        <HomeHeroSection />
      ) : (
        <>
          <div className="agreement">
            {/* 
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1'
              />
              <style
                dangerouslySetInnerHTML={{
                  __html: 'img{display: block; margin-left: auto; margin-right: auto;}',
                }}
              />
              <img
                src={agreementIcon}
                alt='Screenshot 2024-02-22 at 10.29.10 PM'
                style={{ maxWidth: '100%' }}
              /> 
              */}

            <div className="agreement-inner-container">
              {/* left div: */}
              <div className="agreement-person-image">
                <img src={RentAgreementImage} alt="agreement-img" />
              </div>

              {/* right div: */}
              <div className="agreement-right-container">
                <div className="agreement-logo-desc">
                  <div className="agreement-shield-container">
                    <div className="agreement-shield-icon">
                      <img src={shieldIcon} alt="shield-icon" />
                    </div>
                    <div className="agreement-shield-desc">
                      100% Protection from Legal Disputes
                    </div>
                  </div>
                  <div className="agreement-validate-icon">
                    <img src={validateIcon} />
                  </div>
                </div>

                <div className="agreement-head">
                  <h1>
                    <span className="agreement-free">FREE</span> Rent Agreement
                  </h1>
                </div>

                <div className="agreement-desc">
                  <div className="agreement-desc-one">
                    <div>
                      <DoneIcon />
                    </div>
                    <div>Easy & super quick online drafting</div>
                  </div>

                  <div className="agreement-desc-two">
                    <div>
                      <DoneIcon />
                    </div>
                    <div>Completely customisable template</div>
                  </div>

                  <div className="agreement-desc-three">
                    <div>
                      <DoneIcon />
                    </div>
                    <div>Instant download available for FREE</div>
                  </div>
                </div>

                <div className="agreement-button-container">
                  <button
                    onClick={() => navigate("basic-details")}
                    className="agreement-button"
                  >
                    Get FREE Rent Agreement
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <HomeAbouSection />
      <HomeBottomSection />
      {isMobile ? <StickyFooterMobile /> : <StickyFooter />}
    </>
  );
};

export default Agreement;
