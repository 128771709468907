import React, { useState, useEffect } from "react";
import axios from "axios";
import { privateReq } from "../../config/axiosConfig";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  Grid,
  TextField,
  FormControlLabel,
  Button,
} from "@mui/material";
import toast from "react-hot-toast";
import Header from "../../components/Header";
import StickyFooter from "../../components/Footer";

const EditFurniture = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [furniture, setFurniture] = useState(null);
  const [newkey, setNewKey] = useState([{ key: "", value: "" }]);
  const [formData, setFormData] = useState({
    expectedMonthlyRent: "",
    expectedSecurityDeposit: "",
    firstTimePayment: "",
    threeMonthPayment: {
      threeMonthPayment: "",
      monthlyRentalSavings: "",
      earlyClosureCharge: "",
      freeAllocation: "",
      freeUpgrade: "",
    },

    sixMonthPayment: {
      sixMonthPayment: "",
      monthlyRentalSavings: "",
      earlyClosureCharge: "",
      freeAllocation: "",
      freeUpgrade: "",
    },

    nineMonthPayment: {
      nineMonthPayment: "",
      monthlyRentalSavings: "",
      earlyClosureCharge: "",
      freeAllocation: "",
      freeUpgrade: "",
    },

    twelveMonthPayment: {
      twelveMonthPayment: "",
      monthlyRentalSavings: "",
      earlyClosureCharge: "",
      freeAllocation: "",
      freeUpgrade: "",
    },
    keyValue: [],
    description: "",
  });
  const fetchCategories = async () => {
    axios
      .get(
        `https://gharwala-5466fddd6458.herokuapp.com/api/get-category?type=${"rent"}`
      )
      .then((res) => {
        setCategories(res.data.category);
        // console.log(res.data.category,'hi')
        const cat = res.data.category.filter(
          (category) => category.name === furniture?.categoryType
        );
        // console.log(furniture.categoryType)

        // fetchSubCategories(categories.filter((category)=>category===furniture.categoryType))
        fetchSubCategories(cat);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const fetchFurniture = async () => {
      try {
        const response = await privateReq.get(`/furniture/${id}`);
        setFurniture(response.data.furniture);
        // Populate formData with furniture data
        console.log(response.data.furniture);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...response.data.furniture,
        }));
      } catch (error) {
        console.error("Error fetching furniture:", error);
      }
    };

    fetchFurniture();
    fetchCategories();
  }, []);

  const handleCategoryChange = (selectedCategory) => {
    console.log("changed");
    const cat = categories.filter(
      (category) => category.name === selectedCategory
    );
    setFormData({
      ...formData,
      categoryType: selectedCategory,
    });
    fetchSubCategories(cat[0]);
  };
  const fetchSubCategories = async (category) => {
    console.log("hi", category);
    axios
      .post("https://gharwala-5466fddd6458.herokuapp.com/api/get-subcategory", {
        id: category._id,
      })
      .then((res) => {
        setSubCategories(res.data.getSubcategory);
        console.log("res", res.data.getSubcategory);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSave = () => {
    console.log(formData);

    privateReq
      .put(`/furniture/update/${id}`, formData)
      .then((res) => {
        if (res.status === 201) {
          toast.success("furniture updated successfully");
          navigate("/furniture");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    const uploadedImages = [];

    try {
      await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);

          const response = await axios.post(
            `https://gharwala-5466fddd6458.herokuapp.com/api/upload-image`,
            formData
          );
          if (response.data) {
            uploadedImages.push(response.data);
            toast.success("Image uploaded successfully");
          } else {
            toast.error("Failed to upload image");
          }
        })
      );

      setFormData((prev) => ({
        ...prev,
        furnitureImages: [...prev.furnitureImages, ...uploadedImages].slice(
          0,
          10
        ), // Limit to 10 images
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image");
    }
  };

  const removeImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      furnitureImages: prev.furnitureImages.filter((_, i) => i !== index),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    if (keys.length > 1) {
      setFormData((prevData) => ({
        ...prevData,
        [keys[0]]: {
          ...prevData[keys[0]],
          [keys[1]]: value,
        },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // const [newkey, setNewKey] = useState([{ key: "", value: "" }]);

  const handleKeyValueChange = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [newkey[0].key]: newkey[0].value,
    }));
    setNewKey([{ key: "", value: "" }]);
  };

  const handleSubcategoryChange = (selectedSubcategory) => {
    setFormData({
      ...formData,
      subcategoryType: selectedSubcategory,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const onAddMoreKey = () => {
    setFormData({
      ...formData,
      keyValue: [...formData.keyValue, { key: "", value: "" }],
    });
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const keyValue = [...formData.keyValue];
    keyValue[index][name] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      keyValue,
    }));
  };
  return (
    <div>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        style={{
          fontSize: "30px",
          fontFamily: "Montserrat",
          marginTop: "100px",
        }}
      >
        Edit Furniture
      </div>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="furnitureName">
            Furniture name
          </label>
          <input
            style={styles.input}
            type="text"
            id="furnitureName"
            name="furnitureName"
            value={formData?.furnitureName}
            onChange={handleChange}
          />
        </div>

        {/* Category Type */}
        <Box className="mt-3">
          <Typography
            variant="subtitle1"
            sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
            className="mb-2"
          >
            Category Type
          </Typography>
          <RadioGroup name="categoryType">
            {categories.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.name}
                label={item.name}
                control={
                  <Radio
                    checked={item.name === formData.categoryType}
                    sx={{ "&.Mui-checked": styles.color9A291B }}
                    onChange={() => handleCategoryChange(item.name)}
                  />
                }
              />
            ))}
          </RadioGroup>
        </Box>

        {/* Sub Category Type */}
        <Typography
          sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
          className="mb-2"
        >
          Sub Category Type
        </Typography>
        <RadioGroup
          defaultValue=""
          name="sub-category-radio-buttons-group"
          value={formData.subcategoryType}
          onChange={(e) => handleSubcategoryChange(e.target.value)}
        >
          {subCategories?.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.name}
              label={
                <Typography
                  variant="body2"
                  sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                >
                  {item.name}
                </Typography>
              }
              control={
                <Radio
                  checked={item.name === formData.subcategoryType}
                  sx={{ "&.Mui-checked": styles.color9A291B }}
                />
              }
            />
          ))}
        </RadioGroup>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "12" }}>
          <Typography gutterBottom>Key Value Pairs</Typography>
          {formData?.keyValue.map((input, index) => (
            <Grid key={index} container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  className="mt-10"
                  name="key"
                  placeholder="Item Name"
                  value={input.key}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </Grid>
              <Grid item>
                <TextField
                  className="mt-10"
                  name="value"
                  placeholder="Item value"
                  value={input.value}
                  type="text"
                  onChange={(e) => handleInputChange(index, e)}
                />
              </Grid>
            </Grid>
          ))}
          <Box
            style={{
              display: "flex",
              width: "40%",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => onAddMoreKey()}
              variant="contained"
              className="mt-10"
            >
              Add More
            </Button>
          </Box>
        </Box>
        {/* Expected Monthly Rent */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="expectedMonthlyRent">
            Expected Monthly Rent:
          </label>
          <input
            style={styles.input}
            type="text"
            id="expectedMonthlyRent"
            name="expectedMonthlyRent"
            value={formData?.expectedMonthlyRent}
            onChange={handleChange}
          />
        </div>

        {/* Expected Security Deposit */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="expectedSecurityDeposit">
            Expected Security Deposit:
          </label>
          <input
            style={styles.input}
            type="text"
            id="expectedSecurityDeposit"
            name="expectedSecurityDeposit"
            value={formData?.expectedSecurityDeposit}
            onChange={handleChange}
          />
        </div>

        {/* First Time Payment */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="firstTimePayment">
            First Time Payment:
          </label>
          <input
            style={styles.input}
            type="text"
            id="firstTimePayment"
            name="firstTimePayment"
            value={formData?.firstTimePayment}
            onChange={handleChange}
          />
        </div>

        {/* Three Month Payment */}
        <div style={styles.fieldGroup}>
          <label
            style={styles.label}
            htmlFor="threeMonthPayment.threeMonthPayment"
          >
            Three Month Payment:
          </label>
          <input
            style={styles.input}
            type="text"
            id="threeMonthPayment"
            name="threeMonthPayment.threeMonthPayment"
            value={formData?.threeMonthPayment?.threeMonthPayment}
            onChange={handleChange}
          />
        </div>

        {/* Three Month Monthly Rental Savings */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="threeMonthMonthlyRentalSavings">
            Three Month Monthly Rental Savings:
          </label>
          <input
            style={styles.input}
            type="text"
            id="threeMonthMonthlyRentalSavings"
            name="threeMonthPayment.monthlyRentalSavings"
            value={formData?.threeMonthPayment?.monthlyRentalSavings}
            onChange={handleChange}
          />
        </div>

        {/* Three Month Early Closure Charge */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="threeMonthEarlyClosureCharge">
            Three Month Early Closure Charge:
          </label>
          <input
            style={styles.input}
            type="text"
            id="threeMonthEarlyClosureCharge"
            name="threeMonthPayment.earlyClosureCharge"
            value={formData?.threeMonthPayment?.earlyClosureCharge}
            onChange={handleChange}
          />
        </div>

        {/* Three Month Free Allocation */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="threeMonthFreeAllocation">
            Three Month Free Allocation:
          </label>
          <input
            style={styles.input}
            type="text"
            id="threeMonthFreeAllocation"
            name="threeMonthPayment.freeAllocation"
            value={formData?.threeMonthPayment?.freeAllocation}
            onChange={handleChange}
          />
        </div>

        {/* Three Month Free Upgrade */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="threeMonthFreeUpgrade">
            Three Month Free Upgrade:
          </label>
          <input
            style={styles.input}
            type="text"
            id="threeMonthFreeUpgrade"
            name="threeMonthPayment.freeUpgrade"
            value={formData?.threeMonthPayment?.freeUpgrade}
            onChange={handleChange}
          />
        </div>

        {/* Six Month Payment */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="sixMonthPayment">
            Six Month Payment:
          </label>
          <input
            style={styles.input}
            type="text"
            id="sixMonthPayment"
            name="sixMonthPayment.sixMonthPayment"
            value={formData?.sixMonthPayment?.sixMonthPayment}
            onChange={handleChange}
          />
        </div>

        {/* Six Month Monthly Rental Savings */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="sixMonthMonthlyRentalSavings">
            Six Month Monthly Rental Savings:
          </label>
          <input
            style={styles.input}
            type="text"
            id="sixMonthMonthlyRentalSavings"
            name="sixMonthPayment.monthlyRentalSavings"
            value={formData?.sixMonthPayment?.monthlyRentalSavings}
            onChange={handleChange}
          />
        </div>

        {/* Six Month Early Closure Charge */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="sixMonthEarlyClosureCharge">
            Six Month Early Closure Charge:
          </label>
          <input
            style={styles.input}
            type="text"
            id="sixMonthEarlyClosureCharge"
            name="sixMonthPayment.earlyClosureCharge"
            value={formData?.sixMonthPayment?.earlyClosureCharge}
            onChange={handleChange}
          />
        </div>

        {/* Six Month Free Allocation */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="sixMonthFreeAllocation">
            Six Month Free Allocation:
          </label>
          <input
            style={styles.input}
            type="text"
            id="sixMonthFreeAllocation"
            name="sixMonthPayment.freeAllocation"
            value={formData?.sixMonthPayment?.freeAllocation}
            onChange={handleChange}
          />
        </div>

        {/* Six Month Free Upgrade */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="sixMonthFreeUpgrade">
            Six Month Free Upgrade:
          </label>
          <input
            style={styles.input}
            type="text"
            id="sixMonthFreeUpgrade"
            name="sixMonthPayment.freeUpgrade"
            value={formData?.sixMonthPayment?.freeUpgrade}
            onChange={handleChange}
          />
        </div>
        {/* Nine Month Payment */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="nineMonthPayment">
            Nine Month Payment:
          </label>
          <input
            style={styles.input}
            type="text"
            id="nineMonthPayment"
            name="nineMonthPayment.nineMonthPayment"
            value={formData?.nineMonthPayment?.nineMonthPayment}
            onChange={handleChange}
          />
        </div>

        {/* Nine Month Monthly Rental Savings */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="nineMonthMonthlyRentalSavings">
            Nine Month Monthly Rental Savings:
          </label>
          <input
            style={styles.input}
            type="text"
            id="nineMonthMonthlyRentalSavings"
            name="nineMonthPayment.monthlyRentalSavings"
            value={formData?.nineMonthPayment?.monthlyRentalSavings}
            onChange={handleChange}
          />
        </div>

        {/* Nine Month Early Closure Charge */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="nineMonthEarlyClosureCharge">
            Nine Month Early Closure Charge:
          </label>
          <input
            style={styles.input}
            type="text"
            id="nineMonthEarlyClosureCharge"
            name="nineMonthPayment.earlyClosureCharge"
            value={formData?.nineMonthPayment?.earlyClosureCharge}
            onChange={handleChange}
          />
        </div>

        {/* Nine Month Free Allocation */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="nineMonthFreeAllocation">
            Nine Month Free Allocation:
          </label>
          <input
            style={styles.input}
            type="text"
            id="nineMonthFreeAllocation"
            name="nineMonthPayment.freeAllocation"
            value={formData?.nineMonthPayment?.freeAllocation}
            onChange={handleChange}
          />
        </div>

        {/* Nine Month Free Upgrade */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="nineMonthFreeUpgrade">
            Nine Month Free Upgrade:
          </label>
          <input
            style={styles.input}
            type="text"
            id="nineMonthFreeUpgrade"
            name="nineMonthPayment.freeUpgrade"
            value={formData?.nineMonthPayment?.freeUpgrade}
            onChange={handleChange}
          />
        </div>

        {/* Twelve Month Payment */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="twelveMonthPayment">
            Twelve Month Payment:
          </label>
          <input
            style={styles.input}
            type="text"
            id="twelveMonthPayment"
            name="twelveMonthPayment.twelveMonthPayment"
            value={formData?.twelveMonthPayment?.twelveMonthPayment}
            onChange={handleChange}
          />
        </div>

        {/* Twelve Month Monthly Rental Savings */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="twelveMonthMonthlyRentalSavings">
            Twelve Month Monthly Rental Savings:
          </label>
          <input
            style={styles.input}
            type="text"
            id="twelveMonthMonthlyRentalSavings"
            name="twelveMonthPayment.monthlyRentalSavings"
            value={formData?.twelveMonthPayment?.monthlyRentalSavings}
            onChange={handleChange}
          />
        </div>

        {/* Twelve Month Early Closure Charge */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="twelveMonthEarlyClosureCharge">
            Twelve Month Early Closure Charge:
          </label>
          <input
            style={styles.input}
            type="text"
            id="twelveMonthEarlyClosureCharge"
            name="twelveMonthPayment.earlyClosureCharge"
            value={formData?.twelveMonthPayment?.earlyClosureCharge}
            onChange={handleChange}
          />
        </div>

        {/* Twelve Month Free Allocation */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="twelveMonthFreeAllocation">
            Twelve Month Free Allocation:
          </label>
          <input
            style={styles.input}
            type="text"
            id="twelveMonthFreeAllocation"
            name="twelveMonthPayment.freeAllocation"
            value={formData?.twelveMonthPayment?.freeAllocation}
            onChange={handleChange}
          />
        </div>

        {/* Twelve Month Free Upgrade */}
        <div style={styles.fieldGroup}>
          <label style={styles.label} htmlFor="twelveMonthFreeUpgrade">
            Twelve Month Free Upgrade:
          </label>
          <input
            style={styles.input}
            type="text"
            id="twelveMonthFreeUpgrade"
            name="twelveMonthPayment.freeUpgrade"
            value={formData?.twelveMonthPayment?.freeUpgrade}
            onChange={handleChange}
          />
        </div>
        <div
          style={{
            fontSize: "30px",
            fontFamily: "Montserrat",
          }}
        >
          Images:
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            marginTop: "10px",
          }}
        >
          {formData?.furnitureImages?.map((image, index) => (
            <div
              key={index}
              style={{
                position: "relative",
              }}
            >
              <img
                src={image}
                alt={`furniture-${index}`}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
              <div
                onClick={() => removeImage(index)}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                &times;
              </div>
            </div>
          ))}
          <div
            style={{
              width: "100px",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px dashed #ccc",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => document.getElementById("fileInput").click()}
          >
            <span style={{ fontSize: "30px", color: "#ccc" }}>+</span>
          </div>
        </div>
        <input
          id="fileInput"
          type="file"
          style={{ display: "none" }}
          onChange={handleImageUpload}
          multiple
        />

        {/* Save Button */}
        <button style={styles.button} type="submit">
          Save
        </button>
      </form>
      <StickyFooter />
    </div>
  );
};

const styles = {
  form: {
    maxWidth: "500px",
    margin: "10px auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    // marginTop:'50px'
  },
  fieldGroup: {
    marginBottom: "20px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontSize: "16px",
  },
  input: {
    width: "100%",
    padding: "8px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  textarea: {
    width: "100%",
    padding: "8px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    minHeight: "100px",
  },
  button: {
    backgroundColor: "#007bff",
    color: "#fff",
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
  },
};

export default EditFurniture;
