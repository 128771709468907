import React, { useRef, useState, useEffect } from "react";
import { Box, Select, Typography, Avatar } from "@mui/material";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import styles from "../../styles/styles.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonContained from "../../components/Buttons/ButtonContained";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import classes from "../../styles/styles.js";
import { getAllUsers } from "../../redux/action/auth";
import { assetUrl, imageReq } from "../../config/axiosConfig";
import { updateUserStatusService } from "../../config/user";
import toast from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import PageNotFound from "../../components/PageNotFound/index.js";
import { Link } from "react-router-dom";

const Owners = ({
  role = "owner",
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const headerRef = useRef();
  const dispatch = useDispatch();
  const { allUsers } = useSelector((state) => state.auth);
  // const totalUsers = useSelector((state) => state.auth.totalUsers);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const navigate = useNavigate();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [page, setPage] = useState(1);
  const [phoneNumberFilter, setPhoneNumberFilter] = useState("");

  const handleInputChange = (event) => {
    setPhoneNumberFilter(event.target.value);
  };

  const theme = useTheme();

  useEffect(() => {
    onGetAllUsers();
  }, []);

  const onGetAllUsers = () => {
    let queryParams = `?role=${role}`;
    dispatch(getAllUsers(queryParams));
  };

  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  const onUpdateUserStatus = async (id, status) => {
    try {
      const res = await updateUserStatusService({
        active: status,
        userId: id,
      });
      if (res.data.status) {
        toast.success("Status updated successfully!");
        onGetAllUsers();
      }
    } catch (e) {
      console.log(e);
    }
  };
  // useEffect(() => {
  //   setHeaderHeight(headerRef.current.offsetHeight);
  // }, [headerHeight]);

  return showPage ? (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <div className="container-fluid px-0">
        <div className="properties-container bgF4F4F4">
          {/* Input field for phone number filtering */}

          <div
            className="row mx-0"
            style={{
              width: "100%",
            }}
          >
            <div className="col-lg-12 col-md-12 col-xs-12">
              <div className="row properties-list mx-0">
                <input
                  style={{
                    padding: "7px",
                    borderRadius: "10px",
                    width: "400px",
                    margin: "auto",
                    marginTop: "100px",
                    border: "1px solid #9a291b",
                  }}
                  type="number"
                  value={phoneNumberFilter}
                  onChange={handleInputChange}
                  placeholder="Enter Phone Number to search"
                />

                <div className="properties-header mt-5 px-3">
                  <div>
                    <span className="text-capitalize">{role}</span>
                  </div>

                  <div
                    className="properties-filter-select"
                    style={{ marginRight: "1.25rem" }}
                  ></div>
                </div>
                <div
                  className={`${
                    isMobile ? "d-block" : "d-flex"
                  } flex-wrap brokers-list`}
                >
                  {allUsers &&
                    allUsers
                      .filter(
                        (user) =>
                          user.phone && user.phone.includes(phoneNumberFilter)
                      )
                      .map((users, index) => (
                        <div class="col-lg-4 col-md-6 col-xs-12 mt-3">
                          <div
                            style={{
                              minWidth: "90%",
                            }}
                            className="card  card-container-user-desc  border-0 h-100 "
                          >
                            <Box
                              sx={[styles.borderRadius12]}
                              key={index}
                              className="d-flex flex-column justify-content-between shadow-none px-4 py-3"
                            >
                              <div className="d-flex align-items-center">
                                <Avatar
                                  sx={{ width: "72px", height: "72px" }}
                                  alt={users?.users?.name || ""}
                                  src={assetUrl + users?.avatarImage}
                                  // src={card.imageSrc}
                                />
                                <Box className="px-2">
                                  <Typography
                                    sx={[
                                      styles.fontFamilyDS,
                                      styles.fbBold,
                                      styles.fs20,
                                      styles.color240501,
                                      styles.fbBold,
                                      styles.textTransformCapitalize,
                                    ]}
                                  >
                                    {users?.name}
                                  </Typography>
                                  <Typography
                                    className="text-capitalize text-color979797"
                                    sx={[styles.fontFamilyDS, styles.fs20]}
                                  >
                                    {users?.role}
                                  </Typography>

                                  <p
                                    style={{
                                      color: "gray",
                                      fontSize: "small",
                                    }}
                                  >
                                    {users.phone}
                                  </p>
                                </Box>
                              </div>

                              <div className="py-4">
                                <Typography
                                  className="text-color979797"
                                  sx={[
                                    styles.fontFamilyDS,
                                    ,
                                    styles.fs16,
                                    {
                                      display: "-webkit-box",
                                      overflow: "hidden",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 2,
                                    },
                                  ]}
                                >
                                  {users?.desc || "No description to show"}
                                </Typography>
                              </div>
                              <div>
                                <div className="py-2">
                                  <Box className="d-flex">
                                    <Typography
                                      className="w-40"
                                      sx={[
                                        styles.fontFamilyDS,
                                        styles.fs16,
                                        styles.fontWeight500,
                                      ]}
                                    >
                                      Language:
                                    </Typography>

                                    <Typography
                                      className="text-color979797 text-capitalize"
                                      sx={[styles.fontFamilyDS, styles.fs16]}
                                    >
                                      {users?.language}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    className="text-color979797 text-capitalize"
                                    sx={[styles.fontFamilyDS, styles.fs16]}
                                  >
                                    Date: {users.created.slice(0, 10)}
                                  </Typography>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <ButtonContained
                                  sx={[
                                    styles.fontFamilySGB,
                                    users.status !== "activated" &&
                                      styles.btnBgGreen,
                                    styles.p10,
                                    styles.borderRadius12,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...classes.fs9,
                                        ...classes.margin4,
                                        ...classes.borderRadius7,
                                        ...classes.p7,
                                      },
                                    },
                                  ]}
                                  className="m-0 my-2 mx-1"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onUpdateUserStatus(
                                      users._id,
                                      users.status == "activated"
                                        ? "deactivated"
                                        : "activated"
                                    );
                                  }}
                                >
                                  {users.status == "activated"
                                    ? "Deactivate"
                                    : "Activate"}
                                </ButtonContained>
                                <ButtonOutlined
                                  onClick={() =>
                                    navigate(`/${role}s/${users._id}`)
                                  }
                                  sx={[
                                    styles.fontFamilySGB,
                                    styles.borderRadius12,
                                    ,
                                    classes.fs12,
                                    classes.margin4,
                                    classes.p10,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...classes.fs9,
                                        ...classes.margin4,
                                        ...classes.borderRadius7,
                                        ...classes.p6,
                                      },
                                    },
                                  ]}
                                  className="m-0 mx-1 my-2"
                                >
                                  View Details
                                </ButtonOutlined>
                              </div>
                            </Box>
                          </div>
                        </div>
                      ))}

                  {phoneNumberFilter === "" &&
                    allUsers &&
                    allUsers.map((users, index) => (
                      <div class="col-lg-4 col-md-6 col-xs-12 mt-3">
                        <div
                          style={{
                            minWidth: "90%",
                          }}
                          className="card  card-container-user-desc  border-0 h-100 "
                        >
                          <Box
                            sx={[styles.borderRadius12]}
                            key={index}
                            className="d-flex flex-column justify-content-between shadow-none px-4 py-3"
                          >
                            <div className="d-flex align-items-center">
                              <Avatar
                                sx={{ width: "72px", height: "72px" }}
                                alt={users?.users?.name || ""}
                                src={assetUrl + users?.avatarImage}
                                // src={card.imageSrc}
                              />
                              <Box className="px-2">
                                <Typography
                                  sx={[
                                    styles.fontFamilyDS,
                                    styles.fbBold,
                                    styles.fs20,
                                    styles.color240501,
                                    styles.fbBold,
                                    styles.textTransformCapitalize,
                                  ]}
                                >
                                  {users?.name}
                                </Typography>

                                <Typography
                                  className="text-capitalize text-color979797"
                                  sx={[styles.fontFamilyDS, styles.fs20]}
                                >
                                  {users?.role}
                                </Typography>

                                <p
                                  style={{
                                    color: "gray",
                                    fontSize: "small",
                                  }}
                                >
                                  {users.phone}
                                </p>
                              </Box>
                            </div>

                            <div className="py-4">
                              <Typography
                                className="text-color979797"
                                sx={[
                                  styles.fontFamilyDS,
                                  ,
                                  styles.fs16,
                                  {
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 2,
                                  },
                                ]}
                              >
                                {users?.desc || "No description to show"}
                              </Typography>
                            </div>
                            <div>
                              <div className="py-2">
                                <Box className="d-flex">
                                  <Typography
                                    className="w-40"
                                    sx={[
                                      styles.fontFamilyDS,
                                      styles.fs16,
                                      styles.fontWeight500,
                                    ]}
                                  >
                                    Language:
                                  </Typography>
                                  <Typography
                                    className="text-color979797 text-capitalize"
                                    sx={[styles.fontFamilyDS, styles.fs16]}
                                  >
                                    {users?.language}
                                  </Typography>
                                </Box>
                                <Typography
                                  className="text-color979797 text-capitalize"
                                  sx={[styles.fontFamilyDS, styles.fs16]}
                                >
                                  Date: {users.created.slice(0, 10)}
                                </Typography>
                              </div>
                            </div>
                            <div className="d-flex mt-4">
                              <ButtonContained
                                sx={[
                                  styles.fontFamilySGB,
                                  users.status !== "activated" &&
                                    styles.btnBgGreen,
                                  styles.p10,
                                  styles.borderRadius12,
                                  {
                                    [theme.breakpoints.between("xs", "md")]: {
                                      ...classes.fs9,
                                      ...classes.margin4,
                                      ...classes.borderRadius7,
                                      ...classes.p7,
                                    },
                                  },
                                ]}
                                className="m-0 my-2 mx-1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  onUpdateUserStatus(
                                    users._id,
                                    users.status == "activated"
                                      ? "deactivated"
                                      : "activated"
                                  );
                                }}
                              >
                                {users.status == "activated"
                                  ? "Deactivate"
                                  : "Activate"}
                              </ButtonContained>
                              <ButtonOutlined
                                onClick={() =>
                                  navigate(`/${role}s/${users._id}`)
                                }
                                sx={[
                                  styles.fontFamilySGB,
                                  styles.borderRadius12,
                                  ,
                                  classes.fs12,
                                  classes.margin4,
                                  classes.p10,
                                  {
                                    [theme.breakpoints.between("xs", "md")]: {
                                      ...classes.fs9,
                                      ...classes.margin4,
                                      ...classes.borderRadius7,
                                      ...classes.p6,
                                    },
                                  },
                                ]}
                                className="m-0 mx-1 my-2"
                              >
                                View Details
                              </ButtonOutlined>
                            </div>
                          </Box>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <StickyFooter /> */}
    </>
  ) : (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <PageNotFound />
        <Link to="/">
          <button>Go To Home</button>
        </Link>
      </div>
    </>
  );
};

export default Owners;
