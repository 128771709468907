import { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
  addWishlistService,
  getMyWishlistService,
  getMyShortlistService,
  removeFromWishlistService,
} from "../../config/furniture";
import { addToCartService } from "../../config/furniture";
import ButtonContained from "../../components/Buttons/ButtonContained";
import { buyPhonePe } from "../../config/user";
import toast from "react-hot-toast";
import { FaTrash } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { privateReq } from "../../config/axiosConfig";
import { useSelector } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import SignUp from "../../components/Signup";
import ModalSignUp from "../../components/ModalSignup";
import Login from "../../components/Login";
import ModalLogin from "../../components/ModalLogin";
import FurnitureAddress from "../checkout/FurnitureAddress";
import publicReq from "../../config/axiosConfig";
import userEvent from "@testing-library/user-event";

import { Link } from "react-router-dom";
import NotFound from "../wishlist/not_found";

const Shortlist = ({ user }) => {
  const userData = useSelector((state) => state.auth);
  const [items, setItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [loader, setLoader] = useState(false);
  const [cost, setCost] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [usePoints, setUsePoints] = useState(false);
  const [furnitureDetails, setFurnitureDetails] = useState([]);
  const [updatedReferralPoints, setUpdatedReferralPoints] = useState(
    user?.referral_points
  );
  const [deliveryAddress, setDeliveryAddress] = useState({
    address: "",
    house_no: "",
    street: "",
    city: "",
    state: "",
    pinCode: "",
  });
  const [carryingCost, setCarryingCost] = useState([]);
  const [deliveryAddressModal, setDeliveryAddressModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [closeLoginModal, setCloseLoginModal] = useState(false);

  const [monthlyRent, setMonthlyRent] = useState(0);
  const [securityDeposit, setSecurityDeposit] = useState(0);

  //   useEffect(() => {
  //     checkCart();
  //     calcTotalAmount();
  //   }, [userData?.user]);

  //   useEffect(() => {
  //     calcTotalAmount();
  //   }, [items]);

  //   useEffect(()=>{
  //     (privateReq.get('/property/shortlist').then((res)=>{
  //         console.log(res);
  //     }).catch((err)=>{
  //         console.log(err);
  //     }))()
  //   },[])

  //   const onCreateBooking = async () => {
  //     try {
  //       // if (user === null) {
  //       //   setCloseLoginModal(true);
  //       //   return;
  //       // }
  //       if (furnitureDetails?.sellingOptions === "rent" && !bookingPeriod) {
  //         toast.error("Please select rental period", {
  //           position: "top-right",
  //         });
  //         return;
  //       }

  //       if (user === null) {
  //         let data = {
  //           _id: null,
  //           expired: false,
  //           periodNeeded: bookingPeriod,
  //           created: new Date(),
  //           user: null,
  //           furniture: furnitureDetails,
  //           image: furnitureDetails?.furnitureImages[0],
  //         };

  //         let cartData = localStorage.getItem("cartData");
  //         if (cartData) {
  //           let mainData = [...JSON.parse(cartData), data];
  //           localStorage.setItem("cartData", JSON.stringify(mainData));
  //         } else {
  //           localStorage.setItem("cartData", JSON.stringify([data]));
  //         }
  //         toast.success("Added to cart!");

  //         return;
  //       }

  //       const res = await addToCartService({
  //         furnitureId: furnitureDetails?._id,
  //         period: bookingPeriod,
  //       });
  //       if (res.data.status) {
  //         toast.success("Added to cart!");
  //         // setBookingPeriod("");
  //         // setHideBookingDialog(true);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  //   useEffect(() => {
  //     if(items.length== 0){
  //       setCost(0);
  //     }
  //     if (items.length > 0 && items.length <= 5) {

  //      setCost(carryingCost[`product${items.length}`])
  //     //  console.log(carryingCost[`product${items.length}`]);
  //     }
  //     else if(items.length >5){
  //       setCost(350);
  //     }

  //   }, [items]);

  const getItems = async () => {
    // console.log('hi there')
    privateReq("/property-list/shortlist")
      .then((res) => {
        // console.log('new resp' , res);
        setItems(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });

    localStorage.removeItem("cartData");
  };

  const handleRemove = (id) => {
    privateReq
      .delete(`/property-list/shortlist/${id}`)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success("deleted");
          getItems();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getItems();
      });
  };

  //   const handleRemove = async (id, index) => {
  //     if (!userData?.user) {
  //       const cartData = JSON.parse(localStorage.getItem("cartData")) || [];
  //       cartData.splice(index, 1);
  //       localStorage.setItem("cartData", JSON.stringify(cartData));
  //       setItems(cartData);
  //     } else {
  //       try {
  //        const data =  await removeFromWishlistService(`?furnitureId=${id}`);
  //         console.log(data,'hi')
  //         getItems();
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   };

  useEffect(() => {
    getItems();
    console.log("items are", items);
  }, []);

  return (
    <div>
      <Header />
      {items.length === 0 ? (
        <NotFound item={"Shortlisted items"} showText={true} />
      ) : (
        <div
          style={{
            width: "90%",
            margin: "100px auto 0 auto",
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontSize: "40px",
              color: "#9A291B",
            }}
          >
            SHORTLISTED PROPERTIES
          </div>
          <div
            style={{
              display: !isMobile && "flex",
              alignItems: "start",
              gap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                flex: "1",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "24px",
                }}
              >
                Items
              </div>
              {items?.map((it, index) => (
                <div
                  style={{
                    border: "1px solid lightgray",
                    padding: "10px",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: !isMobile && "flex",
                      justifyContent: "start",
                      gap: "10px",
                    }}
                  >
                    <img
                      style={{
                        width: isMobile ? "100%" : "200px",
                        borderRadius: "20px",
                        height: "150px",
                      }}
                      src={it?.property?.propertyImages[0]}
                    />
                    <div
                      style={{
                        marginLeft: !isMobile && "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "start",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "22px",
                          fontWeight: "700",
                        }}
                      >
                        {it?.property?.title}
                      </div>
                      <div
                        style={{
                          fontSize: "22px",
                          fontWeight: "700",
                        }}
                      >
                        {it?.property?.desc}
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: !isMobile && "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          gap: "30px",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginRight: "10px",
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  >
                    <ButtonContained
                      sx={[
                        {
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        },
                      ]}
                      onClick={() => handleRemove(it.propertyId)}
                    >
                      <FaTrash />
                      Remove
                    </ButtonContained>
                    <Link
                      style={{
                        textDecoration: "none",
                      }}
                      to={`/properties/${it.propertyId}`}
                    >
                      <ButtonContained
                        sx={[
                          {
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          },
                        ]}
                      >
                        Checkout property
                      </ButtonContained>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Shortlist;
