import React, { useState, useEffect } from "react";
import "./BlogForm.css";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { toast } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom"; // Import Link
import Header from "../Header.js";
import Footer from "../Footer.js";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";

function BlogForm({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [captchaToken, setCaptchaToken] = useState("");

  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  const uploadFile = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  function onChange(value) {
    // console.log("Captcha value:", value);
    setCaptchaToken(value);
  }

  const submitFiles = async (event) => {
    event.preventDefault();

    // Basic form validation
    if (
      !title ||
      !author ||
      !shortDescription ||
      !editorState ||
      !captchaToken
    ) {
      alert("Please fill in all fields");
      return;
    }

    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("author", author);
    formData.append("shortDescription", shortDescription);
    formData.append("blogContent", JSON.stringify(rawContentState));
    formData.append("file", image);
    formData.append("captchaToken", captchaToken);

    try {
      const response = await axios.post(
        "https://gharwala-5466fddd6458.herokuapp.com/api/upload/single",
        formData
      );
      const data = response.data;
      if (response.status === 201) {
        toast.success("blog uploaded");
      }
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  return showPage ? (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        style={{
          maxWidth: "1600px",
          width: "90%",
          marginTop: "100px",
          marginBottom: "40px",
        }}
        className="blog-form-container"
      >
        <h2>Create a New Blog Post</h2>
        <form onSubmit={submitFiles}>
          <div className="form-group">
            <label htmlFor="image">Image:</label>
            <input
              type="file"
              name="image"
              id="image"
              accept="image/*"
              onChange={uploadFile}
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="author">Author:</label>
            <input
              type="text"
              id="author"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="shortDescription">Short Description:</label>
            <textarea
              id="shortDescription"
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="mainContent">Main Content:</label>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              editorClassName="editor-wrapper"
            />
          </div>

          <ReCAPTCHA
            sitekey="6LdYvL8pAAAAANfeKw4Dy3zd2FlFrrLTWuZyVlP6"
            onChange={onChange}
          />

          <div style={{ marginTop: "20px" }}>
            <button type="submit">Submit</button>
            <Link to="/blogs" className="view-blogs-button">
              <button type="button">View Blogs</button>
            </Link>
          </div>
        </form>
      </div>

      <Footer />
    </>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
}

export default BlogForm;
