import React from "react";
import Owners from "../owners";

const Brokers = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  return (
    <Owners
      role="broker"
      closeLoginModal={closeLoginModal}
      setCloseLoginModal={setCloseLoginModal}
      setOpenPopUp={setOpenPopUp}
    />
  );
};

export default Brokers;
