import React, { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { toast } from "react-hot-toast";
import { privateReq } from "../../config/axiosConfig";
import styles from "../../styles/styles";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../redux/action/auth";
import { useNavigate } from "react-router-dom";

const customStyles = {
  Modal: {
    borderRadius: "25px",
    overlay: "hidden",
    maxWidth: "1300px",
  },
  customModal: {
    border: "none",
    maxWidth: "1300px",
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "#00000075",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 99999,
    left: 0,
  },
  content: {
    maxWidth: "500px",
    maxHeight: "fit-content",
    margin: "auto",
    borderRadius: "20px",
    padding: "30px",
    backgroundColor: "#fff",
    border: "none",
  },
};

const customStylesMobile = {
  ReactModal__Content: {
    border: "none",
    maxWidth: "1300px",
  },
  customModal: {
    border: "none",
    marginTop: "100px",
    maxWidth: "1300px",
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "#00000075",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
  },
  content: {
    maxWidth: "90%",
    border: "none",
    margin: "75px auto 0",
    borderRadius: "20px",
    padding: "20px",
    backgroundColor: "#fff",
  },
};

const inputStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    backgroundColor: "#f8f9fa",
    "&:hover fieldset": {
      borderColor: "#b90000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#b90000",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "15px",
  },
  marginBottom: "20px",
};

const buttonStyle = {
  backgroundColor: "#b90000",
  borderRadius: "12px",
  padding: "12px",
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#009507",
  },
};

const ModalPropertyVerification = ({ onClose, onVerificationComplete }) => {
  const [step, setStep] = useState("mobile");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMobileSubmit = async () => {
    if (!mobile || mobile.length !== 10) {
      toast.error("Please enter a valid 10-digit mobile number");
      return;
    }

    setLoading(true);
    try {
      const response = await privateReq.post("/check-user", { phone: mobile });
      if (response.data.exists) {
        await privateReq.post("/send-otp", { phone: mobile });
        setStep("otp");
      } else {
        setStep("userInfo");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleOtpVerification = async () => {
    if (!otp || otp.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP");
      return;
    }

    setLoading(true);
    try {
      const response = await privateReq.post("/verify-otp", {
        phone: mobile,
        otp: otp,
      });
      if (response.data.status) {
        localStorage.setItem("token", response.data.token);
        console.log("response.data", response.data.token);

        dispatch(
          setCurrentUser(response.data.token, () => {
            toast.success("Login Succesfully");
            let redirect = new URLSearchParams(window.location.search).get(
              "redirect"
            );
            if (redirect) {
              navigate(redirect, { replace: true });
            }
            // setIsLogin(true)
          })
        );

        if (window.innerWidth < 900) {
          window.location.href = "/";
        } else {
          onClose();
        }
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Invalid OTP");
    } finally {
      setLoading(false);
    }
  };

  const handleUserInfoSubmit = async () => {
    if (!userInfo.name.trim()) {
      toast.error("Name is mandatory. Please enter your name.");
      return;
    }
  
    // Validate email if it is provided
    if (userInfo.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userInfo.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
  
    setLoading(true);
    try {
      await privateReq.post("/guest-signup", {
        phone: mobile,
        name: userInfo.name,
        email: userInfo.email || undefined, // Send email only if provided
      });
  
      await privateReq.post("/send-otp", { phone: mobile });
      setStep("otp");
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  

  const getTitle = () => {
    switch (step) {
      case "mobile":
        return "Verify Your Mobile Number";
      case "otp":
        return "Enter OTP";
      case "userInfo":
        return "Complete Your Profile";
      default:
        return "";
    }
  };

  return (
    <Box sx={{ position: "relative", padding: "20px" }}>
      {step === "mobile" && (
        <Box>
          <Typography
            variant="body1"
            sx={{ mb: 2, color: "#666", textAlign: "center" }}
          >
            Enter your mobile number to view contact details
          </Typography>
          <TextField
            fullWidth
            placeholder="Enter Mobile Number"
            variant="outlined"
            value={mobile}
            onChange={(e) =>
              setMobile(e.target.value.replace(/\D/g, "").slice(0, 10))
            }
            sx={inputStyle}
            InputProps={{
              startAdornment: <PhoneIcon sx={{ color: "#666", mr: 1 }} />,
            }}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={handleMobileSubmit}
            disabled={loading}
            sx={buttonStyle}
          >
            Continue
          </Button>
        </Box>
      )}

      {step === "otp" && (
        <Box>
          <Typography
            variant="body1"
            sx={{ mb: 2, color: "#666", textAlign: "center" }}
          >
            Please enter the OTP sent to {mobile}
          </Typography>
          <TextField
            fullWidth
            placeholder="Enter OTP"
            variant="outlined"
            value={otp}
            onChange={(e) =>
              setOtp(e.target.value.replace(/\D/g, "").slice(0, 6))
            }
            sx={inputStyle}
            InputProps={{
              startAdornment: <LockIcon sx={{ color: "#666", mr: 1 }} />,
            }}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={handleOtpVerification}
            disabled={loading}
            sx={buttonStyle}
          >
            Verify OTP
          </Button>
        </Box>
      )}

      {step === "userInfo" && (
        <Box>
          <Typography
            variant="body1"
            sx={{ mb: 2, color: "#666", textAlign: "center" }}
          >
            Please provide the following information to continue
          </Typography>
          <TextField
            fullWidth
            placeholder="Enter Your Name"
            variant="outlined"
            value={userInfo.name}
            onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
            sx={inputStyle}
            InputProps={{
              startAdornment: (
                <PersonOutlineIcon sx={{ color: "#666", mr: 1 }} />
              ),
            }}
          />
          <TextField
            fullWidth
            type="email"
            placeholder="Enter Your Email"
            variant="outlined"
            value={userInfo.email}
            onChange={(e) =>
              setUserInfo({ ...userInfo, email: e.target.value })
            }
            sx={inputStyle}
            InputProps={{
              startAdornment: <MailOutlineIcon sx={{ color: "#666", mr: 1 }} />,
            }}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={handleUserInfoSubmit}
            disabled={loading}
            sx={buttonStyle}
          >
            Continue
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ModalPropertyVerification;
