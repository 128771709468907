import React from 'react';
import Container1 from '../../assets/images/Container1.png';
import Container2 from '../../assets/images/container2.png';
import Container3 from '../../assets/images/Container3.png';
import Container4 from '../../assets/images/Container4.png';
import Container5 from '../../assets/images/Container5.png';
import Box3 from '../../assets/images/box3.png';

const Cities = () => {
  const properties = [
    {
      title: 'NEW YORK',
      location: '300 Properties',
      imgSrc: Container1,
    },
    {
      title: 'Thailand',
      location: '200 Properties',
      imgSrc: Container2,
    },
    {
      title: 'United Kingdom',
      location: '1450 Properties',
      imgSrc: Box3,
    },
    {
      title: 'Singapore',
      location: '400 Properties',
      imgSrc: Container3,
    },
    {
      title: 'Argentina',
      location: '740 Properties',
      imgSrc: Container4,
    },
    {
      title: 'United Arab Emirates',
      location: '100 Properties',
      imgSrc: Container5,
    },
  ];

  return (<></>
    // <section style={styles.container} className="cities-section">
    //   <div style={styles.header}>
    //     <h2 style={styles.title}>Cities With Listing</h2>
    //     <p style={styles.subtitle}>Destinations we love the most</p>
    //     <hr style={styles.separator} />
    //   </div>

    //   <div style={styles.grid}>
    //     {properties.map((property, index) => (
    //       <div key={index} style={styles.card}>
    //         <div style={styles.imageContainer}>
    //           <img
    //             src={property.imgSrc}
    //             alt={property.title}
    //             style={styles.image}
    //           />
    //           <div style={styles.overlay}>
    //             <p style={styles.propertyTitle}>{property.title}</p>
    //             <p style={styles.location}>{property.location}</p>
    //           </div>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </section>
  );
};

// Internal CSS styles object
const styles = {
  container: {
    padding: '100px',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    color: '#1e2a78',
    fontWeight: 'bold',
  },
  subtitle: {
    color: '#7d8597',
    fontSize: '16px',
  },
  separator: {
    borderBottom: '2px solid #B90000',
    width: '26px',
    margin: '0 auto',
    marginTop: '8px',
    borderRadius: '10px',
    height: '10px',
    backgroundColor: '#B90000',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '20px',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
    height: '300px', // Set a specific height for the card
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%', // Make the image container take up the full card
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Ensure the image covers the entire card
  },
  overlay: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    color: 'white',
    padding: '10px',
    textAlign: 'left',
  },
  propertyTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  location: {
    fontSize: '14px',
  },
};

// Add this CSS to your main CSS file or in a <style> tag in your component
const stylesForMobile = `
  @media (max-width: 768px) {
    .cities-section {
      display: none;
    }
  }
`;

// Injecting the mobile styles into the head of the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = stylesForMobile;
document.head.appendChild(styleSheet);

export default Cities;

