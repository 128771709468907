import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ButtonContained from "../Buttons/ButtonContained";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import StarIcon from "@mui/icons-material/Star";
import { useMediaQuery } from "react-responsive";
import { Box, Typography, Grid, Rating } from "@mui/material";
import classes from "../../styles/styles.js";
import styles from "../../styles/styles.js";
import BedSvg from "../../assets/images/bed.svg";
import FloorSvg from "../../assets/images/floor.svg";
import AreaSvg from "../../assets/images/area.svg";
import { getAllRatingsListService } from "../../config/property";
import BathSvg from "../../assets/images/bed.svg";
import { assetUrl } from "../../config/axiosConfig";
export default function Ratings({ role = "broker" }) {
  const dispatch = useDispatch();

  const { properties } = useSelector((state) => state.property);
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [ratings, setRatings] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    onGetAllRatings();
  }, []);
  const onGetAllRatings = async () => {
    const filterQuery = "";
    let res = await getAllRatingsListService(filterQuery);
    if (res.data) {
      setRatings(res.data);
      setLoader(false);
    }
  };
  return (
    <>
      {/* <Header /> */}
      <Grid
        container
        spacing={6}
        sx={classes.gridContainer}
        columns={{ xs: 12, sm: 8, md: 12, lg: 12 }}
      >
        {/* <div className='row'> */}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {ratings &&
            ratings?.map((card, index) => (
              <Card
                sx={[
                  !isMobile ? styles.dflex : styles.dBlock,
                  styles.jcSBetween,
                  styles.borderRadius12,
                  styles.alignItemCenter,
                  { marginBottom: "15px", borderWidth: 1, borderColor: "red" },
                ]}
                key={index}
              >
                <Box sx={[styles.dBlock, { width: "50%" }]}>
                  <Box
                    sx={[
                      styles.borderColorADB0B6,
                      styles.borderRadius12,
                      { borderWidth: 1, borderColor: "red" },
                    ]}
                  >
                    <CardContent
                      sx={[
                        styles.borderColorADB0B6,
                        styles.borderRadius12,
                        {
                          display: "flex",
                          flexDirection: "row",
                          borderWidth: 1,
                          borderColor: "red",
                        },
                      ]}
                    >
                      <Box>
                        <CardMedia
                          component="img"
                          sx={{ width: 151 }}
                          src={assetUrl + card?.property.propertyImages[0]}
                          style={{
                            width: "85px",
                            height: "85px",
                            borderRadius: "5px",
                            margin: "10px",
                          }}
                          alt="Live from space album cover"
                        />
                      </Box>
                      <Box>
                        <Typography
                          component="div"
                          variant="h5"
                          sx={[
                            styles.color240501,
                            styles.fontWeight700,
                            styles.fontFamilySGB,
                            styles.fs15,
                          ]}
                        >
                          ₹ {card.property.expectedMonthlyRent}/month
                        </Typography>
                        <Typography>
                          <div className="propety-icons d-flex">
                            <div className="property-icon">
                              <img src={BedSvg} />
                              <span className="text-color979797 property-icon-text">
                                {card.property.bedrooms}
                              </span>
                            </div>
                            {card.property.bathrooms && (
                              <div className="property-icon">
                                <img src={BathSvg} />
                                <span className="property-icon-text text-color979797">
                                  {card.property.bathrooms}
                                </span>
                              </div>
                            )}
                          </div>
                        </Typography>
                      </Box>
                    </CardContent>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* <div className="property-slider-buttons px-2"> */}

                    <ButtonContained
                      onClick={() =>
                        navigate(
                          `/properties/${
                            card?.property?.route_title || card.property._id
                          }`
                        )
                      }
                      sx={[
                        styles.fontFamilySGB,
                        styles.p10,
                        styles.borderRadius12,
                      ]}
                      className="m-0 mx-1 my-2"
                    >
                      View
                    </ButtonContained>

                    {/* </Link> */}
                    {/* </div> */}
                  </Box>
                </Box>
                <Box
                  sx={[
                    classes.dflex,
                    classes.mt22,
                    { width: "100%", marginLeft: "5%" },
                  ]}
                >
                  <Box
                    sx={[
                      styles.dBlock,
                      {
                        width: "100%",
                      },
                    ]}
                  >
                    <>
                      <Box sx={[styles.dflex, { width: "100%" }]}>
                        <Box>
                          <CardMedia
                            component="img"
                            sx={{ width: "72px", height: "72px" }}
                            // alt={users?.users?.name || ""}
                            // src={assetUrl + users?.avatarImage}
                            src={assetUrl + user.avatarImage}

                            // alt="Live from space album cover"
                          />
                        </Box>
                        <Box className="px-2">
                          <Typography
                            sx={[
                              styles.fontFamilyDS,
                              styles.fbBold,
                              styles.fs20,
                              styles.color240501,
                              styles.fbBold,
                            ]}
                          >
                            {/* Rahul */}
                            {user?.name}
                          </Typography>
                          <Rating
                            value={parseInt(card.rating)}
                            sx={{
                              color: "#9A291B",
                            }}
                            readOnly
                            size="small"
                          />
                        </Box>
                      </Box>
                      <Box className="w-100">
                        <Typography
                          sx={[
                            styles.color979797,
                            styles.fs16,
                            styles.fontFamilyDS,
                          ]}
                        >
                          {card.desc}
                        </Typography>
                      </Box>
                    </>
                  </Box>
                </Box>
              </Card>
            ))}
        </Grid>
      </Grid>
      {/* </div > */}
    </>
  );
}
