import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

const FormBasicDetails = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const navigate = useNavigate();

  const [
    role,
    setRole,
    ownerData,
    setOwnerData,
    tenantData,
    setTenantData,
    propertyDetails,
    setPropertyDetails,
    contractDetails,
    setContractDetails,
    furnitureId,
    setFurnitureId,
    furnishedDetails,
    setFurnishedDetails,
  ] = useOutletContext();

  const handleOwnerChange = (e) => {
    const { name, value } = e.target;
    setOwnerData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTenantChange = (e, index) => {
    const { name, value } = e.target;
    setTenantData((prev) => {
      const updatedTenants = [...prev];
      updatedTenants[index] = {
        ...updatedTenants[index],
        [name]: value,
      };
      return updatedTenants;
    });
  };

  const addTenant = () => {
    setTenantData((prev) => [
      ...prev,
      {
        initial: "Mr",
        name: null,
        relation: "S/O",
        parentName: null,
        countryCode: "+91",
        number: null,
        chooseDoc: "Adhar No.",
        docNumber: null,
        email: null,
        faith: null,
        profession: null,
        address: null,
        pincode: null,
        state: null,
        city: null,
      },
    ]);
  };

  return (
    <>
      <div className="header">Basic Details</div>
      <div>You are renting ?</div>
      <div className="form-button-container">
        <button
          className={`form-button ${
            propertyDetails.type === "property" && "form-button_active"
          }`}
          onClick={() =>
            setPropertyDetails((prev) => ({
              ...prev,
              type: "property",
            }))
          }
        >
          Property
        </button>
      </div>
      <div className="role-form">
        <div className="sub-header">You are:</div>
        <div className="form-button-container">
          {propertyDetails.type !== "furniture" && (
            <button
              className={`form-button ${
                role === "owner" && "form-button_active"
              }`}
              onClick={() => setRole("owner")}
            >
              Owner
            </button>
          )}
          <button
            className={`form-button ${
              role === "tenant" && "form-button_active"
            }`}
            onClick={() => setRole("tenant")}
          >
            Tenant
          </button>
        </div>
      </div>
      {role === "owner" && (
        <form className="form">
          <div className="field-container">
            <select
              name="initial"
              value={ownerData.initial}
              onChange={handleOwnerChange}
            >
              <option>Mr.</option>
              <option>Miss</option>
              <option>Smt</option>
              <option>Shri</option>
            </select>
            <input
              type="text"
              name="name"
              placeholder="Owner's Name"
              value={ownerData.name}
              onChange={handleOwnerChange}
            />
          </div>
          <div className="field-container">
            <select
              name="relation"
              value={ownerData.relation}
              onChange={handleOwnerChange}
            >
              <option>S/O</option>
              <option>D/O</option>
            </select>
            <input
              type="text"
              name="parentName"
              placeholder="Owner's Father/Mother Name"
              value={ownerData.parentName}
              onChange={handleOwnerChange}
            />
          </div>

          <div className="field-container">
            <select
              name="chooseDoc"
              value={ownerData.chooseDoc}
              onChange={handleOwnerChange}
            >
              <option>Aadhar No.</option>
              <option>Pan No.</option>
              <option>Driving Licence No.</option>
            </select>
            <input
              maxlength="50"
              type="text"
              name="docNumber"
              placeholder="document Number"
              value={ownerData.docNumber}
              onChange={handleOwnerChange}
            />
          </div>

          <div className="field-container">
            <select
              name="countryCode"
              value={ownerData.countryCode}
              onChange={handleOwnerChange}
            >
              <option>+91</option>
            </select>
            <input
              maxlength="10"
              type="text"
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleOwnerChange(e);
              }}
              name="number"
              placeholder="Mobile Number"
              value={ownerData.number}
            />
          </div>

          <div className="field-container">
            <input
              type="text"
              name="email"
              placeholder="Email ID"
              value={ownerData.email}
              onChange={handleOwnerChange}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="faith"
              placeholder="Religion"
              value={ownerData.faith}
              onChange={handleOwnerChange}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="profession"
              placeholder="Profession"
              value={ownerData.profession}
              onChange={handleOwnerChange}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="address"
              placeholder="Permanent Address"
              value={ownerData.address}
              onChange={handleOwnerChange}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              value={ownerData.pincode}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleOwnerChange(e);
              }}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="city"
              placeholder="City"
              value={ownerData.city}
              onChange={handleOwnerChange}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="state"
              placeholder="State"
              value={ownerData.state}
              onChange={handleOwnerChange}
            />
          </div>
        </form>
      )}
      {role === "tenant" && (
        <form className="form">
          <div className="field-container">
            <select
              name="initial"
              value={tenantData[0].initial}
              onChange={(e) => handleTenantChange(e, 0)}
            >
              <option>Mr.</option>
              <option>Miss</option>
              <option>Smt</option>
              <option>Shri</option>
            </select>
            <input
              type="text"
              name="name"
              placeholder="Tenant's Name"
              value={tenantData[0].name}
              onChange={(e) => handleTenantChange(e, 0)}
            />
          </div>

          <div className="field-container">
            <select
              name="relation"
              value={tenantData[0].relation}
              onChange={(e) => handleTenantChange(e, 0)}
            >
              <option>S/O</option>
              <option>D/O</option>
            </select>
            <input
              type="text"
              name="parentName"
              placeholder="Tenant's Father/Mother Name"
              value={tenantData[0].parentName}
              onChange={(e) => handleTenantChange(e, 0)}
            />
          </div>

          <div className="field-container">
            <select
              name="chooseDoc"
              value={tenantData[0].chooseDoc}
              onChange={(e) => handleTenantChange(e, 0)}
            >
              <option>Adhar No.</option>
              <option>Pan No.</option>
              <option>Driving Licence No.</option>
            </select>
            <input
              maxlength="50"
              type="text"
              name="docNumber"
              placeholder="document Number"
              value={tenantData[0].docNumber}
              onChange={(e) => handleTenantChange(e, 0)}
            />
          </div>

          <div className="field-container">
            <select
              name="countryCode"
              value={tenantData[0].countryCode}
              onChange={handleTenantChange}
            >
              <option>+91</option>
            </select>
            <input
              type="text"
              maxlength="10"
              name="number"
              placeholder="Mobile Number"
              value={tenantData[0].number}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleTenantChange(e, 0);
              }}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              placeholder="Email ID"
              name="email"
              value={tenantData[0].email}
              onChange={(e) => handleTenantChange(e, 0)}
            />
          </div>

          <div className="field-container">
            <input
              type="text"
              name="faith"
              placeholder="Religion"
              value={tenantData[0].faith}
              onChange={(e) => handleTenantChange(e, 0)}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="profession"
              placeholder="occupation"
              value={tenantData[0].profession}
              onChange={(e) => handleTenantChange(e, 0)}
            />
          </div>

          <div className="field-container">
            <input
              type="text"
              name="address"
              placeholder="Permanent Address"
              value={tenantData[0].address}
              onChange={(e) => handleTenantChange(e, 0)}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              value={tenantData[0].pincode}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleTenantChange(e, 0);
              }}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              placeholder="City"
              name="city"
              value={tenantData[0].city}
              onChange={(e) => handleTenantChange(e, 0)}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="state"
              placeholder="State"
              value={tenantData[0].state}
              onChange={(e) => handleTenantChange(e, 0)}
            />
          </div>
        </form>
      )}
      {role === "tenant" && tenantData.length >= 2 && (
        <form className="form">
          <div className="field-container">
            <select
              name="initial"
              value={tenantData[1].initial}
              onChange={(e) => handleTenantChange(e, 1)}
            >
              <option>Mr.</option>
              <option>Miss</option>
              <option>Smt</option>
              <option>Shri</option>
            </select>
            <input
              type="text"
              name="name"
              placeholder="Tenant's Name"
              value={tenantData[1].name}
              onChange={(e) => handleTenantChange(e, 1)}
            />
          </div>

          <div className="field-container">
            <select
              name="relation"
              value={tenantData[1].relation}
              onChange={(e) => handleTenantChange(e, 1)}
            >
              <option>S/O</option>
              <option>D/O</option>
            </select>
            <input
              type="text"
              name="parentName"
              placeholder="Tenant's Father/Mother Name"
              value={tenantData[1].parentName}
              onChange={(e) => handleTenantChange(e, 1)}
            />
          </div>

          <div className="field-container">
            <select
              name="chooseDoc"
              value={tenantData[1].chooseDoc}
              onChange={(e) => handleTenantChange(e, 1)}
            >
              <option>Adhar No.</option>
              <option>Pan No.</option>
              <option>Driving Licence No.</option>
            </select>
            <input
              maxlength="50"
              type="text"
              name="docNumber"
              placeholder="document Number"
              value={tenantData[1].docNumber}
              onChange={(e) => handleTenantChange(e, 1)}
            />
          </div>

          <div className="field-container">
            <select
              name="countryCode"
              value={tenantData[1].countryCode}
              onChange={(e) => handleTenantChange(e, 1)}
            >
              <option>+91</option>
            </select>
            <input
              type="text"
              name="number"
              maxlength="10"
              placeholder="Mobile Number"
              value={tenantData[1].number}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleTenantChange(e, 1);
              }}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              placeholder="Email ID"
              name="email"
              value={tenantData[1].email}
              onChange={(e) => handleTenantChange(e, 1)}
            />
          </div>

          <div className="field-container">
            <input
              type="text"
              name="faith"
              placeholder="Religion"
              value={tenantData[1].faith}
              onChange={(e) => handleTenantChange(e, 1)}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="profession"
              placeholder="occupation"
              value={tenantData[1].profession}
              onChange={(e) => handleTenantChange(e, 1)}
            />
          </div>

          <div className="field-container">
            <input
              type="text"
              name="address"
              placeholder="Permanent Address"
              value={tenantData[1].address}
              onChange={(e) => handleTenantChange(e, 1)}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              value={tenantData[1].pincode}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleTenantChange(e, 1);
              }}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              placeholder="City"
              name="city"
              value={tenantData[1].city}
              onChange={(e) => handleTenantChange(e, 1)}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="state"
              placeholder="State"
              value={tenantData[1].state}
              onChange={(e) => handleTenantChange(e, 1)}
            />
          </div>
          <button
            className="remove-tenant-button"
            type="button"
            onClick={() => {
              setTenantData((prevData) =>
                prevData.filter((_, index) => index !== 1)
              );
            }}
          >
            Remove Tenant
          </button>
        </form>
      )}
      {role === "tenant" && tenantData.length >= 3 && (
        <form className="form">
          <div className="field-container">
            <select
              name="initial"
              value={tenantData[2].initial}
              onChange={(e) => handleTenantChange(e, 2)}
            >
              <option>Mr.</option>
              <option>Miss</option>
              <option>Smt</option>
              <option>Shri</option>
            </select>
            <input
              type="text"
              name="name"
              placeholder="Tenant's Name"
              value={tenantData[2].name}
              onChange={(e) => handleTenantChange(e, 2)}
            />
          </div>

          <div className="field-container">
            <select
              name="relation"
              value={tenantData[2].relation}
              onChange={(e) => handleTenantChange(e, 2)}
            >
              <option>S/O</option>
              <option>D/O</option>
            </select>
            <input
              type="text"
              name="parentName"
              placeholder="Tenant's Father/Mother Name"
              value={tenantData[2].parentName}
              onChange={(e) => handleTenantChange(e, 2)}
            />
          </div>

          <div className="field-container">
            <select
              name="chooseDoc"
              value={tenantData[2].chooseDoc}
              onChange={(e) => handleTenantChange(e, 2)}
            >
              <option>Adhar No.</option>
              <option>Pan No.</option>
              <option>Driving Licence No.</option>
            </select>
            <input
              maxlength="50"
              type="text"
              name="docNumber"
              placeholder="document Number"
              value={tenantData[2].docNumber}
              onChange={(e) => handleTenantChange(e, 2)}
            />
          </div>

          <div className="field-container">
            <select
              name="countryCode"
              value={tenantData[2].countryCode}
              onChange={(e) => handleTenantChange(e, 2)}
            >
              <option>+91</option>
            </select>
            <input
              type="text"
              name="number"
              maxlength="10"
              placeholder="Mobile Number"
              value={tenantData[2].number}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleTenantChange(e, 2);
              }}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              placeholder="Email ID"
              name="email"
              value={tenantData[2].email}
              onChange={(e) => handleTenantChange(e, 2)}
            />
          </div>

          <div className="field-container">
            <input
              type="text"
              name="faith"
              placeholder="Religion"
              value={tenantData[2].faith}
              onChange={(e) => handleTenantChange(e, 2)}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="profession"
              placeholder="occupation"
              value={tenantData[2].profession}
              onChange={(e) => handleTenantChange(e, 2)}
            />
          </div>

          <div className="field-container">
            <input
              type="text"
              name="address"
              placeholder="Permanent Address"
              value={tenantData[2].address}
              onChange={(e) => handleTenantChange(e, 2)}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              value={tenantData[2].pincode}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleTenantChange(e, 2);
              }}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              placeholder="City"
              name="city"
              value={tenantData[2].city}
              onChange={(e) => handleTenantChange(e, 2)}
            />
          </div>
          <div className="field-container">
            <input
              type="text"
              name="state"
              placeholder="State"
              value={tenantData[2].state}
              onChange={(e) => handleTenantChange(e, 2)}
            />
          </div>
          <button
            className="remove-tenant-button"
            type="button"
            onClick={() => {
              setTenantData((prevData) =>
                prevData.filter((_, index) => index !== 1)
              );
            }}
          >
            Remove Tenant
          </button>
        </form>
      )}
      {role === "tenant" && (
        <button
          className="add-tenant"
          onClick={addTenant}
          disabled={tenantData.length === 3}
        >
          Add tenant
        </button>
      )}
    </>
  );
};

export default FormBasicDetails;
