import React, { useEffect, useState } from "react";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import Carousel from "react-simply-carousel";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { Box, Typography, Button } from "@mui/material";
import { TestimonialsImage } from "../../assets/images/testimonials.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const Testimonials = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [data, setData] = useState([]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 2000, // Time in milliseconds between slides (3 seconds)
  };

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          "https://gharwala-5466fddd6458.herokuapp.com/api/get-all-testimonials"
        );
        setData(response.data.testimonials);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };
    fetchTestimonials();
  }, []);

  return (
    <Box
      sx={{
        padding: isMobile ? "50px 0" : "50px 100px",
        backgroundImage: "../../assets/images/testimonials.svg",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#00436A",
      }}
    >
      <Typography
        variant={isMobile ? "h6" : "h4"}
        sx={{
          fontFamily: "Montserrat",
          textAlign: "center",
          marginBottom: "20px",
          color: "white",
          fontWeight: "bold",
        }}
      >
        Our Happy Customers
      </Typography>
      <Box
        sx={{
          // display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          position: "relative",
          maxWidth:"880px",
          margin:"auto"
        }}

        
      >


<Slider {...settings}>
        {data.map((item, index) =>
          item.pincode ? null : (
            <Box
              key={index}
              sx={{
                border: "none",
                width: "100%",
                display: "flex",
                alignItems: "center",
                overflowY: "scroll",
              }}
            >
              <Box
                sx={{
                  width: isMobile ? "300px" : "800px",
                  padding: "30px",
                  border: "1px solid #9A291B",
                  borderRadius: "15px",
                  position: "relative",
                  margin: "20px",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  backgroundColor: "white", // Add transparency
                }}
              >
                <ImQuotesLeft
                  style={{
                    color: "#9A291B",
                    height: "30px",
                    width: "30px",
                    position: "absolute",
                    left: "20px",
                    top: "20px",
                  }}
                />
                <Box sx={{ marginTop: "10px" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    {/* {item.title} */}
                  </Typography>
                  <Typography>{item.description}</Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: isMobile ? "16px" : "22px",
                    marginTop: "20px",
                    fontWeight: "600",
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "22px",
                    marginTop: "20px",
                    fontWeight: "600",
                  }}
                >
                  {item.pincode}
                </Typography>
                <ImQuotesRight
                  style={{
                    color: "#9A291B",
                    height: "30px",
                    width: "30px",
                    position: "absolute",
                    right: "20px",
                    bottom: "60px",
                  }}
                />
              </Box>
            </Box>
          )
        )}
      </Slider>
      </Box>
    </Box>
  );
};

export default Testimonials;
