import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigation, useParams } from "react-router-dom";
import { privateReq } from "../../config/axiosConfig";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";

const validationSchema = Yup.object().shape({
  expectedSecurityDeposit: Yup.number().typeError("Please enter a number"),
  buildupArea: Yup.number().typeError("Please enter a number"),
  expectedMonthlyRent: Yup.number().typeError(
    "expectedMonthlyRent must be a number"
  ),
  expectedMonthlyDeposit: Yup.number().typeError(
    "expectedMonthlyRent must be a number"
  ),
});

const UpdateProperty = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [propertyData, setPropertyData] = useState(null);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await privateReq.get(`/property/${id}`);
        console.log(response.data, "hi");
        setPropertyData(response.data.post);
      } catch (error) {
        console.error("Error fetching property data:", error);
      }
    };

    fetchProperty();
  }, [id]);

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    const uploadedImages = [];

    try {
      await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);

          const response = await axios.post(
            `https://gharwala-5466fddd6458.herokuapp.com/api/upload-image`,
            formData
          );
          if (response.data) {
            uploadedImages.push(response.data);
            toast.success("Image uploaded successfully");
          } else {
            toast.error("Failed to upload image");
          }
        })
      );

      setPropertyData((prev) => ({
        ...prev,
        propertyImages: [...prev.propertyImages, ...uploadedImages].slice(
          0,
          10
        ), // Limit to 10 images
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image");
    }
  };
  useEffect(() => {
    if (propertyData) {
      // Initialize formik after propertyData is fetched
      formik.setValues({
        propertyCondition: propertyData.propertyCondition || "",
        propertyKind: propertyData.propertyKind || "",
        propertyType: propertyData.propertyType || "",
        propertyImages: propertyData?.propertyImages || "",
        city: propertyData.city || "",
        mobile: propertyData.mobile || "",
        facilities: propertyData.facilities || "",
        title: propertyData.title || "",
        sqyrd: propertyData.sqyrd || "",
        sqmtr: propertyData.sqmtr || "",
        youTubeUrl: propertyData.youTubeUrl || "",
        desc: propertyData.desc || "",
        active: propertyData.active || "",
        listingStatus: propertyData.listingStatus || "",
        createdBy: propertyData.createdBy || "",
        expectedMonthlyRent: propertyData.expectedMonthlyRent || "",

        expectedSecurityDeposit: propertyData.expectedSecurityDeposit || "",
        buildupArea: propertyData.buildupArea || "",
        availableFrom: propertyData.availableFrom || "",
        tenant: propertyData.tenant || "",
        floorNumber: propertyData.floorNumber || "",
        propertyAge: propertyData.propertyAge || "",
        parking: propertyData.parking || "",
        facing: propertyData.facing || "",
        flooring: propertyData.flooring || "",
        address: propertyData.address || "",
        landmark: propertyData.landmark || "",
        water: propertyData.water || "",
        bathroom: propertyData.bathroom || "",
        powerBackup: propertyData.powerBackup || "",
        overlooking: propertyData.overlooking || "",
        amenities: propertyData.amenities || "",
      });
    }
  }, [propertyData]);

  const formik = useFormik({
    initialValues: {
      propertyCondition: propertyData?.propertyCondition || "",
      propertyKind: propertyData?.propertyKind || "",
      propertyType: propertyData?.propertyType || "",
      city: propertyData?.city || "",
      mobile: propertyData?.mobile || "",
      facilities: propertyData?.facilities || "",
      title: propertyData?.title || "",
      sqyrd: propertyData?.sqyrd || "",
      sqmtr: propertyData?.sqmtr || "",
      youTubeUrl: propertyData?.youTubeUrl || "",
      desc: propertyData?.desc || "",
      active: propertyData?.active || "",
      listingStatus: propertyData?.listingStatus || "",
      createdBy: propertyData?.createdBy || "",
      expectedMonthlyRent: propertyData?.expectedMonthlyRent || "",

      expectedSecurityDeposit: propertyData?.expectedSecurityDeposit || "",
      buildupArea: propertyData?.buildupArea || "",
      availableFrom: propertyData?.availableFrom || "",
      tenant: propertyData?.tenant || "",
      floorNumber: propertyData?.floorNumber || "",
      propertyAge: propertyData?.propertyAge || "",
      parking: propertyData?.parking || "",
      facing: propertyData?.facing || "",
      flooring: propertyData?.flooring || "",
      address: propertyData?.address || "",
      landmark: propertyData?.landmark || "",
      water: propertyData?.water || "",
      bathroom: propertyData?.bathroom || "",
      powerBackup: propertyData?.powerBackup || "",
      overlooking: propertyData?.overlooking || "",
      propertyImages: propertyData?.propertyImages || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        console.log("values", values);
        // console.log(values)
        const response = await privateReq.put(`/property/update/${id}`, values);

        toast.success("Edited property successfully");
        navigate("/properties");

        setMessage("Property updated successfully!");
      } catch (error) {
        console.error("Error updating property:", error);
        setMessage("Failed to update property");
      }
    },
  });
  const removeImage = (index) => {
    setPropertyData((prev) => ({
      ...prev,
      propertyImages: prev.propertyImages.filter((_, i) => i !== index),
    }));
  };

  if (!propertyData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div style={{ marginBottom: "100px" }}>
        <Header
          closeLoginModal={closeLoginModal}
          setCloseLoginModal={setCloseLoginModal}
          setOpenPopUp={setOpenPopUp}
        />
      </div>
      <div style={styles.container}>
        <h2>Update Property</h2>
        <form onSubmit={formik.handleSubmit} style={styles.form}>
          <label>Property Condition</label>
          <input
            type="text"
            name="propertyCondition"
            value={formik.values.propertyCondition}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.propertyCondition &&
            formik.errors.propertyCondition && (
              <div style={styles.error}>{formik.errors.propertyCondition}</div>
            )}

          <label>Property Kind</label>
          <input
            type="text"
            name="propertyKind"
            value={formik.values.propertyKind}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.propertyKind && formik.errors.propertyKind && (
            <div style={styles.error}>{formik.errors.propertyKind}</div>
          )}

          <label>Property Type</label>
          <input
            type="text"
            name="propertyType"
            value={formik.values.propertyType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.propertyType && formik.errors.propertyType && (
            <div style={styles.error}>{formik.errors.propertyType}</div>
          )}

          <label>City</label>
          <input
            type="text"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.city && formik.errors.city && (
            <div style={styles.error}>{formik.errors.city}</div>
          )}

          <label>Facilities</label>
          <textarea
            type="text"
            name="facilities"
            value={formik.values.facilities}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.facilities && formik.errors.facilities && (
            <div style={styles.error}>{formik.errors.facilities}</div>
          )}

          <label>Title</label>
          <input
            type="text"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.title && formik.errors.title && (
            <div style={styles.error}>{formik.errors.title}</div>
          )}

          {/* <label>Sq Mtr</label>
        <input
          type="text"
          name="sqmtr"
          value={formik.values.sqmtr}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          style={styles.input}
        />
        {formik.touched.sqmtr && formik.errors.sqmtr && (
          <div style={styles.error}>{formik.errors.sqmtr}</div>
        )} */}

          {/* <label>YouTube URL</label>
        <input
          type="text"
          name="youTubeUrl"
          value={formik.values.youTubeUrl}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          style={styles.input}
        />
        {formik.touched.youTubeUrl && formik.errors.youTubeUrl && (
          <div style={styles.error}>{formik.errors.youTubeUrl}</div>
        )} */}

          <label>Description</label>
          <textarea
            type="text"
            name="desc"
            value={formik.values.desc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.desc && formik.errors.desc && (
            <div style={styles.error}>{formik.errors.desc}</div>
          )}

          {/* <label>Active</label>
        <div style={styles.radioGroup}>
          <label>
            <input
              type="radio"
              name="active"
              value={true}
              checked={formik.values.active == true}
              onChange={() => formik.setFieldValue("active", true)}
            />
            Active
          </label>
          <label>
            <input
              type="radio"
              name="active"
              value={false}
              checked={formik.values.active == false}
              onChange={() => formik.setFieldValue("active", false)}
            />
            Disabled
          </label>
        </div> */}
          {formik.touched.active && formik.errors.active && (
            <div style={styles.error}>{formik.errors.active}</div>
          )}

          {/* <label>Listing Status</label>
        <div style={styles.radioGroup}>
          <label>
            <input
              type="radio"
              name="listingStatus"
              value={true}
              checked={formik.values.listingStatus === true}
              onChange={() => formik.setFieldValue('listingStatus', true)}
            />
            Active
          </label>
          <label>
            <input
              type="radio"
              name="listingStatus"
              value={false}
              checked={formik.values.listingStatus === false}
              onChange={() => formik.setFieldValue('listingStatus', false)}
            />
            Disabled
          </label>
        </div>
        {formik.touched.listingStatus && formik.errors.listingStatus && (
          <div style={styles.error}>{formik.errors.listingStatus}</div>
        )} */}
          <label>Created By</label>
          <input
            type="text"
            name="createdBy"
            value={formik.values.createdBy}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.createdBy && formik.errors.createdBy && (
            <div style={styles.error}>{formik.errors.createdBy}</div>
          )}

          <label>expectedMonthlyRent</label>
          <input
            type="text"
            name="expectedMonthlyRent"
            value={formik.values.expectedMonthlyRent}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.expectedMonthlyRent &&
            formik.errors.expectedMonthlyRent && (
              <div style={styles.error}>
                {formik.errors.expectedMonthlyRent}
              </div>
            )}

          <label>Security Deposit</label>
          <input
            type="text"
            name="expectedSecurityDeposit"
            value={formik.values.expectedSecurityDeposit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.expectedSecurityDeposit &&
            formik.errors.expectedSecurityDeposit && (
              <div style={styles.error}>
                {formik.errors.expectedSecurityDeposit}
              </div>
            )}

          <label>Built-up Area</label>
          <input
            type="text"
            name="buildupArea"
            value={formik.values.buildupArea}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.buildupArea && formik.errors.buildupArea && (
            <div style={styles.error}>{formik.errors.buildupArea}</div>
          )}

          <label>Available From</label>
          <input
            type="text"
            name="availableFrom"
            value={formik.values.availableFrom}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.availableFrom && formik.errors.availableFrom && (
            <div style={styles.error}>{formik.errors.availableFrom}</div>
          )}

          <label>Tenant</label>
          <input
            type="text"
            name="tenant"
            value={formik.values.tenant}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.tenant && formik.errors.tenant && (
            <div style={styles.error}>{formik.errors.tenant}</div>
          )}

          <label>Floor Number</label>
          <input
            type="text"
            name="floorNumber"
            value={formik.values.floorNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.floorNumber && formik.errors.floorNumber && (
            <div style={styles.error}>{formik.errors.floorNumber}</div>
          )}

          <label>Property Age</label>
          <input
            type="text"
            name="propertyAge"
            value={formik.values.propertyAge}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.propertyAge && formik.errors.propertyAge && (
            <div style={styles.error}>{formik.errors.propertyAge}</div>
          )}

          <label>Parking</label>
          <input
            type="text"
            name="parking"
            value={formik.values.parking}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.parking && formik.errors.parking && (
            <div style={styles.error}>{formik.errors.parking}</div>
          )}

          <label>Facing</label>
          <input
            type="text"
            name="facing"
            value={formik.values.facing}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.facing && formik.errors.facing && (
            <div style={styles.error}>{formik.errors.facing}</div>
          )}

          <label>Flooring</label>
          <input
            type="text"
            name="flooring"
            value={formik.values.flooring}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.flooring && formik.errors.flooring && (
            <div style={styles.error}>{formik.errors.flooring}</div>
          )}

          <label>Address</label>
          <input
            type="text"
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.address && formik.errors.address && (
            <div style={styles.error}>{formik.errors.address}</div>
          )}

          <label>Landmark</label>
          <input
            type="text"
            name="landmark"
            value={formik.values.landmark}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.landmark && formik.errors.landmark && (
            <div style={styles.error}>{formik.errors.landmark}</div>
          )}

          <label>Water</label>
          <input
            type="text"
            name="water"
            value={formik.values.water}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.water && formik.errors.water && (
            <div style={styles.error}>{formik.errors.water}</div>
          )}

          <label>Bathroom</label>
          <input
            type="text"
            name="bathroom"
            value={formik.values.bathroom}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.bathroom && formik.errors.bathroom && (
            <div style={styles.error}>{formik.errors.bathroom}</div>
          )}

          <label>Power Backup</label>
          <input
            type="text"
            name="powerBackup"
            value={formik.values.powerBackup}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.powerBackup && formik.errors.powerBackup && (
            <div style={styles.error}>{formik.errors.powerBackup}</div>
          )}
          <div
            style={{
              fontSize: "30px",
              fontFamily: "Montserrat",
            }}
          >
            Images:
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              marginTop: "10px",
            }}
          >
            {propertyData?.propertyImages?.map((image, index) => (
              <div
                key={index}
                style={{
                  position: "relative",
                }}
              >
                <img
                  src={image}
                  alt={`property-${index}`}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <div
                  onClick={() => removeImage(index)}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  &times;
                </div>
              </div>
            ))}
            <div
              style={{
                width: "100px",
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2px dashed #ccc",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => document.getElementById("fileInput").click()}
            >
              <span style={{ fontSize: "30px", color: "#ccc" }}>+</span>
            </div>
          </div>
          <input
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            multiple
            onChange={handleImageUpload}
          />

          <label>Overlooking</label>
          <input
            type="text"
            name="overlooking"
            value={formik.values.overlooking}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.input}
          />
          {formik.touched.overlooking && formik.errors.overlooking && (
            <div style={styles.error}>{formik.errors.overlooking}</div>
          )}

          <button type="submit" style={styles.button}>
            Update Property
          </button>
        </form>
        {message && <div style={styles.message}>{message}</div>}
      </div>
    </>
  );
};

const styles = {
  container: {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: "10px",
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
  },
  error: {
    color: "red",
    marginBottom: "10px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007BFF",
    color: "white",
    cursor: "pointer",
  },
  message: {
    marginTop: "20px",
    fontSize: "18px",
    color: "green",
  },
};

export default UpdateProperty;
