import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import StickyFooter from "../../components/Footer";
import StickyFooterMobile from "../../components/FooterMobile";
import { useMediaQuery } from "react-responsive";
import AdminControl from "../../components/AdminControl";
import { useSelector } from "react-redux";

const Admin = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { user } = useSelector((state) => state.auth);
  const [showAdmin, setShowAdmin] = useState(false);

  useEffect(() => {
    console.log("this is now user", user);
    if (user !== null) {
      if (user?.role === "admin") {
        setShowAdmin(true);
      }
    }
  }, []);

  return (
    <>
      <div>
        <Header
          closeLoginModal={closeLoginModal}
          setCloseLoginModal={setCloseLoginModal}
          setOpenPopUp={setOpenPopUp}
        />
      </div>
      {showAdmin ? (
        <AdminControl />
      ) : (
        <h1 style={{ margin: " 250px 10px", textAlign: "center" }}>
          404 ERROR
        </h1>
      )}

      {isMobile ? <StickyFooterMobile /> : <StickyFooter />}
    </>
  );
};

export default Admin;
