import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Marquee from "react-fast-marquee";
import { Call } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#B90000",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0",
    },
  },
  toolbar: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  text: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: "500",
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      fontSize: "0.8rem",
    },
  },
}));

const TopBanner = () => {
  const classes = useStyles();

  return (
    <AppBar
      style={{ backgroundColor: "#B90000" }}
      position="static"
      className={classes.appBar}
    >
      <div className="container-fluid px-4">
        <div className="full-width">
          <div className="w-15"></div>
          <div className="marquee">
            <Marquee>
              <p variant="p" className={classes.text}>
                No 1. Realestate Website to Buy / Sell Your Place
              </p>
            </Marquee>
          </div>
          <div className="top-right">
            <a className="text-white no-link" href="tel:+918240444587">
              <Call fontSize="18px" /> +91 82404 44587
            </a>
          </div>
        </div>
      </div>
    </AppBar>
  );
};

export default TopBanner;
