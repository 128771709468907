import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Box,
  Select,
  Typography,
  MenuItem,
  Avatar,
  OutlinedInput,
  InputAdornment,
  Input,
} from "@mui/material";
import Header from "../../components/Header";
import styles from "../../styles/styles.js";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonContained from "../../components/Buttons/ButtonContained";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getAllUsers } from "../../redux/action/auth";
import { API_KEY, assetUrl, imageReq } from "../../config/axiosConfig";
import { updateOtherServiceStatusApi } from "../../config/otherservices";
import { getAllOtherServicesAction } from "../../redux/action/otherservice";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import classes from "../../styles/styles.js";
import { useTheme } from "@emotion/react";
import ReactGoogleAutocomplete, {
  usePlacesWidget,
} from "react-google-autocomplete";
import { Search } from "@mui/icons-material";
import OutlinedFormInput from "../../components/Input/OutlinedInput.js";
// import { servicesArr } from "../../util/json/helpers/index.js";
import { getLocLatLng } from "../properties/index.js";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import ModalSignUp from "../../components/ModalSignup/index.js";
import SignUp from "../../components/Signup/index.js";
import ModalLogin from "../../components/ModalLogin/index.js";
import Login from "../../components/Login/index.js";
import { privateReq } from "../../config/axiosConfig";
import publicReq from "../../config/axiosConfig";
// import { useNavigate } from 'react-router-dom';
const ListItem = ({
  onUpdateServiceStatus,
  user,
  index,
  service,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const [showPhone, setShowPhone] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  return (
    <div
      class="col-lg-4 col-md-6 col-xs-12 mt-3"
      style={{
        height: isMobile ? "auto" : "auto",
      }}
    >
      <div
        className="card mb-2"
        style={{
          minWidth: "90%",
          height: "auto",
        }}
      >
        <div
          className="card-container"
          style={{
            height: "30%",
          }}
        >
          <Box
            sx={[
              styles.borderRadius12,
              {
                height: "30%",
              },
            ]}
            key={index}
            className="border-0 d-flex flex-column justify-content-between  card-container-user-desc shadow-none px-4 py-3"
          >
            <div className="d-flex align-items-center">
              <Avatar
                sx={{ width: "72px", height: "72px" }}
                alt={service?.name || ""}
                src={assetUrl + service?.profilePic}
                // src={card.imageSrc}
              />
              <Box className="px-2">
                <Typography
                  sx={[
                    styles.fontFamilyDS,
                    styles.fbBold,
                    styles.fs20,
                    styles.color240501,
                    styles.fbBold,
                  ]}
                >
                  {service?.name}
                </Typography>
                <Typography
                  className="text-capitalize text-color979797"
                  sx={[styles.fontFamilyDS, styles.fs20]}
                >
                  {service?.serviceName}
                </Typography>
              </Box>
            </div>

            {/* <div className="py-4"> */}
            {/* <Typography
                                      className="text-color979797"
                                      sx={[
                                          styles.fontFamilyDS,
                                          ,
                                          styles.fs16,
                                          {
                                              display: "-webkit-box",
                                              overflow: "hidden",
                                              WebkitBoxOrient: "vertical",
                                              WebkitLineClamp: 5,
                                          },
                                      ]}
                                  >
                                      {users?.desc}
                                  </Typography> */}
            {/* </div> */}
            <div>
              <div className="py-4">
                <Box className="d-flex">
                  <Typography
                    className="w-40"
                    sx={[
                      styles.fontFamilyDS,
                      styles.fs16,
                      styles.fontWeight500,
                    ]}
                  >
                    Language:
                  </Typography>
                  <Typography
                    className="text-color979797"
                    sx={[styles.fontFamilyDS, styles.fs16]}
                  >
                    {service?.language}
                  </Typography>
                </Box>
              </div>
              {(user?.role === "admin" || user?.role === "serviceman") && (
                <Box>
                  <Typography
                    className="w-40"
                    sx={[
                      styles.fontFamilyDS,
                      styles.fs16,
                      styles.fontWeight500,
                    ]}
                  >
                    Images:
                  </Typography>

                  {service?.images?.map((img, index) => (
                    <div className="py-1">
                      <a
                        className="text-color979797"
                        target="_blank"
                        href={assetUrl + img}
                      >
                        - Image {index + 1}
                      </a>
                    </div>
                  ))}
                </Box>
              )}
            </div>
            <div className="d-flex mt-3">
              {/* <button className="btn-connect">Connect </button> */}
              {user?.role == "admin" ? (
                <ButtonContained
                  sx={[
                    styles.fontFamilySGB,
                    service.status !== "active" && styles.btnBgGreen,
                    styles.p10,
                    styles.borderRadius12,
                  ]}
                  className="m-0 my-2 mx-1"
                  onClick={(e) => {
                    e.preventDefault();
                    onUpdateServiceStatus(
                      service._id,
                      service.status == "active" ? "inactive" : "active"
                    );
                  }}
                >
                  {service.status == "active" ? "Deactivate" : "Activate"}
                </ButtonContained>
              ) : user?.role === "serviceman" ? (
                <ButtonContained
                  sx={[
                    styles.fontFamilySGB,
                    styles.p10,
                    styles.borderRadius12,
                    service.status === "active" && styles.btnBgGreen,
                  ]}
                  className="m-0 my-2 mx-1"
                >
                  {service.status}
                </ButtonContained>
              ) : (
                <ButtonContained
                  sx={[
                    styles.fontFamilySGB,
                    styles.btnBgGreen,
                    styles.p10,
                    styles.borderRadius12,
                  ]}
                  className="m-0 mt-1 mx-1"
                  onClick={(e) => {
                    e.preventDefault(e);
                    if (user) {
                      if (isMobile) window.open(service?.phone);
                      else setShowPhone(true);
                    } else {
                      setCloseLoginModal(true);
                    }
                  }}
                >
                  {showPhone ? service?.phone : "Contact"}
                </ButtonContained>
              )}
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};
const OtherServices = ({ setOpenPopUp }) => {
  const headerRef = useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [closeModal, setCloseModal] = useState(false);
  const [closeLoginModal, setCloseLoginModal] = useState(false);
  const { services } = useSelector((state) => state.otherservices);
  const { user } = useSelector((state) => state.auth);
  // const navigate = useNavigate();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [page, setPage] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showFilter, setShowFilter] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [locality, setLocality] = useState({});
  const { location: userLocation } = useSelector((state) => state.auth);
  const location = useLocation();
  const [servicesArr, setServicesArr] = useState([]);
  const [showServices, setShowServices] = useState();

  function checkTabS2() {
    publicReq
      .post("/get-access-for-basic-features", { propertyName: "SERVICES" })
      .then((result) => {
        setShowServices(result.data.message.enabled);
        console.log(result.data.message.enabled);
        if (result.data.message.enabled === false) {
          navigate("/");
        }
      })
      .catch((error) => {
        toast.error("something went wrong");
        console.log(error);
      });

    // const results = await Promise.all(promises);

    // setBroker(results[0].data.message.enabled);
    // setOwner(results[1].data.message.enabled);
    // setService(results[2].data.message.enabled);
    // setProperty(results[3].data.message.enabled);
  }

  useEffect(() => {
    checkTabS2();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await privateReq.get("/admin-get-services");
      setServicesArr(data.services);
    })();
  }, []);

  const { ref } = usePlacesWidget({
    apiKey: API_KEY,
    options: {
      componentRestrictions: { country: "in" },
      types: ["(regions)"],
    },
    onPlaceSelected: (place) => {
      setLocality({
        lng: place.geometry.location.lng(),
        lat: place.geometry.location.lat(),
      });
    },
  });
  useEffect(() => {
    onGetAllOtherServices();
  }, []);

  const onGetAllOtherServices = (queryParams = "") => {
    dispatch(getAllOtherServicesAction(queryParams));
  };

  const onUpdateServiceStatus = async (id, status) => {
    try {
      const res = await updateOtherServiceStatusApi({
        active: status,
        serviceId: id,
      });
      if (res.data.status) {
        toast.success("Status updated successfully!");
        onGetAllOtherServices();
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerHeight]);
  function onResetFilter(e) {
    e.preventDefault();
    ref.current?.setValue("");
    onGetAllOtherServices();
    setShowFilter(false);
  }
  useEffect(() => {
    if (location.search) {
      let params = new URLSearchParams(location.search);
      if (params.get("serviceName")) {
        setServiceName([params.get("serviceName")]);
      }
      if (params.get("latitude") && params.get("longitude")) {
        setLocality({
          lng: params.get("longitude"),
          lat: params.get("latitude"),
        });
      }

      let timeout = setTimeout(() => {
        onApplyFilter();
        clearTimeout(timeout);
      }, 500);
    } else if (userLocation.lat !== undefined && userLocation.lng !== undefined)
      onGetAllOtherServices();
    else onGetAllOtherServices();
  }, [userLocation]);
  const onApplyFilter = async () => {
    // event.preventDefault();
    try {
      const data = {
        serviceName,
      };
      let loc =
        locality.lng && locality.lat ? locality : await getLocLatLng(locality);
      if (loc?.lng && loc?.lat) {
        data.longitude = loc?.lng;
        data.latitude = loc?.lat;
      } else if (userLocation?.lng && userLocation?.lat) {
        data.longitude = userLocation?.lng;
        data.latitude = userLocation?.lat;
      }
      let query = `?`;
      Object.keys(data).map((key, idx) => {
        if (Array.isArray(data[key])) {
          data[key].forEach((item, index) => {
            query += `${idx > 0 || index > 0 ? "&" : ""}${key}=${item}`;
          });
        } else {
          query += `${idx > 0 ? "&" : ""}${key}=${data[key]}`;
        }
      });

      onGetAllOtherServices(query);
      setShowFilter(false);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <div className="container-fluid px-0">
        <div className="properties-container bgF4F4F4">
          <div
            className="row mx-0"
            style={{
              // height: `calc(100vh - ${headerHeight}px)`,
              // overflowY: "scroll",
              width: "100%",
            }}
          >
            {isMobile && (
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
                sx={[
                  classes.colorADB0B6,
                  classes.fs20,
                  classes.fontFamilyDS,
                  classes.fbBold,
                  classes.textDecorationUL,
                  {
                    cursor: "pointer",
                    zIndex: 9,
                  },
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                      ...classes.fbBold,
                    },
                  },
                ]}
              >
                Show Filters
              </Typography>
            )}
            {(isMobile && showFilter) || !isMobile ? (
              <div className="col-lg-3 col-md-3 col-xs-12 bg-white p-4 d-flex container-scrollbar flex-column justify-content-between">
                <div>
                  <Box
                    sx={[
                      classes.p10,
                      classes.dflex,
                      classes.jcSBetween,
                      classes.alignItemCenter,
                    ]}
                    className="mt-2"
                  >
                    <Typography
                      component="div"
                      variant="h5"
                      sx={[
                        classes.fontFamilySGB,
                        classes.fbBold,
                        classes.fs24,
                        classes.dflex,
                        classes.color240501,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs17,
                          },
                        },
                      ]}
                    >
                      Filters
                    </Typography>
                    <Typography
                      onClick={onResetFilter}
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                      sx={[
                        classes.colorADB0B6,
                        classes.fs20,
                        classes.fontFamilyDS,
                        classes.fbBold,
                        classes.textDecorationUL,
                        {
                          cursor: "pointer",
                        },
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs14,
                            ...classes.fbBold,
                          },
                        },
                      ]}
                    >
                      Reset Filters
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={[
                      classes.colorADB0B6,
                      classes.fs20,
                      classes.fontFamilyDS,
                      classes.fbBold,
                      classes.p10,
                      {
                        [theme.breakpoints.between("xs", "md")]: {
                          ...classes.fs17,
                        },
                      },
                    ]}
                  >
                    Service Name
                  </Typography>
                  <Select
                    sx={[styles.filterSelect]}
                    className="w-100"
                    placeholder="Select"
                    value={serviceName}
                    onChange={(e) => {
                      setServiceName(e.target.value);
                    }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {servicesArr?.map(
                      (item) =>
                        item.service_name && (
                          <MenuItem
                            className="text-capitalize"
                            value={item._id}
                          >
                            {item.service_name}
                          </MenuItem>
                        )
                    )}
                  </Select>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={[
                      classes.colorADB0B6,
                      classes.fs20,
                      classes.fontFamilyDS,
                      classes.fbBold,
                      classes.p10,
                      {
                        [theme.breakpoints.between("xs", "md")]: {
                          ...classes.fs17,
                        },
                      },
                    ]}
                  >
                    City or Locality
                  </Typography>
                  <GooglePlacesAutocomplete
                    apiKey={API_KEY}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["in"],
                      },
                    }}
                    selectProps={{
                      district: locality,
                      onChange: setLocality,
                      placeholder: "Enter City, Locality",
                    }}
                  />
                </div>
                <Box>
                  <ButtonOutlined
                    onClick={onApplyFilter}
                    sx={[
                      classes.width95,
                      classes.fbBold,
                      classes.fontFamilySGB,
                      classes.fs16,
                      classes.borderRadius12,
                      classes.mt50,
                    ]}
                  >
                    Apply Filter
                  </ButtonOutlined>
                </Box>
              </div>
            ) : null}
            <div className="col-lg-9 col-md-9 col-xs-12">
              <div className="row properties-list mx-0">
                <div className="properties-header mt-5 px-3">
                  <div>
                    <span className="text-capitalize">Services</span>
                  </div>
                  <div
                    className="properties-filter-select"
                    style={{ marginRight: "1.25rem" }}
                  ></div>
                </div>
                <div
                  className={`${
                    isMobile ? "d-block" : "d-flex"
                  } flex-wrap mt-2 other-services-list`}
                >
                  {services &&
                    services.map((service, index) => (
                      <ListItem
                        user={user}
                        setCloseLoginModal={setCloseLoginModal}
                        service={service}
                        index={index}
                        onUpdateServiceStatus={onUpdateServiceStatus}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
          <ModalSignUp
            state={closeModal}
            stateFunc={setCloseModal}
            title="Please fill all the Details"
          >
            <SignUp
              setSignupModal={setCloseModal}
              isPropertyFree={false}
              setCloseModal={setCloseLoginModal}
            />
          </ModalSignUp>

          <ModalLogin
            state={closeLoginModal}
            stateFunc={setCloseLoginModal}
            title="Login"
          >
            <Login
              setSignupModal={setCloseModal}
              setCloseModal={setCloseLoginModal}
            />
          </ModalLogin>
        </div>
      </div>
      {/* <StickyFooter /> */}
    </>
  );
};

export default OtherServices;
