import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Modal,
  FormControlLabel,
} from "@mui/material";
import { privateReq } from "../../config/axiosConfig";
import toast from "react-hot-toast";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop.js";
import SecurityIcon from "@mui/icons-material/Security";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  maxHeight: "90vh",
  overflow: "auto",
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};

// List of all admin and frontend screens that can have permissions
const AVAILABLE_SCREENS = [
  // Admin screens
  "Dashboard",
  "Users",
  "Properties",
  "Furniture",
  "Services",
  "Brokers",
  "Owners",
  "Enquiries",
  "Documents",
  "Blog",
  "FAQ",
  "Testimonials",
  // Frontend screens
  "Home",
  "PropertyListing",
  "FurnitureListing",
  "ServiceListing",
  "Profile",
  "Contact",
];

const RoleManagement = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [editingRole, setEditingRole] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    permissions: AVAILABLE_SCREENS.map((screen) => ({
      name: screen,
      view: true,
      add: false,
      edit: false,
    })),
  });

  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    fetchRoles();
    setHeaderHeight(headerRef.current.offsetHeight);
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await privateReq.get("/roles");
      setRoles(response.data.data);
    } catch (err) {
      console.error("Error fetching roles:", err);
      toast.error("Failed to fetch roles");
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    // Auto format: capitalize first letter, trim spaces, replace multiple spaces
    const formattedValue = value
      .trim()
      .replace(/\s+/g, " ")
      .replace(/^\w/, (c) => c.toUpperCase());

    setFormData((prev) => ({
      ...prev,
      name: formattedValue,
    }));
  };

  const handlePermissionChange = (screenName, permissionType) => {
    setFormData((prev) => ({
      ...prev,
      permissions: prev.permissions.map((perm) =>
        perm.name === screenName
          ? { ...perm, [permissionType]: !perm[permissionType] }
          : perm
      ),
    }));
  };

  const resetForm = () => {
    setFormData({
      name: "",
      permissions: AVAILABLE_SCREENS.map((screen) => ({
        name: screen,
        view: true,
        add: false,
        edit: false,
      })),
    });
    setEditingRole(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingRole) {
        await privateReq.put(`/roles/${editingRole._id}`, formData);
        toast.success("Role updated successfully");
      } else {
        await privateReq.post("/roles", formData);
        toast.success("Role created successfully");
      }
      resetForm();
      fetchRoles();
      setOpenModal(false);
    } catch (err) {
      console.error("Error saving role:", err);
      toast.error(err.response?.data?.message || "Failed to save role");
    }
  };

  const handleEdit = (role) => {
    setEditingRole(role);
    setFormData({
      name: role.name,
      permissions: AVAILABLE_SCREENS.map((screen) => {
        const existingPerm = role.permissions.find((p) => p.name === screen);
        return {
          name: screen,
          view: existingPerm?.view ?? true,
          add: existingPerm?.add ?? false,
          edit: existingPerm?.edit ?? false,
        };
      }),
    });
    setOpenModal(true);
  };

  return (
    <>
      <ScrollToTop />
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <Box
        sx={{
          p: 4,
          maxWidth: "100%",
          mt: `${headerHeight}px`,
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)",
          minHeight: "100vh",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "16px",
            background: "white",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <Box
            sx={{
              p: 1,
              borderBottom: "1px solid #eee",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", padding:"3px, 10px" }}>
              <SecurityIcon sx={{ fontSize: 16, color: "#1976d2", mr: 1 }} />
              <Typography  fontWeight="600" color="#2c3e50">
                Role Management
              </Typography>
            </Box>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                resetForm();
                setOpenModal(true);
              }}
              sx={{
                borderRadius: "12px",
                textTransform: "none",
                background: "linear-gradient(45deg, #1976d2 30%, #2196f3 90%)",
                boxShadow: "0 3px 15px rgba(33,150,243,0.3)",
                "&:hover": {
                  background:
                    "linear-gradient(45deg, #1565c0 30%, #1976d2 90%)",
                },
              }}
            >
              Add New Role
            </Button>
          </Box>
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: "600", backgroundColor: "#f8fafc", width:"150px", padding:"3px 10px" }}
                  >
                    Role Name
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "600", backgroundColor: "#f8fafc", padding:"3px 10px" }}
                  >
                    Permissions
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      width: 100,
                      padding:"3px 10px"
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {roles.map((role) => (
                  <TableRow
                    key={role._id}
                    hover
                    sx={{
                      margin:"0px",
                      "&:hover": {
                        backgroundColor: "#f8fafc",
                      },
                    }}
                  >
                    <TableCell 
                    style={{
                      padding:"3px 10px"
                    }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        {role.name}
                      </Typography>
                    </TableCell>
                    <TableCell style={{padding:"6px 10px"}}>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.1 }}>
                        {role.permissions.map((perm) => (
                          <Box
                            key={perm.name}
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                              bgcolor: "#e3f2fd",
                              borderRadius: "4px",
                              px: 1,
                              py: 0.2,
                              mr: 1,
                              mb: 0.2,
                            }}
                          >
                            <Typography variant="caption" sx={{ ml: 0 }}>
                              {perm.name}
                            </Typography>
                            {perm.view && (
                              <Typography
                                variant="caption"
                                sx={{ color: "success.main", ml:0.12 }}
                              >
                                View
                              </Typography>
                            )}
                            {perm.add && (
                              <Typography
                                variant="caption"
                                sx={{ color: "primary.main", ml: 0.5 }}
                              >
                                Add
                              </Typography>
                            )}
                            {perm.edit && (
                              <Typography
                                variant="caption"
                                sx={{ color: "warning.main", ml: 0.5 }}
                              >
                                Edit
                              </Typography>
                            )}
                          </Box>
                        ))}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Button
                        startIcon={<EditIcon />}
                        onClick={() => handleEdit(role)}
                        sx={{ textTransform: "none" }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            resetForm();
          }}
          aria-labelledby="role-modal"
          aria-describedby="modal-to-add-or-edit-role"
        >
          <Paper sx={modalStyle}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <SecurityIcon sx={{ fontSize: 16, color: "#1976d2" }} />
              <Typography variant="p" fontWeight="600" color="#2c3e50">
                {editingRole ? "Edit Role" : "Add New Role"}
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Role Name"
                value={formData.name}
                onChange={handleInputChange}
                margin="normal"
                required
                // sx={{
                //   "& .MuiOutlinedInput-root": {
                //     borderRadius: "12px",
                //   },
                // }}
              />

              <Typography
                variant="subtitle1"
                sx={{
                  color: "#2c3e50",
                  fontWeight: 500,
                  mt: 1,
                  mb: 1,
                }}
              >
                Permissions
              </Typography>

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"0px"}}>Screen</TableCell>
                      <TableCell style={{padding:"0px"}} align="center">View</TableCell>
                      <TableCell style={{padding:"0px"}} align="center">Add</TableCell>
                      <TableCell style={{padding:"0px"}} align="center">Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.permissions.map((perm) => (
                      <TableRow key={perm.name}>
                        <TableCell style={{padding:"0px"}}  >{perm.name}</TableCell>
                        <TableCell align="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                              style={{
                                position:"relative",
                                left:"14px"
                              }}
                                checked={perm.view}
                                onChange={() =>
                                  handlePermissionChange(perm.name, "view")
                                }
                                color="primary"
                              />
                            }
                            label=""
                          />
                        </TableCell>
                        <TableCell style={{padding:"0px"}} align="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                              style={{
                                position:"relative",
                                left:"14px"
                              }}
                                checked={perm.add}
                                onChange={() =>
                                  handlePermissionChange(perm.name, "add")
                                }
                                color="primary"
                              />
                            }
                            label=""
                          />
                        </TableCell>
                        <TableCell style={{padding:"0px"}} align="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                              style={{
                                position:"relative",
                                left:"14px"
                              }}
                                checked={perm.edit}
                                onChange={() =>
                                  handlePermissionChange(perm.name, "edit")
                                }
                                color="primary"
                              />
                            }
                            label=""
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 1,
                  height: "48px",
                  borderRadius: "12px",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "600",
                  background:
                    "linear-gradient(45deg, #1976d2 30%, #2196f3 90%)",
                  boxShadow: "0 3px 15px rgba(33,150,243,0.3)",
                  "&:hover": {
                    background:
                      "linear-gradient(45deg, #1565c0 30%, #1976d2 90%)",
                  },
                }}
              >
                {editingRole ? "Update Role" : "Create Role"}
              </Button>
            </form>
          </Paper>
        </Modal>
      </Box>
    </>
  );
};

export default RoleManagement;
