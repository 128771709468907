import React from "react";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
const Privacy = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="container" style={{ paddingTop: "125px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Privacy Policy
        </h2>{" "}
        <br />
        <p className="para-about-terms">Effective Date: 31/12/2023</p>
        <h4 className="h4-about-tems"> Introduction </h4>
        <p className="para-about-terms">
          {" "}
          Welcome to Gharwalah.com ("the Platform"). This Privacy Policy is
          designed to help you understand how we collect, use, disclose, and
          safeguard your personal information. Your privacy is important to us,
          and we are committed to ensuring the confidentiality and security of
          your personal data.
        </p>{" "}
        <br />
        <h4 className="h4-about-tems">Information We Collect: </h4>
        <br />
        <h4 className="h4-about-tems"> Personal Information: </h4>
        <p className="para-about-terms">
          {" "}
          We may collect personal information such as your name, email address,
          phone number, postal address, and payment information when you use our
          services. User-Generated Content: We may collect data related to any
          content you submit or upload, including photos, reviews, and comments.{" "}
        </p>
        <br />
        <h4 className="h4-about-tems"> Device Information: </h4>
        <p className="para-about-terms">
          {" "}
          We collect information about the devices you use to access our
          services, including device type, operating system, and unique device
          identifiers.
        </p>{" "}
        <br />
        <h4 className="h4-about-tems"> Usage Data:</h4>
        <p className="para-about-terms">
          {" "}
          We collect data about how you use the Platform, including your search
          history, browsing history, and interactions with other users. Location
          Information: With your consent, we may collect and process information
          about your precise or approximate location using data such as GPS and
          IP address. Third-Party Information: We may receive information from
          third-party services and integrate that information into our services.{" "}
        </p>
        <br />
        <h4 className="h4-about-tems"> How We Use Your Information:</h4>
        <p className="para-about-terms">
          {" "}
          Service Provision: We use your information to provide, maintain, and
          improve our services, including facilitating property rentals, rental
          transactions, and resolving issues. Communication: We may use your
          contact information to send transaction-related messages, promotional
          messages, and service updates. Customer Support: We use your
          information to provide customer support and address your inquiries.
          Personalization: We may use your data to personalize your experience
          on the Platform, including providing recommendations and customizing
          the content you see. Information Sharing.{" "}
        </p>
        <br />
        <h4 className="h4-about-tems">With Other Users: </h4>
        <p className="para-about-terms">
          {" "}
          Your user profile information, reviews, and other user-generated
          content may be visible to other users. Service Providers: We share
          information with service providers who assist us in operating our
          services. These service providers may have access to your information
          for specific tasks. Legal and Safety: We may share information to
          respond to legal requests, protect our rights, or comply with the law.
          Business Transfers: In the event of a merger, acquisition, or sale of
          all or part of our assets, we may transfer your information.
        </p>{" "}
        <br />
        <h4 className="h4-about-tems"> Data Security</h4>
        <p className="para-about-terms">
          {" "}
          We employ security measures to protect your information from
          unauthorized access, disclosure, and alteration. We regularly review
          our information collection, storage, and processing practices to
          ensure we guard against unauthorized access.
        </p>{" "}
        <br />
        <h4 className="h4-about-tems"> Your Choices and Rights:</h4> <br />
        <h4 className="h4-about-tems">You have the right to: </h4>
        Access and review your personal information. <br />
        Correct inaccurate information. <br />
        Withdraw consent for certain data processing. <br />
        Delete or request the removal of your information. <br />
        Opt out of receiving promotional emails. <br />
        <br />
        <h4 className="h4-about-tems">Children's Privacy </h4>
        <p className="para-about-terms">
          {" "}
          Our services are not intended for individuals under the age of 18. We
          do not knowingly collect personal information from children. If you
          believe that a child has provided us with personal information, please
          contact us, and we will take appropriate steps to remove such
          information.
        </p>{" "}
        <br />
        <h4 className="h4-about-tems"> Changes to This Privacy Policy</h4>{" "}
        <p className="para-about-terms">
          {" "}
          We may update this Privacy Policy to reflect changes in our practices
          and services. The updated policy will be posted on our website with
          the effective date.
        </p>{" "}
        <br />
        <br />
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </>
  );
};

export default Privacy;
