import React from "react";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
const Services = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="container" style={{ paddingTop: "125px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Services
        </h2>{" "}
        <br />
        <p className="para-about-terms">
          {" "}
          Welcome to Gharwala Services – a realm where your home needs find
          swift solutions. Our array of services is meticulously designed to add
          ease to your life. Explore the convenience of booking maid services
          with just a few clicks. Need an expert electrician or plumber?
          Gharwala brings skilled professionals right to your doorstep. Our
          house cleaning services promise a sparkling abode, ensuring you come
          home to comfort and cleanliness. With a seamless online booking
          process, timely service delivery, and trusted professionals, Gharwala
          Services transforms your home maintenance experience. Choose Gharwala
          – where service excellence meets the simplicity of a click.
        </p>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </div>
  );
};

export default Services;
