import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Box, Checkbox, Chip, Typography } from "@mui/material";
import styles from "../../styles/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import classes from "../../styles/styles.js";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import { useTheme } from "@mui/material/styles";
import {
  cancelFurnitureBookingService,
  getFurnitureBookingsService,
  putFurnitureOrderVerified,
} from "../../config/furniture";
import ButtonContained from "../../components/Buttons/ButtonContained";
import { assetUrl, privateReq } from "../../config/axiosConfig";
import StarIcon from "@mui/icons-material/Star";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import ModalSignUp from "../../components/ModalSignup";
const FurnitureOrder = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const headerRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [category, setCategory] = useState([]);
  const [productType, setProductType] = useState([]);
  const [bookings, setBookings] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const theme = useTheme();
  const [showFilter, setShowFilter] = useState(false);
  const [showKyc, setShowKyc] = useState(false);
  const [kycDetails, setKycDetails] = useState();

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
    getBookings();
  }, [headerHeight]);

  function showKYC(kyc) {
    setKycDetails(kyc);
    setShowKyc(true);
  }

  async function sendKycReminder(name, phone) {
    try {
      const config = {
        username: "Sachin",
        phone: 8789869147,
        description:
          "Please add your KYC to get your order delivered by Gharwala. Please click this link to submit KYC https://www.gharwalah.com/profile ",
      };

      const res = await privateReq.post("/admin-notification", {
        ...config,
        phone: 8789869147,
      });
      console.log("res", res);
      toast.success("Message sent successfully", {
        position: "top-right",
      });
    } catch (err) {
      console.log(err);
    }
  }

  const getBookings = async (query = "") => {
    try {
      const res = await getFurnitureBookingsService(query);
      if (res.data.status) {
        setBookings(res.data.bookings);
        console.log(res.data.bookings);
      }
    } catch (e) {
      console.log(e);
    }
  };
  function onResetFilter() {
    setCategory([]);
    setProductType([]);
    getBookings();
    setShowFilter(false);
  }
  const onCheck = (state, val, setState) => {
    const arr = [...state];
    if (arr.includes(val)) {
      let idx = arr.findIndex((i) => i === val);
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    setState(arr);
  };
  const onApplyFilter = () => {
    // event.preventDefault();
    try {
      const data = {
        category,
        productType,
      };
      let query = `?`;
      Object.keys(data).map((key, idx) => {
        if (Array.isArray(data[key])) {
          data[key].forEach((item, index) => {
            query += `${idx > 0 || index > 0 ? "&" : ""}${key}=${item}`;
          });
        } else {
          query += `${idx > 0 ? "&" : ""}${key}=${data[key]}`;
        }
      });

      getBookings(query);
      setShowFilter(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onCancelBooking = async (id, idx) => {
    if (window.confirm("Are you sure you want to cancel this order?")) {
      try {
        const res = await cancelFurnitureBookingService({
          bookingId: id,
        });
        if (res.data.status) {
          toast.success("Order cancelled!");
          let arr = [...bookings];
          arr[idx].expired = true;
          setBookings(arr);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onVerify = async (item) => {
    const res = await putFurnitureOrderVerified({
      orderId: item._id,
      furnitures: item.furniture,
    });
    if (res) {
      setHeaderHeight(headerRef.current.offsetHeight);
      getBookings();
    }
  };

  return (
    <>
      <Header
        innerRef={headerRef}
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="container-fluid px-0">
        <div className="properties-container">
          <div className="w-100">
            <div className="border-box px-0">
              <Box
                className="d-flex align-items-center py-3 border-bottom p-4 w-100"
                sx={[styles.color9A291B]}
              >
                <Link
                  onClick={() => navigate(-1)}
                  className="d-flex align-items-center text-decoration-none"
                >
                  <KeyboardBackspaceIcon sx={[styles.color9A291B]} />
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "#950000",
                      marginTop: "10px",
                    }}
                  >
                    Listing
                  </p>

                  <Typography
                    sx={[
                      styles.color9A291B,
                      styles.fs24,
                      styles.fbBold,
                      styles.fontFamilyDS,
                    ]}
                    className="px-1"
                  ></Typography>
                </Link>
              </Box>
            </div>
            <div className="row mx-0  w-100">
              {isMobile && (
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                  sx={[
                    classes.colorADB0B6,
                    classes.fs20,
                    classes.fontFamilyDS,
                    classes.fbBold,
                    classes.textDecorationUL,
                    {
                      cursor: "pointer",
                      zIndex: 9,
                    },
                    {
                      [theme.breakpoints.between("xs", "md")]: {
                        ...classes.fs14,
                        ...classes.fbBold,
                      },
                    },
                  ]}
                >
                  Show Filters
                </Typography>
              )}

              {(isMobile && showFilter) || !isMobile ? (
                <div
                  className="col-lg-3 col-md-3 col-xs-12 bg-white p-4 container-scrollbar"
                  style={{
                    height: `calc(100vh - ${headerHeight}px)`,
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <Box
                    sx={[
                      classes.p10,
                      classes.dflex,
                      classes.jcSBetween,
                      classes.alignItemCenter,
                    ]}
                  >
                    <Typography
                      component="div"
                      variant="h5"
                      sx={[
                        classes.fontFamilySGB,
                        classes.fbBold,
                        classes.fs24,
                        classes.dflex,
                        classes.color240501,
                      ]}
                    >
                      Filters
                    </Typography>
                    <Typography
                      onClick={onResetFilter}
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                      sx={[
                        classes.colorADB0B6,
                        classes.fs20,
                        classes.fontFamilyDS,
                        classes.fbBold,
                        classes.textDecorationUL,
                        {
                          cursor: "pointer",
                        },
                      ]}
                    >
                      Reset Filters
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={[
                      classes.colorADB0B6,
                      classes.fs20,
                      classes.fontFamilyDS,
                      classes.fbBold,
                      classes.p10,
                    ]}
                  >
                    Category
                  </Typography>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                      ]}
                    >
                      Bedroom
                    </Typography>
                    <Checkbox
                      value={"bedroom"}
                      onChange={(e) =>
                        onCheck(category, e.target.value, setCategory)
                      }
                      checked={category.includes("bedroom")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                      ]}
                    >
                      Living Room
                    </Typography>
                    <Checkbox
                      value={"living-room"}
                      onChange={(e) =>
                        onCheck(category, e.target.value, setCategory)
                      }
                      checked={category.includes("living-room")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                      ]}
                    >
                      Baby Furniture
                    </Typography>
                    <Checkbox
                      value={"baby-furniture"}
                      onChange={(e) =>
                        onCheck(category, e.target.value, setCategory)
                      }
                      checked={category.includes("baby-furniture")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                      ]}
                    >
                      Kitchen & Dining
                    </Typography>
                    <Checkbox
                      value={"kitchen-dining"}
                      onChange={(e) =>
                        onCheck(category, e.target.value, setCategory)
                      }
                      checked={category.includes("kitchen-dining")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  {/* <Box
                className="d-flex justify-content-between w-100 align-items-center"
                sx={classes.px10}
              >
                <Typography
                  sx={[classes.fs16, classes.color240501, classes.fontFamilyDS]}
                >
                  Work From Home (WFH)
                </Typography>
                <Checkbox
                  sx={{
                    "&.Mui-checked": classes.color9A291B,
                  }}
                />
              </Box> */}

                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={[
                      classes.colorADB0B6,
                      classes.fs20,
                      classes.fontFamilyDS,
                      classes.fbBold,
                      classes.p10,
                    ]}
                  >
                    Sub Category
                  </Typography>
                  {/* <Box
                className="d-flex justify-content-between w-100 align-items-center"
                sx={classes.px10}
              >
                <Typography
                  sx={[classes.fs16, classes.color240501, classes.fontFamilyDS]}
                >
                  Sofas
                </Typography>
                <Checkbox
                  sx={{
                    "&.Mui-checked": classes.color9A291B,
                  }}
                />
              </Box> */}
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                      ]}
                    >
                      Beds
                    </Typography>
                    <Checkbox
                      value={"bed"}
                      onChange={(e) =>
                        onCheck(productType, e.target.value, setProductType)
                      }
                      checked={productType.includes("bed")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                      ]}
                    >
                      Fridge
                    </Typography>
                    <Checkbox
                      value={"fridge"}
                      onChange={(e) =>
                        onCheck(productType, e.target.value, setProductType)
                      }
                      checked={productType.includes("fridge")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                      ]}
                    >
                      Sofa
                    </Typography>
                    <Checkbox
                      value={"sofa"}
                      onChange={(e) =>
                        onCheck(productType, e.target.value, setProductType)
                      }
                      checked={productType.includes("sofa")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                      ]}
                    >
                      Washing Machine
                    </Typography>
                    <Checkbox
                      value={"washing-machine"}
                      onChange={(e) =>
                        onCheck(productType, e.target.value, setProductType)
                      }
                      checked={productType.includes("washing-machine")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box
                    className="d-flex justify-content-between w-100 align-items-center"
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                      ]}
                    >
                      Microwave
                    </Typography>
                    <Checkbox
                      value={"microwave"}
                      onChange={(e) =>
                        onCheck(productType, e.target.value, setProductType)
                      }
                      checked={productType.includes("microwave")}
                      sx={{
                        "&.Mui-checked": classes.color9A291B,
                      }}
                    />
                  </Box>
                  <Box>
                    <ButtonOutlined
                      onClick={onApplyFilter}
                      sx={[
                        classes.width95,
                        classes.fbBold,
                        classes.fontFamilySGB,
                        classes.fs16,
                        classes.borderRadius12,
                        classes.mt50,
                      ]}
                    >
                      Apply Filter
                    </ButtonOutlined>
                  </Box>
                </div>
              ) : null}
              <div
                className="col-lg-9 col-md-9 col-xs-12 container-scrollbar ps-0 pe-0"
                style={{
                  height: `calc(100vh - ${headerHeight}px)`,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div className="row properties-list mx-0  w-100 furniture-order-row">
                  <div className="properties-header mt-4 ">
                    <div>
                      <span>Orders</span>
                    </div>
                  </div>
                  {user?.role !== "admin" && (
                    <marquee
                      style={{
                        color: "#9a291b",
                        fontWeight: "bold",
                        marginBottom: "30px",
                      }}
                    >
                      Please complete your KYC to get your order delivered.{" "}
                      <Link
                        style={{
                          color: "orangeRed",
                        }}
                        to="/profile"
                      >
                        Go to KYC
                      </Link>
                    </marquee>
                  )}
                  <div className="ps-2 pe-2">
                    {bookings?.map((card, index) => (
                      <div
                        key={index}
                        className="card card-container"
                        style={{
                          minWidth: "97%",
                          maxWidth: "97%",
                        }}
                      >
                        <div className="row furniture-order-content">
                          <div className={!isMobile ? "col-4" : "col-sm-12"}>
                            <img
                              className="card-image"
                              src={card.image}
                              style={{
                                aspectRatio: !isMobile ? 2.5 : 0.5,
                                width: !isMobile ? "100%" : "100%",
                                margin: "auto",
                              }}
                            />
                          </div>
                          <div
                            className={
                              !isMobile
                                ? "col-8 pe-0 ps-0 d-flex flex-column justify-content-between"
                                : "col-sm-12 pe-0 ps-5 d-flex flex-column justify-content-between"
                            }
                          >
                            <Typography
                              sx={[
                                {
                                  textTransform: "capitalize",
                                },
                                styles.fs22,
                                styles.fontFamilyDS,
                                styles.fbBold,
                                styles.color9A291B,
                              ]}
                            >
                              {card.furniture.furnitureName}
                            </Typography>
                            <div className="col mt-2">
                              <div className="col-lg-3 col-md-3 col-sm-12">
                                <Typography
                                  sx={[
                                    styles.fs9,
                                    styles.fontFamilyDS,
                                    styles.colorADB0B6,
                                  ]}
                                >
                                  Order By
                                </Typography>
                                <div className="flex1 h-10 mt-2 ">
                                  <div className="col-4 avtar">
                                    <Avatar
                                      src={assetUrl + card?.user?.avatarImage}
                                      alt={card?.user?.name}
                                      sx={{
                                        width: "36px",
                                        height: "36px",
                                        borderRadius: "25px",
                                        marginRight: "5px",
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      !isMobile ? "col-8" : "col-sm-12"
                                    }
                                  >
                                    <Typography
                                      sx={[
                                        {
                                          textTransform: "capitalize",
                                        },
                                        styles.fs17,
                                        styles.color240501,
                                        styles.fontFamilyDS,
                                        styles.fontWeight500,
                                      ]}
                                    >
                                      {card?.user?.name}
                                    </Typography>
                                    <Typography
                                      sx={[
                                        styles.fs15,
                                        styles.color979797,
                                        styles.fontFamilyDS,
                                        styles.fontWeight500,
                                      ]}
                                    >
                                      {card?.user?.phone}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  !isMobile
                                    ? "col-2 order-duration"
                                    : "col-sm-12 order-duration"
                                }
                              >
                                <Typography
                                  sx={[
                                    styles.fs15,
                                    styles.fontFamilyDS,
                                    styles.colorADB0B6,
                                  ]}
                                >
                                  Duration
                                </Typography>

                                <Typography
                                  className="mt-2"
                                  sx={[
                                    styles.fs14,
                                    styles.color240501,
                                    styles.fontFamilyDS,
                                    styles.fontWeight500,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        paddingBottom: "8px",
                                        marginLeft: "5px",
                                      },
                                    },
                                  ]}
                                >
                                  {card?.periodNeeded}M
                                </Typography>
                              </div>
                              {user?.role === "admin" && (
                                <div
                                  className={
                                    !isMobile
                                      ? "col-2 order-duration"
                                      : "col-sm-12 order-duration"
                                  }
                                >
                                  <Typography
                                    sx={[
                                      styles.fs15,
                                      styles.fontFamilyDS,
                                      styles.colorADB0B6,
                                    ]}
                                  >
                                    Status
                                  </Typography>

                                  <Typography
                                    className="mt-2"
                                    sx={[
                                      styles.fs14,
                                      styles.color240501,
                                      styles.fontFamilyDS,
                                      styles.fontWeight500,
                                      {
                                        [theme.breakpoints.between("xs", "md")]:
                                          {
                                            paddingBottom: "8px",
                                            marginLeft: "5px",
                                          },
                                      },
                                    ]}
                                  >
                                    {card?.expired && card?.cancelledByUser
                                      ? "Cancelled by user"
                                      : card?.expired
                                      ? "Cancelled"
                                      : "Active"}
                                  </Typography>
                                </div>
                              )}
                              <div
                                className={!isMobile ? "col-2" : "col-sm-12"}
                              >
                                <Typography
                                  sx={[
                                    styles.fs9,
                                    styles.fontFamilyDS,
                                    styles.colorADB0B6,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...styles.fs15,
                                      },
                                    },
                                  ]}
                                >
                                  Order Date
                                </Typography>

                                <Typography
                                  className="mt-2"
                                  sx={[
                                    styles.fs14,
                                    styles.color240501,
                                    styles.fontFamilyDS,
                                    styles.fontWeight500,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...styles.fs17,
                                      },
                                    },
                                  ]}
                                >
                                  {moment(card?.created).format("DD/MM/YYYY")}
                                </Typography>
                              </div>
                              <div
                                className={!isMobile ? "col-2" : "col-sm-12"}
                              >
                                <Typography
                                  sx={[
                                    styles.fs9,
                                    styles.fontFamilyDS,
                                    styles.colorADB0B6,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...styles.fs15,
                                      },
                                    },
                                  ]}
                                >
                                  Order Time
                                </Typography>

                                <Typography
                                  className="mt-2"
                                  sx={[
                                    styles.fs14,
                                    styles.color240501,
                                    styles.fontFamilyDS,
                                    styles.fontWeight500,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...styles.fs17,
                                      },
                                    },
                                  ]}
                                >
                                  {moment(card?.created).format("hh:mm A")}
                                </Typography>
                              </div>
                              <div
                                className={!isMobile ? "col-2" : "col-sm-12"}
                              >
                                <Typography
                                  sx={[
                                    styles.fs9,
                                    styles.fontFamilyDS,
                                    styles.colorADB0B6,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...styles.fs15,
                                      },
                                    },
                                  ]}
                                >
                                  Location
                                </Typography>

                                <Typography
                                  className="mt-2"
                                  sx={[
                                    styles.fs14,
                                    styles.color240501,
                                    styles.fontFamilyDS,
                                    styles.fontWeight500,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...styles.fs17,
                                      },
                                    },
                                  ]}
                                >
                                  {card?.user?.address}
                                </Typography>
                              </div>
                              {user?.role === "admin" && (
                                <Typography
                                  className="mt-2 w-100"
                                  sx={[
                                    styles.fs14,
                                    styles.color240501,
                                    styles.fontFamilyDS,
                                    styles.fontWeight500,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...styles.fs17,
                                      },
                                    },
                                  ]}
                                >
                                  ID Proof(s):
                                  {card?.user?.idProof?.map((img, index) => (
                                    <div className="py-1">
                                      <a
                                        className="text-color979797"
                                        target="_blank"
                                        href={img}
                                      >
                                        - Proof {index + 1}
                                      </a>
                                    </div>
                                  ))}
                                </Typography>
                              )}
                            </div>

                            <div>
                              <div>
                                <p
                                  style={{
                                    color: "#9A291B",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Delivery Address
                                </p>
                              </div>

                              <ul className="ull">
                                <li>House: {card?.address.house_no}</li>
                                <li>Street: {card?.address.street}</li>
                                <li>Address: {card?.address.address}</li>

                                <li>Pin Code: {card?.address.pinCode}</li>
                                <li>State: {card?.address.state}</li>
                              </ul>
                            </div>

                            <div className="property-slider-buttons">
                              {!card?.expired && (
                                <ButtonContained
                                  onClick={() =>
                                    onCancelBooking(card._id, index)
                                  }
                                  sx={[
                                    styles.fontFamilySGB,
                                    styles.p10,
                                    styles.borderRadius12,
                                  ]}
                                  className="m-0 ms-4 me-1 my-2"
                                >
                                  Cancel Order
                                </ButtonContained>
                              )}
                              <ButtonOutlined
                                onClick={() =>
                                  navigate(`/furniture/${card.furniture._id}`)
                                }
                                sx={[
                                  styles.fontFamilySGB,
                                  styles.p10,
                                  styles.borderRadius12,
                                ]}
                                className="m-0 me-4 ms-1 my-2"
                              >
                                View Product
                              </ButtonOutlined>
                              {/* </Link> */}
                            </div>

                            {!card?.order_verified &&
                              user?.role === "admin" && (
                                <div className="flex1">
                                  <ButtonContained
                                    onClick={() => onVerify(card)}
                                    sx={[
                                      styles.fontFamilySGB,
                                      styles.p10,
                                      styles.borderRadius12,
                                      {
                                        width: "250px",
                                      }, // Set width to inherit
                                    ]}
                                    className="m-0 ms-4 me-1 my-2"
                                  >
                                    Verify Order
                                  </ButtonContained>

                                  <ButtonContained
                                    onClick={() => showKYC(card?.kycData)}
                                    sx={[
                                      styles.fontFamilySGB,
                                      styles.p10,
                                      styles.borderRadius12,
                                      {
                                        width: "250px",
                                      }, // Set width to inherit
                                    ]}
                                    className="m-0 ms-4 me-1 my-2"
                                  >
                                    View KYC Details
                                  </ButtonContained>

                                  <ButtonContained
                                    onClick={() =>
                                      sendKycReminder(
                                        card?.user?.name,
                                        card?.user.phone
                                      )
                                    }
                                    sx={[
                                      styles.fontFamilySGB,
                                      styles.p10,
                                      styles.borderRadius12,
                                      {
                                        width: "250px",
                                      }, // Set width to inherit
                                    ]}
                                    className="m-0 ms-4 me-1 my-2"
                                  >
                                    KYC Reminder
                                  </ButtonContained>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalSignUp
          stateFunc={() => setShowKyc(false)}
          state={showKyc}
          title="KYC Details"
        >
          <div>
            {kycDetails && (
              <ul>
                <li>Phone Number : {kycDetails?.contact_number}</li>

                <li>House Number : {kycDetails?.house_no}</li>

                <li>State : {kycDetails?.state}</li>

                <li>Address : {kycDetails?.state}</li>

                <li>Street : {kycDetails?.street}</li>

                <li>Pincode : {kycDetails?.pin_code}</li>

                <li>Created : {kycDetails?.created}</li>

                <li>Aadhar Number : {kycDetails?.adhar_number}</li>

                <li>
                  Address Proof :{" "}
                  <a href={kycDetails?.address_proof} target="_blank">
                    Link
                  </a>
                </li>

                <li>
                  Aadhar Photo :{" "}
                  <a href={kycDetails?.aadhar_card} target="_blank">
                    Link
                  </a>
                </li>

                <li>
                  PAN Photo :{" "}
                  <a href={kycDetails?.pan_card} target="_blank">
                    Link
                  </a>
                </li>

                <li>
                  Selfie :{" "}
                  <a href={kycDetails?.selfie} target="_blank">
                    Link
                  </a>
                </li>
              </ul>
            )}
          </div>
        </ModalSignUp>
      </div>
    </>
  );
};

export default FurnitureOrder;
