import React from "react";
import Header from "../../../components/Header";
import StickyFooterMobile from "../../../components/FooterMobile";
import StickyFooter from "../../../components/Footer";
import { useMediaQuery } from "react-responsive";
const RefferalTerms = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="container" style={{ padding: "125px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Rental Terms of Service{" "}
        </h2>
        <div>
          <p className="para-about-terms">
            {" "}
            These Rental Terms and Conditions ("Terms") govern the use of
            Gharwalah.com's rental services. Please read these Terms carefully
            before using our services. By engaging with Gharwala
            {/* <sup style={{ fontSize: "15px" }}>TM</sup> */}.com, you
            acknowledge and agree to abide by these Terms.
          </p>
          <br />
          <h4 className="h4-about-tems"> Our Comprehensive Services:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            Home Rental Solutions: Find our ideal rental home with ease. Our
            extensive listings cover a spectrum of properties, ensuring you
            discover the perfect match for your lifestyle and preferences.
            Buying and Selling of Properties: Whether you're looking to invest,
            sell, or buy property, our platform simplifies the real estate
            journey. We connect buyers and sellers, making transactions seamless
            and secure.
          </p>
          <br />
          <h4 className="h4-about-tems"> 1. Rental Agreement: </h4>
          <br />
          <p className="para-about-terms">
            {" "}
            - Gharwalah.com ("Service Provider") offers rental services to you
            ("Renter") under the terms and conditions outlined herein. - The
            rental agreement commences when the Renter places an order and is
            confirmed by the Service Provider.
          </p>
          <br />
          <h4 className="h4-about-tems"> 2. Rental Period:</h4>
          <br />
          <p className="para-about-terms">
            - The rental period begins on the delivery date specified by the
            Renter and ends when the Renter returns the items to the Service
            Provider. - The minimum rental period is [Specify duration]
            days/months.
          </p>
          <br />
          <h4 className="h4-about-tems"> 3. Rental Payments:</h4>
          <p className="para-about-terms">
            {" "}
            - The Renter agrees to make all rental payments on a prepaid basis.
            - The rental fees are payable monthly or by the agreed-upon payment
            schedule.
          </p>
          <br />
          <h6 className="h4-about-tems"> 4. Security Deposit:</h6>
          <br />
          <p className="para-about-terms">
            - A refundable security deposit is required. The amount will be
            specified in the rental agreement. - The deposit will be returned
            upon the satisfactory return of rented items, minus any deductions
            for damages, if applicable.
          </p>
          <br />
          <h4 className="h4-about-tems"> 5. Care and Maintenance:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            -- The Renter is responsible for the proper care and maintenance of
            the rented items. - Any damage or excessive wear and tear beyond
            normal usage may result in charges to the Renter.
          </p>
          <br />
          <h4 className="h4-about-tems"> 6. Relocation and Transfer:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            -The Service Provider offers relocation services within a specified
            radius (if applicable). Additional charges may apply for locations
            beyond this radius. - The Renter may transfer their subscription to
            a roommate, flatmate, or someone in the same building by following
            the documented process.
          </p>
          <br />
          <h4 className="h4-about-tems"> 7. Cancellation and Termination:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            - Cancellation requests must be submitted in writing to the Service
            Provider. - Cancellation during the rental period may result in
            applicable charges or penalties.
          </p>
          <br />
          <h4 className="h4-about-tems"> 8. Liability</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            - The Service Provider is not responsible for any loss, damage, or
            injury resulting from the use of rented items. - The Renter is
            advised to have appropriate insurance coverage.
          </p>
          <br />
          <h4 className="h4-about-tems"> 9. Force Majeure:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            - Neither party shall be held responsible for failure to perform its
            obligations under these Terms due to events beyond its control.
          </p>
          <br />
          <h4 className="h4-about-tems"> 10. Governing Law:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            These Terms are governed by the laws of [Specify jurisdiction]. Any
            disputes will be resolved in the courts of this jurisdiction.
          </p>
          <br />
          <h4 className="h4-about-tems">
            {" "}
            11. Amendments: Technicians for Home Appliance Repairs:
          </h4>
          <br />
          <p className="para-about-terms">
            {" "}
            Appliance breakdowns can disrupt your daily routine. With Gharwala
            {/* <sup style={{ fontSize: "15px" }}>TM</sup> */}.com, expert
            technicians are a call away. We offer quick and efficient repair
            services for all your home appliances, making sure your household
            operates smoothly.
          </p>
          <br />
          <h4 className="h4-about-tems"> Why Gharwalah.com?</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            -The Service Provider reserves the right to amend these Terms at any
            time. The Renter will be informed of any changes in advance.
          </p>
          <br />
          <h4 className="h4-about-tems"> 12. Entire Agreement:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            These Terms constitute the entire agreement between the parties and
            supersede any prior agreements or understandings.
            <br />
            By using Gharwalah.com's rental services, you acknowledge your
            understanding and agreement with these Terms. If you have any
            questions or concerns, please contact us for clarification.
          </p>
        </div>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </>
  );
};

export default RefferalTerms;
