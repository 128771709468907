import React from "react";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
const BuyingProperty = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="container" style={{ paddingTop: "125px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Buying a Property
        </h2>{" "}
        <br />
        <p className="para-about-terms">
          {" "}
          Discover Your Dream Home with Gharwala Property Buying – Your Gateway
          to Real Estate Excellence. Whether you're looking for a spacious
          villa, a charming apartment, or a cozy plot of land, Gharwala offers
          an extensive selection of properties for sale. Sellers, both owners
          and brokers, can confidently list their offerings, as Gharwala ensures
          a meticulous verification process for authenticity. Navigating the
          property buying journey has never been more straightforward – explore
          listings, scrutinize details, and connect with sellers, all through
          our intuitive website. It's important to note that while Gharwala
          facilitates the process, the final transaction is a direct agreement
          between the buyer and the seller. Empower your property buying
          experience with Gharwala – where your dream home awaits!
        </p>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </div>
  );
};

export default BuyingProperty;
