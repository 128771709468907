import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Avatar,
  Badge,
  Container,
} from "@mui/material";
import Header from "../../components/Header";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import ButtonContained from "../../components/Buttons/ButtonContained";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import EditSvg from "../../assets/images/edit.svg";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import { validateField } from "../../util/json/helpers";
import { toast } from "react-hot-toast";
import { addBrokerService } from "../../config/user";
import { useTheme } from "@mui/material/styles";
const AddBroker = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const inputRef = useRef();
  const [data, setData] = useState({
    photo: {},
  });
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [role, setRole] = useState("broker");
  const [pinCode, setPincode] = useState("");
  const [language, setLanguage] = useState("");
  const [desc, setDesc] = useState("");
  const navigate = useNavigate();

  const theme = useTheme();
  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true;

    if (name.trim() === "") {
      validateField("name", "Name field is required");
      validate = false;
    }

    if (phone.trim() === "") {
      validateField("phone", "Phone field is required");
      validate = false;
    }

    if (phone.trim().length != 10) {
      validateField("phone", "Phone Number should be 10 numbers only");
      validate = false;
    }

    if (!validate) {
      toast("Please fill required fields");
      return;
    }

    try {
      setLoader(true);
      const obj = {
        name,
        phone,
        role,
        password,
        email,
        address,
        pinCode,
        language,
        desc,
        avatarImage: data.photo,
      };
      const fd = new FormData();
      for (let key in obj) {
        fd.append(key, obj[key]);
      }
      const res = await addBrokerService(fd);

      if (res.data.success) {
        setLoader(false);
        setPincode("");
        setName("");
        setPhone("");
        setEmail("");
        setRole("");
        setPassword("");
        setLanguage("");
        setDesc("");
        setPincode("");

        toast.success("Broker Added Successfully");
        navigate("/");
      }
    } catch (err) {
      setLoader(false);
      // if (err.response.data.errors[0].msg) { toast(err.response.data.errors[0].msg) }
      console.log(err);
    }
  };

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="container-fluid px-0">
        <div className="properties-container">
          <div className="w-100">
            <div className="border-box px-0 responsive-view-add-broker">
              <Box
                className="d-flex align-items-center py-3 border-bottom p-4 w-100"
                sx={[styles.color9A291B]}
              >
                <Link
                  onClick={() => navigate(-1)}
                  className="d-flex align-items-center text-decoration-none"
                >
                  <KeyboardBackspaceIcon sx={[styles.color9A291B]} />
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "#950000",
                      marginTop: "10px",
                    }}
                  >
                    Listing
                  </p>

                  <Typography
                    sx={[
                      styles.color9A291B,
                      styles.fs24,
                      styles.fbBold,
                      styles.fontFamilyDS,
                    ]}
                    className="px-1"
                  ></Typography>
                </Link>
              </Box>
            </div>
            <div className="mt-4 mb-5 responsive-view-add-broker-content">
              <Container
                className="w-50 p-4"
                sx={[
                  styles.borderRadius24,
                  {
                    border: "1px solid #dee2e6",
                  },
                  {
                    [theme.breakpoints.between("xs", "md")]: [
                      {
                        border: "0px solid #fff",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      },
                    ],
                  },
                ]}
              >
                <Box className="text-center">
                  <Typography
                    sx={[
                      styles.borderRadius24,
                      styles.fontFamilySGB,
                      styles.fs30,
                      {
                        border: "1px solid #dee2e6",
                      },
                      {
                        [theme.breakpoints.between("xs", "md")]: [
                          {
                            fontSize: "24px",
                            border: "none",
                          },
                        ],
                      },
                    ]}
                  >
                    Add New Broker
                  </Typography>
                </Box>
                <Box
                  sx={[styles.mt40]}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <input
                    ref={inputRef}
                    type="file"
                    className="d-none"
                    accept="image/*"
                    onChange={(e) =>
                      setData({ ...data, photo: e.target.files[0] })
                    }
                  />
                  <Badge
                    overlap="circular"
                    className="d-flex justify-content-center align-items-center"
                    onClick={() => inputRef.current.click()}
                    sx={{
                      width: "90px",
                      height: "90px",
                      border: "1px solid #ADB0B6",
                      borderRadius: "45px",
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={<img src={EditSvg} />}
                  >
                    {data.photo.name ? (
                      <Avatar
                        alt="Profile image"
                        src={URL.createObjectURL(data.photo)}
                        className="h-100 w-100"
                      />
                    ) : (
                      <Person2OutlinedIcon
                        sx={{ color: "#ADB0B6" }}
                        fontSize="large"
                      />
                    )}
                  </Badge>
                  <Box className="w-100 mt-4">
                    <Box
                      sx={[
                        styles.dflex,
                        {
                          [theme.breakpoints.between("xs", "md")]: [
                            styles.dBlock,
                          ],
                        },
                      ]}
                    >
                      <Box className="col-lg-6 col-xs-12 col-md-6 px-2">
                        <Typography
                          sx={[
                            styles.fs16,
                            styles.fontFamilySG,
                            styles.color240501,
                          ]}
                          className="mb-2"
                        >
                          Full Name
                        </Typography>
                        <OutlinedFormInput
                          placeholder={"Rahul Sharma"}
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          autocomplete="off"
                          name="name"
                          sx={[
                            { height: "45px" },
                            {
                              [theme.breakpoints.between("xs", "sm")]: [
                                {
                                  maxWidth: "330px",
                                  minWidth: "330px",
                                },
                              ],
                            },
                          ]}
                        />
                      </Box>
                      <Box className="col-lg-6 col-xs-12 col-md-6 px-2">
                        <Typography
                          sx={[
                            styles.fs16,
                            styles.fontFamilySG,
                            styles.color240501,
                          ]}
                          className="mb-2"
                        >
                          Email
                        </Typography>
                        <OutlinedFormInput
                          placeholder={"xyz@gmail.com"}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          autocomplete="off"
                          name="email"
                          sx={[
                            { height: "45px" },
                            {
                              [theme.breakpoints.between("xs", "sm")]: [
                                {
                                  maxWidth: "330px",
                                  minWidth: "330px",
                                },
                              ],
                            },
                          ]}
                        />
                      </Box>
                    </Box>
                    <Box className="px-2 mt-3">
                      <Typography
                        sx={[
                          styles.fs16,
                          styles.fontFamilySG,
                          styles.color240501,
                        ]}
                        className="mb-2"
                      >
                        Phone Number
                      </Typography>
                      <OutlinedFormInput
                        type="number"
                        placeholder={"9087654321"}
                        inputProps={{
                          type: "number",
                        }}
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        autocomplete="off"
                        name="phone"
                        sx={[
                          { height: "45px" },
                          {
                            [theme.breakpoints.between("xs", "sm")]: [
                              {
                                maxWidth: "330px",
                                minWidth: "330px",
                              },
                            ],
                          },
                        ]}
                      />
                    </Box>
                    <Box className="px-2 mt-3">
                      <Typography
                        sx={[
                          styles.fs16,
                          styles.fontFamilySG,
                          styles.color240501,
                        ]}
                        className="mb-2"
                      >
                        Full Address
                      </Typography>
                      <OutlinedFormInput
                        multiline={true}
                        rows={2}
                        placeholder={"House no."}
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        autocomplete="off"
                        name="address"
                        sx={[
                          { height: "initial" },
                          {
                            [theme.breakpoints.between("xs", "sm")]: [
                              {
                                maxWidth: "330px",
                                minWidth: "330px",
                              },
                            ],
                          },
                        ]}
                      />
                    </Box>
                    <Box className="px-2 mt-3">
                      <Typography
                        sx={[
                          styles.fs16,
                          styles.fontFamilySG,
                          styles.color240501,
                        ]}
                        className="mb-2"
                      >
                        PIN Code
                      </Typography>
                      <OutlinedFormInput
                        type="number"
                        placeholder={"700001"}
                        inputProps={{
                          type: "number",
                        }}
                        value={pinCode}
                        onChange={(e) => {
                          setPincode(e.target.value);
                        }}
                        autocomplete="off"
                        name="pinCode"
                        sx={[
                          { height: "45px" },
                          {
                            [theme.breakpoints.between("xs", "sm")]: [
                              {
                                maxWidth: "330px",
                                minWidth: "330px",
                              },
                            ],
                          },
                        ]}
                      />
                    </Box>
                    <Box className="px-2 mt-3">
                      <Typography
                        sx={[
                          styles.fs16,
                          styles.fontFamilySG,
                          styles.color240501,
                        ]}
                        className="mb-2"
                      >
                        Lanugage
                      </Typography>
                      <OutlinedFormInput
                        placeholder={"Hindi, Bengali"}
                        value={language}
                        onChange={(e) => {
                          setLanguage(e.target.value);
                        }}
                        autocomplete="off"
                        name="language"
                        sx={[
                          { height: "45px" },
                          {
                            [theme.breakpoints.between("xs", "sm")]: [
                              {
                                maxWidth: "330px",
                                minWidth: "330px",
                              },
                            ],
                          },
                        ]}
                      />
                    </Box>
                    <Box className="px-2 mt-3">
                      <Typography
                        sx={[
                          styles.fs16,
                          styles.fontFamilySG,
                          styles.color240501,
                        ]}
                        className="mb-2"
                      >
                        Description
                      </Typography>
                      <OutlinedFormInput
                        multiline={true}
                        sx={[
                          { height: "initial" },
                          {
                            [theme.breakpoints.between("xs", "sm")]: [
                              {
                                maxWidth: "330px",
                                minWidth: "330px",
                              },
                            ],
                          },
                        ]}
                        rows={4}
                        placeholder={"write..."}
                        value={desc}
                        onChange={(e) => {
                          setDesc(e.target.value);
                        }}
                        autocomplete="off"
                        name="desc"
                      />
                    </Box>
                    <Box className=" mt-3">
                      <ButtonContained
                        sx={[
                          styles.fs16,
                          styles.borderRadius12,
                          { padding: "0.75rem" },
                        ]}
                        onClick={onSubmit}
                      >
                        Continue
                      </ButtonContained>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBroker;
