// import React from "react";
// import {
//   Box,
//   Typography,
//   Grid,
//   Paper,
//   IconButton,
//   Card,
//   CardContent,
// } from "@mui/material";
// import { ArrowBack, ArrowForward } from "@mui/icons-material";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
// import VillaOutlinedIcon from "@mui/icons-material/VillaOutlined";
// import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
// import RedLine from "../../assets/images/property-types-red.png";
// import PropertyHome from "../../assets/images/property-home-icon.svg";
// import PropertyOffice from "../../assets/images/property-office-icon.svg";
// import Villa from "../../assets/images/property-villa-icon.svg";
// import Apartment from "../../assets/images/property-apartment-icon.svg";
// import { getProperties } from "../../redux/action/property";
// const propertyTypes = [
//   { label: "Houses", properties: 100, image: PropertyHome },
//   { label: "Offices", properties: 50, image: PropertyOffice },
//   { label: "Villas", properties: 50, image: Villa },
//   { label: "Apartment", properties: 1000, image: Apartment },
// ];

// useEffect(() => {
//   // Fetch properties when the component mounts
//   dispatch(getProperties());
// }, [dispatch]);

// const handleContact = (id) => {
//   // Navigate to the properties details page
//   navigate(`/properties/${id}`);
// };
// const ExplorePropertyType = () => {
//   return (
//     <Box sx={{
//       paddingInline: {
//         xs: "30px", // For small screens (mobile)
//         md: "80px", // For medium and larger screens (desktop)
//       },
//       paddingBlock: "50px", // Constant for all screens
//       backgroundColor: "#00436A",
//       color: "#fff",
//     }}>
//       <Grid container alignItems="center">
//         <Grid item xs={12} md={4}>
//           {/* Section Title */}
//           <Typography variant="h4" fontWeight="bold" gutterBottom>
//             Explore by
//             <br /> Property Type
//           </Typography>
//           <Typography>
//             <img src={RedLine} alt="line-img" />
//           </Typography>
//           <Typography
//             variant="body1"
//             color="rgba(255, 255, 255, 0.7)"
//             mb={4}
//             mt={4}
//           >
//             Gharwala has all type of properties
//             <br /> including houses, offices, Villas and apartments.
//           </Typography>

//           {/* Arrow Buttons */}
//           {/* <Box display="flex" gap={2}>
//             <IconButton
//               sx={{
//                 backgroundColor: "#fff",
//                 color: "#003366",
//                 "&:hover": { backgroundColor: "#f0f0f0" },
//               }}
//             >
//               <ArrowBack />
//             </IconButton>
//             <IconButton
//               sx={{
//                 backgroundColor: "#fff",
//                 color: "#003366",
//                 "&:hover": { backgroundColor: "#f0f0f0" },
//               }}
//             >
//               <ArrowForward />
//             </IconButton>
//           </Box> */}
//         </Grid>

//         {/* Property Cards */}
//         <Grid item xs={12} md={8}>
//           <Grid container spacing={2}>
//             {propertyTypes.map((type, index) => (
//               <Grid item xs={6} md={3} key={index}>
//                 <Card
//                   sx={{
//                     paddingInline: "16px",
//                     paddingBlock: "24px",
//                     textAlign: "center",
//                     borderRadius: "12px",
//                     transition: "transform 0.3s ease-in-out",
//                     "&:hover": { transform: "scale(1.05)" },
//                     cursor:"pointer"
//                   }}
//                   elevation={4}
//                 >
//                   <CardContent>
//                     <Box display="flex" justifyContent="center" mb={2}>
//                       <img
//                         src={type.image}
//                         alt={`${type.label} icon`}
//                       />
//                     </Box>
//                     <Typography variant="h6" fontWeight="bold">
//                       {type.label}
//                     </Typography>
//                     <Typography variant="body2" color="textSecondary">
//                       {type.properties} Properties
//                     </Typography>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default ExplorePropertyType;
import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RedLine from "../../assets/images/property-types-red.png";
import PropertyHome from "../../assets/images/property-home-icon.svg";
import PropertyOffice from "../../assets/images/property-office-icon.svg";
import Villa from "../../assets/images/property-villa-icon.svg";
import Apartment from "../../assets/images/property-apartment-icon.svg";
import { getProperties } from "../../redux/action/property";

// Property types with mock images and static values
const propertyTypes = [
  { label: "Houses", properties: 100, image: PropertyHome, propertyType: "house" },
  { label: "Flat", properties: 50, image: PropertyOffice, propertyType: "flat" },
  { label: "Villas", properties: 50, image: Villa, propertyType: "villa" },
  { label: "Apartment", properties: 1000, image: Apartment, propertyType: "apartment" },
];

const ExplorePropertyType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [properties, setProperties] = useState([]); 
  const [serviceType, setServiceType] = useState("rent"); 

  const allProperties = useSelector((state) => state.properties);

  const lat = 28.7041;  
  const lng = 77.1025; 

  useEffect(() => {
    
    dispatch(getProperties());
  }, [dispatch]);

  useEffect(() => {
   
    setProperties(allProperties || []); 
  }, [allProperties]);

  // Mark handleContact as async to use await
  const handleContact = async (propertyType) => {
    const maxPrice = 1000000; 


    const obj = { lat, lng };

 
    if (Array.isArray(properties)) {
      const propertiesForService = properties.filter(
        (property) => property.type === propertyType && property.serviceType === serviceType
      );

      console.log("Filtered Properties:", propertiesForService);
    
      // Build query and navigate
      const query = `propertyType=${propertyType}`;
      navigate(`/properties?${query}`);
    } else {
      console.error("Properties is not an array:", properties);
    }
  };

  return (
    <Box
      sx={{
        paddingInline: {
          xs: "30px", 
          md: "80px", 
        },
        paddingBlock: "50px", 
        backgroundColor: "#00436A",
        color: "#fff",
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} md={4}>
          {/* Section Title */}
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Explore by
            <br /> Property Type
          </Typography>
          <Typography>
            <img src={RedLine} alt="line-img" />
          </Typography>
          <Typography
            variant="body1"
            color="rgba(255, 255, 255, 0.7)"
            mb={4}
            mt={4}
          >
            Gharwala has all types of properties
            <br /> including houses, offices, villas, and apartments.
          </Typography>
        </Grid>

        {/* Property Cards */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {propertyTypes.map((type, index) => (
              <Grid item xs={6} md={3} key={index}>
                <Card
                  sx={{
                    paddingInline: "16px",
                    paddingBlock: "24px",
                    textAlign: "center",
                    borderRadius: "12px",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": { transform: "scale(1.05)" },
                    cursor: "pointer",
                  }}
                  elevation={4}
                  onClick={() => handleContact(type.propertyType)}
                >
                  <CardContent>
                    <Box display="flex" justifyContent="center" mb={2}>
                      <img
                        src={type.image}
                        alt={`${type.label} icon`}
                      />
                    </Box>
                    <Typography variant="h6" fontWeight="bold">
                      {type.label}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {type.properties} Properties
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExplorePropertyType;

