import React, { useEffect, useState } from "react";
import DetailHeader from "../../components/DetailHeader";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import classes from "../../styles/styles.js";
import Button from "@mui/material/Button";
import ButtonContained from "../../components/Buttons/ButtonContained";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import { useTheme } from "@mui/material/styles";
import BedSvg from "../../assets/images/bed.svg";
import BathSvg from "../../assets/images/bed.svg";
import KitchenSvg from "../../assets/images/kitchen.svg";
import { Avatar } from "@mui/material";
import {
  addRatingService,
  onGetPropertyById,
  updatePropertyStatus,
  viewPropertyContactService,
} from "../../config/property";
import styles from "../../styles/styles.js";
import StickyFooter from "../../components/Footer";
import { assetUrl, privateReq } from "../../config/axiosConfig";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { toast } from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import ModalSignUp from "../../components/ModalSignup";
import SignUp from "../../components/Signup";
import ModalLogin from "../../components/ModalLogin";
import Login from "../../components/Login";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Watermark } from "@hirohe/react-watermark";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CenterFocusStrong, CenterFocusWeak } from "@mui/icons-material";
import axios from "axios";
import { getProperties } from "../../redux/action/property.js";
import "./index.css";
import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// If you want you can use SCSS instead of css
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { Helmet } from "react-helmet";
import ModalPropertyVerification from "../../components/ModalPropertyVerification/index.js";

const PropertyDetails = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const theme = useTheme();
  const params = useParams();
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [closeModal, setCloseModal] = useState(false);
  const [propertyVerificationModal, setPropertyVerificationModal] =
    useState(false);
  // const [closeLoginModal, setCloseLoginModal] = useState(false);
  const [disableShortlist, setShortlist] = useState(false);

  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // const { properties } = useSelector((state) => state.property);
  const [properties, setProperties] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [showContact, setShowContact] = useState(false);
  const [reviewGiven, setreviewGiven] = useState(false);
  const [reviews, setReviews] = useState([]);
  const navigate = useNavigate();
  const [watermarkedImages, setWatermarkedImages] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [myRating, setMyRating] = useState({
    rate: 0,
    desc: "",
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (propertyDetails) {
      const title = `${propertyDetails?.bedrooms} BHK ${propertyDetails?.propertyType} is on ${propertyDetails?.wantto} for Rs ${propertyDetails?.expectedMonthlyRent}/- in ${propertyDetails?.locality?.coordinates[2]} available from immediately | Gharwala your One step solution for your housing and furniture rental search gharwalah.com`;

      console.log(title);
      document.title = title;
    } else {
      document.title = "Property Details Gharwala";
    }
  }, [
    propertyDetails?.bedrooms,
    propertyDetails?.propertyType,
    propertyDetails?.wantto,
    propertyDetails?.expectedMonthlyRent,
    propertyDetails?.locality?.coordinates,
  ]);

  const getPropertiesInitial = async () => {
    // event.preventDefault();
    try {
      const data = {
        priceRange: [1, 10000000],
      };
      const resp = await privateReq.get("/get-properties");
      setProperties(resp.data);
    } catch (e) {
      console.log(e);
    }
  };

  function capitalizeFLetter(word) {
    if (word) {
      return word[0].toUpperCase() + word.slice(1);
    }
  }

  useEffect(() => {
    getPropertiesInitial();
  }, []);

  useEffect(() => {
    onGetProperty();
    (() => {
      privateReq
        .get(`/property-list/shortlist/${params?.id}`)
        .then((res) => {
          console.log("exits", res);
          setShortlist(res.data.exists);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
    // console.log('details' , propertyDetails)
  }, [params]);

  function removeLocations(inputString) {
    if (inputString) {
      // Remove "West Bengal", "India", and any postal code at the end of the string
      let modifiedString = inputString
        .replace(/West Bengal,?\s?|India,?\s?|\d{6}/g, "")
        .trim();

      // Remove any extra commas and spaces that might result from the replacements
      modifiedString = modifiedString
        .replace(/,\s*,/g, ", ")
        .replace(/,\s*$/, "")
        .trim();

      return modifiedString;
    }
    return inputString;
  }

  const onGetProperty = async () => {
    const filterQuery = params.id;
    let res = await onGetPropertyById(filterQuery);
    if (res.data) {
      setPropertyDetails(res.data.post);
      setShowContact(res.data.showContact);
      setreviewGiven(res.data.reviewGiven);
      setReviews(res.data.reviews);
      setLoader(false);
    }
  };

  const handleTextChange = (name, val) => {
    setMyRating({ ...myRating, [name]: val });
  };
  const onViewContact = async (e) => {
    e.preventDefault();
    try {
      if (user === null) {
        setPropertyVerificationModal(true);
        return;
      }
      const res = await viewPropertyContactService({
        propertyId: propertyDetails._id,
      });
      if (res.data.status) {
        setShowContact(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onAddReview = async (e) => {
    e.preventDefault();
    try {
      const res = await addRatingService({
        ...myRating,
        id: params.id,
      });
      if (res.data.status) {
        toast.success("Review added successfully!");
        setMyRating({
          rate: 0,
          desc: "",
        });
        await onGetProperty();
        setreviewGiven(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const shortlist = () => {
    privateReq
      .post("/property-list/shortlist", {
        propertyId: propertyDetails?._id,
      })
      .then((res) => {
        if (res.data === "already added") {
          toast.error("Already shortlisted this property");
        } else if (res.data.status === true) {
          toast.success("Shortlisted");
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onUpdateStatus = async (status) => {
    try {
      const res = await updatePropertyStatus({
        active: !propertyDetails?.status,
        id: propertyDetails?._id,
      });
      if (res.data.success) {
        toast.success(` ${status ? "Deactivated" : "Activated"} successfully!`);
        onGetProperty();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setOpenImage = (index) => {
    setSelectedImage(index);
    if (index > 2) {
      setOpen(true);
    }
  };

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        // innerRef={headerRef}
      />

      <div style={{ paddingBottom: "45px" }} className="container-new">
        {user?.role === "admin" && isMobile && (
          <div className="mb-3">
            <ButtonContained
              sx={[
                styles.fontFamilySGB,
                propertyDetails?.status && styles.btnBgGreen,
                styles.p10,
                styles.borderRadius12,
              ]}
              className="m-0 my-2 mx-1"
              onClick={(e) => {
                e.preventDefault();
                onUpdateStatus(propertyDetails?.status);
              }}
            >
              {propertyDetails?.status ? "Deactivate" : "Activate"}
            </ButtonContained>
          </div>
        )}
        <div className="row">
          <div className="col-md-8 col-xs-12">
            {selectedImage !== null ? (
              <div>
                <Watermark
                  gutter={150}
                  rotate={0}
                  text="Gharwalah.com"
                  textSize={50}
                  multiline={false}
                >
                  <img
                    src={propertyDetails?.propertyImages[selectedImage]}
                    style={{
                      width: "100%",
                      aspectRatio: 2,
                      height: undefined,
                      borderRadius: "10px",
                    }}
                  />
                </Watermark>
              </div>
            ) : (
              <div>
                <Watermark
                  gutter={150}
                  rotate={0}
                  text="Gharwalah.com"
                  textSize={50}
                  multiline={false}
                >
                  <img
                    src={propertyDetails?.propertyImages[0]}
                    alt={
                      propertyDetails?.title +
                      " " +
                      propertyDetails?.propertyType +
                      " for " +
                      propertyDetails?.wantto +
                      " " +
                      propertyDetails?.locality?.coordinates[2]
                    }
                    style={{
                      width: "100%",
                      aspectRatio: 2,
                      height: undefined,
                      borderRadius: "10px",
                    }}
                  />
                </Watermark>
              </div>
            )}
            <LightGallery
              onInit={() => console.log("LightGallery initialized")}
              speed={500}
              plugins={[lgThumbnail, lgZoom]}
            >
              {propertyDetails?.propertyImages.map((image, index) => (
                <a
                  className="col-3 lightgalleryjs-image"
                  href={image}
                  data-lightgallery="item"
                  data-src={image}
                >
                  {index === 3 ? (
                    <a
                      className="d-block w-100 h-100"
                      href={image}
                      data-src={image}
                      style={{
                        zIndex: 1,
                        position: "relative",
                        backgroundColor: "green",
                        marginTop: "15px",
                        borderRadius: "10px",
                        display: "block",
                      }}
                    >
                      <img
                        src={image}
                        alt={
                          propertyDetails?.title +
                          " " +
                          propertyDetails?.propertyType +
                          " for " +
                          propertyDetails?.wantto +
                          " " +
                          propertyDetails?.locality?.coordinates[2] +
                          " " +
                          index
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                        }}
                      />
                      <span
                        className="d-flex align-items-center justify-content-center slider-title"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          zIndex: 3,
                          backgroundColor: "rgba(0, 0, 0, 0.75)",
                          borderRadius: "10px",
                          color: "#fff",
                          cursor: "pointer",
                          height: "auto",
                        }}
                        href={image}
                        data-src={image}
                      >
                        +{propertyDetails.propertyImages.length - 4} photos
                      </span>
                    </a>
                  ) : (
                    <img
                      src={image}
                      alt={
                        propertyDetails?.title +
                        " " +
                        propertyDetails?.propertyType +
                        " for " +
                        propertyDetails?.wantto +
                        " " +
                        propertyDetails?.locality?.coordinates[2] +
                        " " +
                        index
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        marginTop: "15px",
                        cursor: "pointer",
                        display: index >= 3 ? "none" : "block",
                      }}
                    />
                  )}
                </a>
              ))}
            </LightGallery>

            <div
              className={
                !isMobile
                  ? "property-detail-bottom-left"
                  : "property-detail-bottom-left-mobile"
              }
            >
              <div className="property-detail-title mt-5">
                <span className={"property-detail-title"}>
                  {propertyDetails?.bedrooms} BHK for{" "}
                  {propertyDetails?.wantto !== "sell" ? "Rent " : "Sell "} in{" "}
                  {removeLocations(propertyDetails?.locality.coordinates[2])}
                </span>
              </div>
              <div className="property-detail-pricing">
                <span className="property-detail-price">
                  ₹
                  {propertyDetails?.expectedMonthlyRent !== undefined
                    ? Intl.NumberFormat("en-IN").format(
                        propertyDetails.expectedMonthlyRent
                      )
                    : "Loading..."}
                  {propertyDetails &&
                    propertyDetails.wantto !== "sell" &&
                    "/month"}
                </span>
              </div>
            </div>
            <div className="desc-stars">
              <div>
                <span className="property-detail-address">
                  {[
                    propertyDetails?.houseNo,
                    propertyDetails?.apartmentSociety,
                    // propertyDetails?.locality, todo
                    propertyDetails?.landMark,
                    // propertyDetails?.pinCode,
                  ]
                    .filter((i) => i !== "")
                    .join(", ")}
                </span>
              </div>
            </div>

            {propertyDetails?.wantto !== "sell" && (
              <span
                style={{ marginTop: "20px" }}
                className="property-detail-security"
              >
                Security Deposit ₹ {propertyDetails?.expectedSecurityDeposit}{" "}
              </span>
            )}

            {/* <div className="rviews-btn d-flex flex-wrap">
							{propertyDetails?.facilities &&
								propertyDetails?.facilities
									.split(",")
									.map((item) => (
										<Button
											sx={[
												styles.containedPropertyDetailButton2,
											]}
											variant="contained"
										>
											{String(item).trim()}
										</Button>
									))}
						</div> */}
            <div className="bed-bath">
              {!propertyDetails?.bathrooms && !propertyDetails?.kitchens && (
                <div
                  className="bed-bath-items"
                  style={{ display: "flex", gap: "2" }}
                >
                  <span className="bed-bath-title">Type</span>
                  <span className="bed-bath-text">
                    {propertyDetails?.propertyType.toUpperCase()}
                  </span>
                </div>
              )}
              {propertyDetails?.bedrooms &&
                (propertyDetails?.bathrooms || propertyDetails?.kitchens) && (
                  <div className="bed-bath-items">
                    <span className="bed-bath-title">Bedroom</span>
                    <span className="bed-bath-text">
                      <img src={BedSvg} /> {propertyDetails?.bedrooms}
                    </span>
                  </div>
                )}
              {propertyDetails?.bathrooms && (
                <div className="bed-bath-items">
                  <span className="bed-bath-title">Bathroom</span>
                  <span className="bed-bath-text">
                    <img src={BathSvg} /> {propertyDetails?.bathrooms}
                  </span>
                </div>
              )}
              {propertyDetails?.kitchens && (
                <div className="bed-bath-items">
                  <span className="bed-bath-title">Kitchen</span>
                  <span className="bed-bath-text">
                    <img src={KitchenSvg} /> {propertyDetails?.kitchens || 1}
                  </span>
                </div>
              )}
            </div>

            {propertyDetails?.desc && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                  }}
                  className="property-description"
                >
                  <h4>About Property</h4>
                  <span
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    {propertyDetails?.desc}
                  </span>

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    {propertyDetails?.furnishingDetails !== null && (
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          backgroundColor: "#f0f9ff",
                          paddingInline: "8px",
                          borderRadius: "8px",
                        }}
                      >
                        <p
                          style={{
                            lineHeight: "20px",
                            marginTop: "10px",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Furnishing Details :{" "}
                        </p>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "bolder",
                          }}
                        >
                          {propertyDetails?.furnishingDetails === "1"
                            ? "Furnished"
                            : propertyDetails?.furnishingDetails === "2"
                            ? "Semi Furnished"
                            : "Unfurnished "}
                        </span>
                      </div>
                    )}

                    {propertyDetails?.water !== null &&
                      propertyDetails?.water !== "" && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            backgroundColor: "#f0f9ff",
                            paddingInline: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "20px",
                              marginTop: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Water :{" "}
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {propertyDetails?.water}
                          </span>
                        </div>
                      )}

                    {propertyDetails?.floorNumber !== null &&
                      propertyDetails?.floorNumber !== "" && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            backgroundColor: "#f0f9ff",
                            paddingInline: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "20px",
                              marginTop: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Floor Number :{" "}
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {propertyDetails?.floorNumber}
                          </span>
                        </div>
                      )}

                    {/* <div style={{display: "flex", gap: "20px"}}>
                    <p style={{lineHeight: "20px", marginTop: "10px", fontWeight: "bolder", fontSize: "18px"}}>Tenant : </p>
                    <span style={{display: "flex", alignItems: "center"}}>{propertyDetails?.tenant}</span>
                  </div> */}
                    {propertyDetails?.buildupArea !== null &&
                      propertyDetails?.buildupArea !== "" && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            backgroundColor: "#f0f9ff",
                            paddingInline: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "20px",
                              marginTop: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Buildup Area :{" "}
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {propertyDetails?.buildupArea}
                            &nbsp;sq. Ft.
                          </span>
                        </div>
                      )}

                    {propertyDetails?.availableFrom !== null &&
                      propertyDetails?.availableFrom !== "" && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            backgroundColor: "#f0f9ff",
                            paddingInline: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "20px",
                              marginTop: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Available From :{" "}
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {propertyDetails?.availableFrom}
                          </span>
                        </div>
                      )}

                    {propertyDetails?.propertyAge !== null &&
                      propertyDetails?.propertyAge !== "" && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            backgroundColor: "#f0f9ff",
                            paddingInline: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "20px",
                              marginTop: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Property Age :{" "}
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {propertyDetails?.propertyAge}
                          </span>
                        </div>
                      )}

                    {propertyDetails?.parking !== null && (
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          backgroundColor: "#f0f9ff",
                          paddingInline: "8px",
                          borderRadius: "8px",
                        }}
                      >
                        <p
                          style={{
                            lineHeight: "20px",
                            marginTop: "10px",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Car Parking :{" "}
                        </p>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "bolder",
                          }}
                        >
                          {propertyDetails?.parking === "1" ? "Yes" : "No"}
                        </span>
                      </div>
                    )}

                    {propertyDetails?.facing !== null &&
                      propertyDetails?.facing !== "" && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            backgroundColor: "#f0f9ff",
                            paddingInline: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "20px",
                              marginTop: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Facing :{" "}
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {propertyDetails?.facing}
                          </span>
                        </div>
                      )}

                    {propertyDetails?.flooring !== null &&
                      propertyDetails?.flooring !== "" && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            backgroundColor: "#f0f9ff",
                            paddingInline: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "20px",
                              marginTop: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Flooring :{" "}
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {propertyDetails?.flooring}
                          </span>
                        </div>
                      )}

                    {propertyDetails?.bathroom !== null &&
                      propertyDetails?.bathroom !== "" && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            backgroundColor: "#f0f9ff",
                            paddingInline: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "20px",
                              marginTop: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Bathroom :{" "}
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {propertyDetails?.bathroom}
                          </span>
                        </div>
                      )}

                    {propertyDetails?.powerBackup !== null &&
                      propertyDetails?.powerBackup !== "" && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            backgroundColor: "#f0f9ff",
                            paddingInline: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "20px",
                              marginTop: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Power Backup :{" "}
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {propertyDetails?.powerBackup}
                          </span>
                        </div>
                      )}

                    {propertyDetails?.overlooking !== null &&
                      propertyDetails?.overlooking !== "" && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            backgroundColor: "#f0f9ff",
                            paddingInline: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "20px",
                              marginTop: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Over looking :{" "}
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {propertyDetails?.overlooking}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </>
            )}
            {(propertyDetails?.furnishingDetails === "1" ||
              propertyDetails?.furnishingDetails === "2") &&
              propertyDetails?.furnitureDetails.length > 0 && (
                <>
                  <h4
                    className="amenties-heading"
                    style={{ marginTop: "35px" }}
                  >
                    Furnishing Details
                  </h4>
                  <div>
                    <ul className="property-amenties-items">
                      {propertyDetails?.furnitureDetails.map((item) => (
                        <li>
                          {/* <img src={PoolSvg} /> */}
                          <span
                            className="furnture-span"
                            style={{
                              color: "black",
                            }}
                          >
                            {item.key} : {item.value}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}

            {propertyDetails?.accessoryList.length > 0 && (
              <>
                <h4 className="amenties-heading" style={{ marginTop: "35px" }}>
                  Society Accessories
                </h4>
                <div>
                  <ul className="property-amenties-items">
                    {propertyDetails?.accessoryList.map((item) => (
                      <li>
                        {/* <img src={PoolSvg} /> */}
                        <span
                          className="furnture-span"
                          style={{
                            color: "black",
                          }}
                        >
                          {item.accessoryName}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            {propertyDetails?.usp.length > 1 && (
              <>
                <h4 className="amenties-heading" style={{ marginTop: "35px" }}>
                  USP
                </h4>
                <div>
                  <ul className="property-amenties-items">
                    {propertyDetails?.usp.map((item) => (
                      <li>
                        {/* <img src={PoolSvg} /> */}
                        <span
                          className="text-capitalize furnture-span"
                          style={{ color: "black" }}
                        >
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}

            {/* <h4
              className="amenties-heading"
              style={{ marginTop: "35px", marginBottom: "20px" }}
            >
              Places Near By
            </h4>
            <div className="near-by-you">
              <button
                type="button"
                className="btn btn-outline-primary"
                style={{
                  backgroundColor: "#e2b4af",
                  border: "1px solid #9A291B",
                  color: "#9A291B",
                  marginRight: "15px",
                }}
              >
                Burger Point
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                class="btn"
                style={{
                  backgroundColor: "#e2b4af",
                  border: "1px solid #9A291B",
                  color: "#9A291B",
                }}
              >
                Indian Bank
              </button>
            </div> */}
            {/* <>
              <h4 className="amenties-heading" style={{ marginTop: "35px" }}>
                Reviews
              </h4>
              <div>
                {reviews.length > 0 ? (
                  reviews.map((item) => (
                    <>
                      <div className="reviews-section-1">
                        <div className="review-user-section">
                          <Avatar
                            src={assetUrl + item.user?.avatarImage}
                            className="review-image"
                            alt={item.user.name}
                          />
                        </div>
                        <div className="review-stars-section">
                          <div className="review-user-name">
                            <div>
                              <span>{item.user.name}</span>
                            </div>
                            <Rating
                              value={item.rating}
                              sx={{
                                color: "#9A291B",
                              }}
                              readOnly
                              size="small"
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="review-sub-title">{item.desc}</p>
                      </div>
                    </>
                  ))
                ) : (
                  <span className="nearby-list-address">No reviews yet</span>
                )}
              </div>
            </> */}
            {/* {showContact && !reviewGiven && (
              <div>
                <h4 className="amenties-heading" style={{ marginTop: "35px" }}>
                  Add a review
                </h4>
                <Rating
                  value={myRating.rate}
                  sx={{
                    color: "#9A291B",
                  }}
                  onChange={(e) =>
                    handleTextChange("rate", parseInt(e.target.value))
                  }
                  size="large"
                />
                <TextareaAutosize
                  style={styles.outlineTextArea}
                  className="w-100 border"
                  minRows={5}
                  placeholder="Write your review..."
                  value={myRating.desc}
                  onChange={(e) => handleTextChange("desc", e.target.value)}
                />
                <ButtonContained onClick={onAddReview}>Submit</ButtonContained>
              </div>
            )} */}
          </div>
          <div className="col-md-4 col-xs-12">
            {user?.role === "admin" && !isMobile && (
              <div>
                <ButtonContained
                  sx={[
                    styles.fontFamilySGB,
                    propertyDetails?.status && styles.btnBgGreen,
                    styles.p10,
                    styles.borderRadius12,
                  ]}
                  className="m-0 my-2 mx-1"
                  onClick={(e) => {
                    e.preventDefault();
                    onUpdateStatus(propertyDetails?.status);
                  }}
                >
                  {propertyDetails?.status ? "Deactivate" : "Activate"}
                </ButtonContained>
              </div>
            )}
            <div
              style={{ marginTop: "20px" }}
              class="card card-property-details"
            >
              <div class="card-body">
                <div className="property-detail-right-bar-top">
                  <div className="user-section-top">
                    <Avatar
                      src={assetUrl + propertyDetails?.avatarImage}
                      class="user-section-img"
                      alt={propertyDetails?.user.name}
                      style={{
                        width: 74,
                        height: 74,
                        borderRadius: 100,
                      }}
                    />
                  </div>
                  <div className="user-name align-self-center ms-2">
                    <h5 class="card-title text-capitalize">
                      {propertyDetails?.user.name}
                    </h5>
                    {propertyDetails?.user.role !== "admin" && (
                      <span className="user-role text-capitalize">
                        {propertyDetails?.user.role}
                      </span>
                    )}
                  </div>
                </div>
                {propertyDetails?.user?.desc && (
                  <div className="user-bio">
                    <p class="card-text">{propertyDetails?.user?.desc}</p>
                  </div>
                )}
                <div className="user-lang-resp">
                  {showContact && (
                    <div className="user-lang">
                      <p className="user-lang-heading">Contact:</p>
                      <a
                        href={`tel:${propertyDetails?.user?.phone}`}
                        className="user-lang-sub-heading"
                        style={{
                          color: "#008000",
                          textDecoration: "none",
                        }}
                      >
                        {propertyDetails?.user?.phone}
                      </a>
                    </div>
                  )}
                  {propertyDetails?.user?.language && (
                    <div className="user-lang">
                      <p className="user-lang-heading">Language:</p>
                      <p className="user-lang-sub-heading text-capitalize">
                        {propertyDetails?.user?.language}
                      </p>
                    </div>
                  )}
                </div>
                {!showContact && (
                  <div className="btn-group-user-property-details mt-3">
                    <ButtonContained onClick={onViewContact}>
                      <i class="fa fa-phone" aria-hidden="true"></i>
                      View Contact
                    </ButtonContained>
                    <ButtonContained
                      disabled={disableShortlist}
                      onClick={() => {
                        shortlist();
                      }}
                    >
                      <i class="fa fa-phone" aria-hidden="true"></i>
                      Shortlist
                    </ButtonContained>
                    {/* <div className="btn-dark-user-property-details">
                    <button type="button" class="btn" onClick={onViewContact}>
                      {" "}
                    </button>
                  </div> */}
                    {/* <div className="btn-light-user-property-details">
                    <button type="button" class="btn">
                      <i class="fa fa-phone" aria-hidden="true"></i>Save
                      Property
                    </button>
                  </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {!isMobile ? (
          <div style={{ marginTop: "100px" }}>
            <h4 className="nearby-title">Properties Nearby</h4>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              {properties &&
                properties?.map(
                  (card, index) =>
                    card._id !== params.id && (
                      <div
                        style={isMobile ? {} : { width: "32.2%" }}
                        className={`col-sm-12 ${isMobile && " w-100"}`}
                      >
                        <div className="card-container">
                          <div key={index} className="card">
                            <Watermark
                              gutter={60}
                              rotate={0}
                              text="Gharwalah.com"
                              textSize={20}
                              multiline={false}
                            >
                              <div
                                onClick={
                                  () =>
                                    window.open(
                                      `/properties/${
                                        card?.route_title || card._id
                                      }`,
                                      "_blank"
                                    )
                                  // navigate(
                                  //   `/properties/${
                                  //     card?.route_title || card._id
                                  //   }`
                                  // )
                                }
                                className="card-image-properties"
                                style={{
                                  backgroundImage: `url(${card.image})`,
                                  backgroundSize: "100%",
                                  backgroundRepeat: "no-repeat",
                                  backgroundClip: "content-box",
                                  backgroundPosition: "center",
                                }}
                              ></div>
                            </Watermark>

                            <div className="text-info">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    color: "black",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {card?.bedrooms} BHK{" "}
                                  {capitalizeFLetter(card.propertyType)}{" "}
                                </p>
                                <p className="slider-title price-text">
                                  ₹
                                  {Intl.NumberFormat("en-IN").format(
                                    card?.expectedMonthlyRent
                                  )}
                                  {card?.wantto !== "sell" && ""}
                                </p>
                              </div>
                              <div
                                style={{
                                  height: "23px",
                                }}
                              >
                                {card.buildupArea && (
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      fontSize: "16px",
                                      color: "#000000",
                                    }}
                                  >
                                    {card.buildupArea} Sq. Ft.
                                  </span>
                                )}
                              </div>
                              <div className="slider-card-header">
                                <h2 className="slider-title">{card.title} </h2>
                              </div>
                              <div>
                                <span
                                  style={{
                                    color: "#9a291b",
                                    fontWeight: "700",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {card.wantto}
                                </span>
                              </div>
                              <p className="property-desc">
                                {card.description}
                              </p>
                              <span
                                style={{
                                  color: "gray",
                                  fontSize: "small",
                                }}
                              >
                                Listed on: {card.created.slice(0, 10)}
                              </span>
                              <div
                                style={{
                                  color: "#00B908",
                                  fontSize: "small",
                                  fontWeight: "bold",
                                }}
                              >
                                {card.createdBy !== "" && (
                                  <div>
                                    Post by:{" "}
                                    {card.createdBy === "admin"
                                      ? "Gharwala Exclusive"
                                      : card.createdBy || "Gharwala Exclusive"}
                                  </div>
                                )}
                                <span
                                  style={{
                                    color: "gray",
                                  }}
                                >
                                  {removeLocations(
                                    card?.locality.coordinates[2]
                                  )}
                                </span>
                              </div>
                              <div className="propety-icons">
                                <div className="property-icon">
                                  <img src={BedSvg} />
                                  <span className="text-color979797 property-icon-text">
                                    {card.bedrooms}
                                  </span>
                                </div>
                                {card.bathrooms && (
                                  <div className="property-icon">
                                    <img src={BathSvg} />
                                    <span className="property-icon-text text-color979797">
                                      {card.bathrooms}
                                    </span>
                                  </div>
                                )}
                                {user && user?.role !== "guest" && (
                                  <div className="property-icon">
                                    <span
                                      style={{
                                        color: card.status ? "green" : "red",
                                      }}
                                      className="property-icon-text text-color979797 px-2"
                                    >
                                      {card.status ? "Active" : "Deactivated"}
                                    </span>
                                  </div>
                                )}
                                {/* <div className="property-icon">
                                <img src={FloorSvg} />
                                <span className="text-color979797 property-icon-text">
                                  1
                                </span>
                              </div> */}
                                {/* <div className="property-icon">
                                <img src={AreaSvg} />
                                <span className="text-color979797 property-icon-text">
                                  2000 Ft
                                </span>
                              </div> */}
                              </div>
                            </div>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <ButtonContained
                                // onClick={()=>{
                                //   {`/property-detail/${card._id}`}
                                // }}
                                onClick={
                                  () =>
                                    window.open(
                                      `/properties/${
                                        card?.route_title || card._id
                                      }`,
                                      "_blank"
                                    )
                                  // navigate(
                                  //   `/properties/${
                                  //     card?.route_title || card._id
                                  //   }`
                                  // )
                                }
                                sx={[
                                  classes.borderRadius12,
                                  classes.fs12,
                                  classes.margin4,
                                  classes.p10,
                                  {
                                    [theme.breakpoints.between("xs", "md")]: {
                                      ...classes.fs9,
                                      ...classes.margin4,
                                      ...classes.borderRadius7,
                                      ...classes.p7,
                                    },
                                  },
                                ]}
                                className="m-0 mx-1 my-2"
                              >
                                Connect
                              </ButtonContained>
                              {user?.role === "admin" && (
                                <ButtonContained
                                  // onClick={()=>{
                                  //   {`/property-detail/${card._id}`}
                                  // }}
                                  onClick={() =>
                                    navigate(`/edit-properties/${card._id}`)
                                  }
                                  style={{ padding: "5px" }}
                                  sx={[
                                    classes.borderRadius12,
                                    classes.fs12,
                                    classes.margin4,
                                    classes.p10,
                                    {
                                      [theme.breakpoints.between("xs", "md")]: {
                                        ...classes.fs9,
                                        ...classes.margin4,
                                        ...classes.borderRadius7,
                                        ...classes.p7,
                                      },
                                    },
                                  ]}
                                  className="m-0 mx-1 my-2"
                                >
                                  Edit
                                </ButtonContained>
                              )}
                            </div>
                            <div className="property-slider-buttons px-2">
                              {/* <button className="btn-connect">Connect </button> */}
                              {/* <Link to={`/property-detail/${card._id}`} style={{ textDecoration: "none", color: "#000" }}> */}

                              {/* </Link> */}
                              {/* <button className="btn-connect btn-details col-6">
                            View Detail{" "}
                          </button> */}
                              {/* <Link to= style={{ textDecoration: "none", color: "#000" }}> */}
                              <ButtonOutlined
                                sx={[
                                  classes.borderRadius12,
                                  classes.fs12,
                                  classes.margin4,
                                  classes.p10,
                                  {
                                    [theme.breakpoints.between("xs", "md")]: {
                                      ...classes.fs9,
                                      ...classes.margin4,
                                      ...classes.borderRadius7,
                                      ...classes.p6,
                                    },
                                  },
                                ]}
                                onClick={() =>
                                  // navigate(
                                  //   `/properties/${
                                  //     card?.route_title || card._id
                                  //   }`
                                  // )

                                  window.open(
                                    `/properties/${
                                      card?.route_title || card._id
                                    }`,
                                    "_blank"
                                  )
                                }
                              >
                                View Detail
                              </ButtonOutlined>

                              {/* </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        ) : (
          <>
            {properties?.data?.length > 0 && (
              <Carousel
                responsive={responsive}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                infinite={true}
                showDots={true}
                autoPlay={true}
                autoPlaySpeed={3500}
              >
                {/* <Slider
            className='slider-margin-left'
            ref={setSliderRef}
            {...sliderSettings} */}
                {/* > */}
                {properties?.data?.map((card, index) => (
                  <div className="card-container mt-5 mb-5 mx-3">
                    <div key={index} className="card">
                      <Watermark
                        gutter={150}
                        rotate={0}
                        text="Gharwalah.com"
                        textSize={50}
                        multiline={false}
                      >
                        <div
                          className="card-image"
                          style={{
                            backgroundImage: `url(${card.image})`,
                            backgroundSize: "100%",
                            backgroundRepeat: "no-repeat",
                            backgroundClip: "content-box",
                            backgroundPosition: "center",
                          }}
                        >
                          <div className="d-flex justify-content-end"></div>
                        </div>
                      </Watermark>
                      <div className="text-info">
                        <div className="slider-card-header">
                          <h2
                            className="slider-title"
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {card.title}
                          </h2>
                          <span className="slider-title price-text">
                            {card.pricingText}
                          </span>
                        </div>
                        <div>
                          {/* <span style={{color: "#9a291b",}}>For</span>{' '}  */}
                          <span
                            style={{
                              color: "black",
                              paddingBlock: "4px",
                              paddingInline: "8px",
                              borderRadius: "8px",
                              textTransform: "capitalize",
                            }}
                          >
                            {card.wantto}
                          </span>
                        </div>
                        <p className="property-desc">{card.description}</p>
                        <div className="propety-icons">
                          <div className="property-icon">
                            <img src={BedSvg} />
                            <span className="text-color979797 property-icon-text">
                              {card.bedrooms}
                            </span>
                          </div>
                          {card.bathrooms && (
                            <div className="property-icon">
                              <img src={BathSvg} />
                              <span className="property-icon-text text-color979797">
                                {card.bathrooms}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="property-slider-buttons">
                        {/* <button className="btn-connect">Connect </button> */}
                        <ButtonContained
                          onClick={() => {
                            navigate(
                              `/properties/${card?.route_title || card._id}`
                            );
                            window.location.reload();
                          }}
                          sx={[
                            classes.borderRadius12,
                            classes.fs12,
                            classes.margin4,
                            classes.p10,
                            {
                              [theme.breakpoints.between("xs", "md")]: {
                                ...classes.fs9,
                                ...classes.margin4,
                                ...classes.borderRadius7,
                              },
                            },
                          ]}
                        >
                          Contact
                        </ButtonContained>
                        <ButtonOutlined
                          onClick={() => {
                            navigate(
                              `/properties/${card?.route_title || card._id}`
                            );
                            window.location.reload();
                          }}
                          sx={[
                            classes.borderRadius12,
                            classes.fs12,
                            classes.margin4,
                            classes.p10,
                            {
                              [theme.breakpoints.between("xs", "md")]: {
                                ...classes.fs8,
                                ...classes.margin4,
                                ...classes.borderRadius7,
                                ...classes.p6,
                                ...classes.px5,
                              },
                            },
                          ]}
                        >
                          View Detail
                        </ButtonOutlined>

                        {/* <button className="btn-connect btn-details">View Detail </button> */}
                      </div>
                    </div>
                  </div>
                ))}
                {/* </Slider> */}
              </Carousel>
            )}
          </>
        )}
      </div>

      <StickyFooter />
      {isMobile ? (
        <BottomSheet
          snapPoints={({ minHeight }) => minHeight}
          open={closeModal}
          onDismiss={() => setCloseModal(false)}
        >
          <SignUp setCloseModal={setCloseModal} />
        </BottomSheet>
      ) : (
        <ModalSignUp
          state={closeModal}
          stateFunc={setCloseModal}
          title="Please fill all the Details"
        >
          <SignUp setCloseModal={setCloseModal} />
        </ModalSignUp>
      )}
      {isMobile ? (
        <BottomSheet
          snapPoints={({ minHeight }) => minHeight}
          open={propertyVerificationModal}
          onDismiss={() => setPropertyVerificationModal(false)}
        >
          <ModalPropertyVerification
            onClose={setPropertyVerificationModal}
            onVerificationComplete={setPropertyVerificationModal}
          />
        </BottomSheet>
      ) : (
        <ModalLogin
          state={propertyVerificationModal}
          stateFunc={setPropertyVerificationModal}
          title="Verification"
        >
          <ModalPropertyVerification
            onClose={setPropertyVerificationModal}
            onVerificationComplete={setPropertyVerificationModal}
          />
        </ModalLogin>
      )}
    </>
  );
};

export default PropertyDetails;
