import React, { useState } from "react";
import "./style.css";
import creditImg from "../../assets/images/credit.png";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { privateReq } from "../../config/axiosConfig";
import toast from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

export default function ModalCredit({
  setOpenPopUp,
  user,
  closeLoginModal,
  setCloseLoginModal,
}) {
  const [loading, setLoading] = useState(false);
  console.log("user there is ", user);
  const navigate = useNavigate();

  function handleCloseIcon() {
    navigate(-1);
  }

  async function buyCredit() {
    setLoading(true);
    try {
      const buyCreditsResponse = await privateReq.post("/buy-credits", {
        email: user.email,
      });
      if (buyCreditsResponse.data.url) {
        window.location.href = buyCreditsResponse.data.url;
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      const buyCreditsResponse = await privateReq.post("/buy-credits");
      if (buyCreditsResponse.data.url) {
        window.location.href = buyCreditsResponse.data.url;
      } else {
        toast.error("Something went wrong!");
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="creditPopMain">
        <div className="cross-icon" onClick={handleCloseIcon}>
          <div className="close-icon">
            <CloseIcon />
          </div>
        </div>
        <div className="credit-pop-inner">
          <div className="credit-head">
            <p>Buy Credits</p>
          </div>
          <div className="credit-desc">
            <p>
              Buy 5 listing{" "}
              <span
                style={{
                  backgroundColor: "rgb(255, 199, 44)",
                  paddingBlock: "4px",
                  paddingInline: "6px",
                  borderRadius: "8px",
                }}
              >
                Free
              </span>{" "}
              at just ₹99
            </p>
          </div>
          <div className="credit-image">
            <img className="c-img" src={creditImg} alt="credit-img" />
          </div>

          <div className="button-container">
            {loading ? (
              <button className="buy-now-button">Loading...</button>
            ) : (
              <button onClick={() => buyCredit()} className="buy-now-button">
                Buy Credits Now
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
